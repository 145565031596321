import {createContext} from 'react';

export const WEB_MAX_WIDTH = 800;

export const themes = {
  light: {
    light: true,

    backgroundPrimary: '#f2f1f5',
    backgroundSecondary: '#f8f8f8',
    headerBackground: '#ffffff',
    footerBackground: '#ffffff',
    headerBorderColor: '#ebeaea',
    footerBorderColor: '#ebeaea',
    textPrimary: '#000000',
    textSecondary: '#444444',
    colorPrimary: '#3c88f6',
    colorSecondary: '#bfbfbf',

    success: '#5cc185',
    error: '#dc3545ff',
    warning: '#ff882a',

    inputBackgroundColor: '#ffffff',
    inputBorderColor: '#ebeaea',

    buttonText: '#ffffff',
    link: '#3C88F6',
  },
  dark: {
    light: false,

    backgroundPrimary: '#1d1d1d',
    backgroundSecondary: '#2A2A2B',
    headerBackground: '#0f0f0f',
    footerBackground: '#0f0f0f',
    headerBorderColor: '#2a2a2b',
    footerBorderColor: '#2a2a2b',
    textPrimary: '#ffffff',
    textSecondary: '#c9c9c9',
    colorPrimary: '#3c88f6',
    colorSecondary: '#bfbfbf',

    success: '#5cc185',
    error: '#dc3545ff',
    warning: '#ff882a',

    inputBackgroundColor: '#000000',
    inputBorderColor: '#444D55',

    buttonText: '#ffffff',
    link: '#3C88F6',
  },
};

export const ThemeContext = createContext(themes.light);
