import React, {forwardRef, useContext, useMemo} from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';

import {ThemeContext} from '../../contexts/ThemeContext';

export const BUTTON_SIZE = 47;
export const BUTTON_MARGIN_LEFT = 8;

// eslint-disable-next-line react/display-name
const FooterButton = forwardRef(({iconBackgroundColor, icon, ...props}, ref) => {
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme), [theme]);

  return (
    <TouchableOpacity
      style={styles.touch}
      {...props}
    >
      <View ref={ref} style={[styles.icon, {backgroundColor: iconBackgroundColor}]}>
        {icon}
      </View>
    </TouchableOpacity>
  );
});

const getStyles = () => StyleSheet.create({
  touch: {
    height: '100%',
    justifyContent: 'center',
  },
  icon: {
    width: BUTTON_SIZE,
    height: BUTTON_SIZE,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: BUTTON_MARGIN_LEFT,
    marginRight: 12,
    borderRadius: BUTTON_SIZE / 2,
  },
});

export default FooterButton;
