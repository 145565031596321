import React, {useState, useContext, useEffect, useMemo} from 'react';

import {
  Image,
  Keyboard,
  View,
  Text,
  StyleSheet,
  SafeAreaView,
  KeyboardAvoidingView,
  TouchableWithoutFeedback, Platform
} from 'react-native';
import {useTranslation} from 'react-i18next';
import * as Cellular from 'expo-cellular';

import Api from '../../../constants/Api';
import {ThemeContext} from '../../../contexts/ThemeContext';
import countries from '../../../assets/countries';
import StyledButton from '../../../components/StyledButton';

import SignupForm from './SignupForm';
import ConfirmForm from './ConfirmForm';

const DEFAULT_COUNTRY = countries.find(({code}) => code === 'US');

const SignupScreen = () => {
  const {t} = useTranslation();
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme), [theme]);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [status, setStatus] = useState('initial');
  const [country, setCountry] = useState(DEFAULT_COUNTRY);

  useEffect(() => {
    const initCountry = async () => {
      let countryCode;

      if (Platform.OS === 'web') {
        // eslint-disable-next-line no-undef
        countryCode = Intl.DateTimeFormat().resolvedOptions().locale;
      } else {
        countryCode = await Cellular.getIsoCountryCodeAsync();
      }

      if (countryCode) {
        setCountry(countries.find(({code}) => code === countryCode.toUpperCase()) || DEFAULT_COUNTRY);
      }
    };

    initCountry();
  }, []);

  const handleCancelPress = () => setStatus('initial');

  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAvoidingView
        style={{flex: 1}}
        behavior="padding"
        enabled
      >
        <TouchableWithoutFeedback
          onPress={() => {
            if (Platform.OS !== 'web') {
              Keyboard.dismiss();
            }
          }}
        >
          <View style={{flex: 1}}>
            <View style={styles.textContainer}>
              <View
                style={[styles.logoContainer, {
                  backgroundColor: Api.appName === 'ChaTTTTT' ? '#000000' : theme.colorPrimary,
                }]}
              >
                <Image
                  source={require('../../../assets/icon-white.png')}
                  style={styles.logo}
                />
              </View>

              <Text style={styles.title}>
                {Api.appName === 'ChaTTTTT' ? 'ChaTTTTT' : 'Secrétaire'}
              </Text>
            </View>

            <View style={styles.formContainer}>
              {(status === 'initial' || status === 'sending') && (
                <SignupForm
                  country={country}
                  setCountry={setCountry}
                  status={status}
                  setStatus={setStatus}
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                />
              )}

              {status === 'sent' && (
                <ConfirmForm
                  setStatus={setStatus}
                  phoneNumber={phoneNumber}
                />
              )}

              {status === 'error' && (
                <View style={styles.textContainer}>
                  <Text style={styles.text}>
                    {t('SignupScreen.confirm.errors.text1', 'An error occured.')}
                  </Text>

                  <Text style={styles.text}>
                    {t('SignupScreen.confirm.errors.text2', 'We were unable to send a confirmation code to {{phoneNumber}}.', {phoneNumber})}
                  </Text>

                  <Text style={styles.text}>
                    {t('SignupScreen.confirm.errors.text3', 'Please check your phone number or try later.')}
                  </Text>

                  <StyledButton
                    style={styles.button}
                    text={t('SignupScreen.confirm.errors.ok', 'OK')}
                    onPress={handleCancelPress}
                  />
                </View>
              )}
            </View>
          </View>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

// TODO: Refactor styles with the other component
const getStyles = theme => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.backgroundPrimary,
  },
  textContainer: {
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  logoContainer: {
    marginTop: 50,
    marginBottom: 20,
    padding: 10,
    borderRadius: 60,
  },
  logo: {
    width: 60,
    height: 60,
    resizeMode: 'contain',
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    color: theme.textPrimary,
  },
  formContainer: {
    alignItems: 'center',
    paddingHorizontal: 30,
    flex: 1,
  },
  text: {
    fontSize: 16,
    color: theme.textPrimary,
    lineHeight: 24,
    textAlign: 'center',
    maxWidth: 250,
    marginBottom: 16,
  },
  button: {
    paddingVertical: 15,
    paddingHorizontal: 80,
    marginBottom: 30,
  },
});

export default SignupScreen;
