import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';

import {
  ActivityIndicator,
  Dimensions,
  Keyboard,
  Platform,
  SafeAreaView,
  StyleSheet,
  View,
} from 'react-native';
import uuid from 'react-native-uuid';

import * as Device from 'expo-device';
import * as Location from 'expo-location';
import * as Haptics from 'expo-haptics';
import * as Localization from 'expo-localization';
import * as Sentry from 'sentry-expo';
import {FontAwesome} from '@expo/vector-icons';
import Constants from 'expo-constants';
import {useTranslation} from 'react-i18next';

import EventSource from '../../utils/SourceEvent';
import {UserContext} from '../../contexts/UserContext';
import {SettingsContext} from '../../contexts/SettingsContext';
import {ThemeContext, WEB_MAX_WIDTH} from '../../contexts/ThemeContext';
import {throttle} from '../../utils/throttle';
import useSpeaker from '../../hooks/useSpeaker';
import Api from '../../constants/Api';
import StyledInput from '../../components/StyledInput';

import Chat from './Chat/Chat';
import Recorder from './Recorder';
import FooterButton, {BUTTON_MARGIN_LEFT, BUTTON_SIZE} from './FooterButton';

const FOOTER_PADDING_HORIZONTAL = 12;
const FOOTER_PADDING_VERTICAL = 8;
const FOOTER_HEIGHT = BUTTON_SIZE + (2 * FOOTER_PADDING_VERTICAL);

let shouldScroll = false;

const ChatView = ({previousDiscussionId, discussionId, updateDiscussionId, fetchDiscussions}) => {
  const {t} = useTranslation();
  const {userToken, deviceToken, user, setUser} = useContext(UserContext);
  const {settings: {language}} = useContext(SettingsContext);
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme), [theme]);
  const speaker = useSpeaker();

  const scrollViewRef = useRef(null);

  const [queries, setQueries] = useState([]);
  const [loading, setLoading] = useState(false);
  // Note: Object with discussionId as key and array of localQueries as value. When discussionId is
  //  not set (eg: first use, after a clear discussion) we use the key: 'new'
  const [localQueriesByDiscussionId, setLocalQueriesByDiscussionId] = useState({});
  const [queryText, setQueryText] = useState('');

  const fetchQueries = useCallback(async () => {
    if (discussionId) {
      setLoading(true);

      const response = await fetch(`${Api.apiBaseUrl}/discussions/${discussionId}.json?user_token=${userToken}`, {method: 'GET'});
      const data = await response.json();

      setLoading(false);

      if (data.error) {
        // TODO: handle error
        console.log(data.error);
        return;
      }

      setQueries(data.queries);
      setTimeout(() => scrollViewRef && scrollViewRef.current && scrollViewRef.current.scrollToEnd({animated: false}), 0);
    } else {
      setQueries([]);
    }
  }, [userToken, setLoading, setQueries, discussionId]);

  useEffect(() => {
    if (previousDiscussionId) {
      fetchQueries();
    }
  }, [userToken, previousDiscussionId, discussionId, fetchQueries]);

  const pushLocalQuery = useCallback(query => {
    const updatedLocalQueries = [...localQueriesByDiscussionId[discussionId || 'new'] || [], query];

    setLocalQueriesByDiscussionId({
      ...setLocalQueriesByDiscussionId,
      [discussionId || 'new']: updatedLocalQueries,
    });

    return updatedLocalQueries;
  }, [discussionId, localQueriesByDiscussionId, setLocalQueriesByDiscussionId]);

  const updateLocalQuery = useCallback((localQueries, localQuery) => {
    const localQueryIndex = localQueries.findIndex(query => query.ref === localQuery.ref);

    if (localQueryIndex !== -1) {
      const updatedLocalQueries = [...localQueries];
      updatedLocalQueries[localQueryIndex] = localQuery;

      setLocalQueriesByDiscussionId({
        ...localQueriesByDiscussionId,
        [discussionId || 'new']: updatedLocalQueries,
      });
    }
  }, [discussionId, localQueriesByDiscussionId, setLocalQueriesByDiscussionId]);

  const createQuery = useCallback(async (data, localQueries, localQuery) => {
    let es = null;
    const fakeResponseId = uuid.v4();

    try {
      const formData = new FormData();

      formData.append('user_token', userToken);
      formData.append('device_token', deviceToken);
      formData.append('stream', true);

      if (discussionId) {
        formData.append('query[discussion_id]', discussionId);
      }

      formData.append('query[channel]', data.channel);
      formData.append('query[ref]', data.ref);
      formData.append('query[text]', data.text);

      if (data.recordingId) {
        formData.append('query[recording_id]', data.recordingId);
      }

      formData.append('query[country_code]', Localization.getLocales()[0].regionCode);
      formData.append('query[timezone]', Localization.timezone);

      formData.append('query[language]', language); // fr-FR
      formData.append('query[device_brand]', Device.brand); // Android: "google", "xiaomi"; iOS: "Apple"; web: null
      formData.append('query[device_name]', Device.deviceName); // "Vivian's iPhone XS"
      formData.append('query[device_manufacturer]', Device.manufacturer); // Android: "Google", "xiaomi"; iOS: "Apple"; web: "Google", null
      formData.append('query[device_os_name]', Platform.OS);
      formData.append('query[device_os_version]', Device.osVersion);
      formData.append('query[device_model_name]', Device.modelName);
      formData.append('query[expo_session_id]', Constants.sessionId);
      formData.append('query[expo_installation_id]', Constants.installationId);

      const deviceType = await Device.getDeviceTypeAsync();
      if(deviceType === 0)       { formData.append('query[device_type]', 'unknown'); }
      else if(deviceType === 1)  { formData.append('query[device_type]', 'phone'); }
      else if(deviceType === 2)  { formData.append('query[device_type]', 'tablet'); }
      else if(deviceType === 3)  { formData.append('query[device_type]', 'desktop'); }
      else if(deviceType === 4)  { formData.append('query[device_type]', 'tv'); }
      else { formData.append('query[device_type]', 'UNKNOWN'); }

      let locationPerm = {};

      if (Platform.OS === 'web') {
        const {state} = await navigator.permissions.query({name: 'geolocation'});

        locationPerm = {granted: state === 'granted'};
      } else {
        locationPerm = await Location.getForegroundPermissionsAsync();
      }

      if (locationPerm.granted) {
        let location = await Location.getLastKnownPositionAsync({
          requiredAccuracy: 300, // Note: Return null if ~300 meter away from last known location
          maxAge: 600000, // Note: Return null if last known location is more than 10m old
        });

        if (!location) {
          location = await Location.getCurrentPositionAsync({
            accuracy: Location.Accuracy.High,
            mayShowUserSettingsDialog: false,
          });
        }

        formData.append('query[latitude]', location.coords.latitude);
        formData.append('query[longitude]', location.coords.longitude);
      }

      const createRequest = new Promise((resolve, reject) => {
        es = new EventSource(
          `${Api.apiBaseUrl}/queries.json`,
          {
            method: 'POST',
            body: formData,
            debug: true,
          }
        );

        es.addEventListener('open', () => {
          console.log('Event "open" called');
          shouldScroll = true;

          speaker.initSpeakQueryState();
        });

        const throttledUpdateLocalQuery = throttle(message => {
          updateLocalQuery(localQueries, {
            ...localQuery,
            responses: [{
              id: fakeResponseId,
              message,
              created_at: Date.now(),
              channel: localQuery.channel,
              source: undefined,
              cards: [],
            }],
          });

          if (shouldScroll) {
            setTimeout(() => scrollViewRef && scrollViewRef.current && scrollViewRef.current.scrollToEnd({animated: true}), 0);
          }
        }, 300, false, false);

        let message = '';

        es.addEventListener('initial', event => {
          const query = JSON.parse(event.data);
          console.log('Event "initial": ', query);

          es.addEventListener('message', event => {
            message += event.data;
            throttledUpdateLocalQuery(message);

            speaker.speakToken(event.data, query.language);
          });
        });

        es.addEventListener('final', event => {
          es.removeAllEventListeners();
          es.close();

          const query = JSON.parse(event.data);
          console.log('Event "final": ', query);

          if (query.responses && query.responses.length && query.responses[0].message) {
            speaker.emptyResponseBuffer(query.responses[0].message, query.responses[0].language);
          }

          const updatedLocalQueries = (localQueriesByDiscussionId[discussionId || 'new'] || [])
            .filter(({ref}) => query.ref && query.ref === ref);

          if (discussionId) {
            setLocalQueriesByDiscussionId({
              ...localQueriesByDiscussionId,
              [discussionId]: updatedLocalQueries,
            });
          } else {
            // Note: If we weren't on a discussion (eg: first use, after a clear discussion) we reset the
            //  special 'new' key, update the discussionId and fetchDiscussions to update the drawer content
            setLocalQueriesByDiscussionId({
              ...localQueriesByDiscussionId,
              new: [],
              [query.discussion_id]: updatedLocalQueries,
            });

            updateDiscussionId(query.discussion_id);
          }

          setQueries([...queries, query]);

          const updatedUser = {...user, daily_queries: query.daily_queries};

          setUser(updatedUser);

          fetchDiscussions();

          resolve(query);
        });

        es.addEventListener('error', (event) => {
          // TODO: Handle errors
          if (event.type === 'error') {
            console.log('Connection error:', event.message);
            reject({message: event.message});
          } else if (event.type === 'exception') {
            console.log('Error:', event.message, event.error);
            reject({error: event.error, message: event.message});
          }

          updateLocalQuery(localQueries, {
            ...localQuery,
            responses: [{
              id: fakeResponseId,
              message: t('MainScreen.ChatView.Recorder.errors.recordingFailed', 'Sorry, something went wrong, try again later.'),
              status: 'error',
              created_at: Date.now(),
              channel: localQuery.channel,
              source: undefined,
              cards: [],
            }],
          });

          es.removeAllEventListeners();
          es.close();
        });

        es.addEventListener('close', () => {
          console.log('Close SSE connection.');
        });
      });

      const result = await createRequest;

      console.log(result);

      return result;
    } catch (error) {
      // TODO: Handle errors
      if (Platform.OS === 'web') {
        Sentry.Browser.captureException(error);
      } else {
        Sentry.captureException(error);
      }

      if (es) {
        es.removeAllEventListeners();
        es.close();
      }

      updateLocalQuery(localQueries, {
        ...localQuery,
        responses: [{
          id: fakeResponseId,
          message: t('MainScreen.ChatView.Recorder.errors.recordingFailed', 'Sorry, something went wrong, try again later.'),
          status: 'error',
          created_at: Date.now(),
          channel: localQuery.channel,
          source: undefined,
          cards: [],
        }],
      });
    }
  }, [
    t,
    userToken,
    deviceToken,
    user,
    setUser,
    discussionId,
    language,
    updateLocalQuery,
    speaker,
    localQueriesByDiscussionId,
    queries,
    fetchDiscussions,
    updateDiscussionId,
  ]);

  const handleSendQuery = useCallback(async text => {
    if (
      !text.length
      || !text.trim().length
      // Note: THIS IS NOT A SIMPLE SPACE, DO NOT DELETE !
      //  This is a weird character that is added when on iPhone when someone use the voice control
      //  but doesn't speak.
      || text === '￼'
    ) {
      if (Platform.OS !== 'web') {
        Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
      }

      return;
    }

    setQueryText('');
    Keyboard.dismiss();

    const ref = uuid.v4();

    const localQuery = {
      ref,
      text,
      channel: 'keyboard',
      created_at: new Date(),
      updated_at: new Date(),
      responses: [],
    };

    // Note: Optimistically add a fake query until the server return the result
    const localQueries = pushLocalQuery(localQuery);

    setTimeout(() => scrollViewRef && scrollViewRef.current && scrollViewRef.current.scrollToEnd({animated: true}), 0);

    await createQuery({ref, channel: 'keyboard', text}, localQueries, localQuery);

    setTimeout(() => scrollViewRef && scrollViewRef.current && scrollViewRef.current.scrollToEnd({animated: true}), 0);
  }, [scrollViewRef, pushLocalQuery, createQuery]);

  const onChangeText = useCallback(value => setQueryText(value), [setQueryText]);
  const onSubmitEditing = useCallback(event => handleSendQuery(event.nativeEvent.text), [handleSendQuery]);

  const allQueries = useMemo(() => (
    [...queries, ...(localQueriesByDiscussionId[discussionId || 'new'] || [])]
  ), [queries, localQueriesByDiscussionId, discussionId]);

  return (
    <SafeAreaView style={{flex:1, width: '100%', backgroundColor: theme.headerBackground}}>
      <View
        style={{
          flex: 1,
          width: '100%',
          backgroundColor: theme.backgroundPrimary,
          paddingBottom: Platform.OS === 'web' ? FOOTER_HEIGHT : undefined,
        }}
      >
        {loading
          ? (
            <View style={{flex: 1, justifyContent: 'center'}}>
              <ActivityIndicator size="large"/>
            </View>
          )
          : (
            <Chat
              scrollViewRef={scrollViewRef}
              queries={allQueries}
              setQueryText={setQueryText}
            />
          )}

        <View style={styles.footer}>
          <View style={styles.innerFooter}>
            <StyledInput
              style={{width: Dimensions.get('window').width - 2 * FOOTER_PADDING_HORIZONTAL - BUTTON_SIZE - BUTTON_MARGIN_LEFT}}
              onChangeText={onChangeText}
              onSubmitEditing={onSubmitEditing}
              value={queryText}
              placeholder={t('MainScreen.ChatView.input.placeholder', 'Type anything...')}
            />

            {queryText.length || (Platform.OS === 'web' && !window.MediaRecorder.isTypeSupported('audio/webm'))
              ? (
                <FooterButton
                  onPress={() => handleSendQuery(queryText)}
                  iconBackgroundColor={theme.colorPrimary}
                  icon={<FontAwesome name="send" size={22} color="white"/>}
                />
              )
              : (
                <Recorder
                  pushLocalQuery={pushLocalQuery}
                  updateLocalQuery={updateLocalQuery}
                  createQuery={createQuery}
                  scrollViewRef={scrollViewRef}
                />
              )
            }
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

const getStyles = theme => StyleSheet.create({
  footer: {
    ...(Platform.OS === 'web'
      ? {
        position: 'fixed',
        bottom: 0,
      }
      : {}),
    backgroundColor: theme.footerBackground,
    alignItems: 'center',
    width: '100%',
    height: FOOTER_HEIGHT,
    borderTopColor: theme.footerBorderColor,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
  },
  innerFooter: {
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: WEB_MAX_WIDTH,
    width: '100%',
    height: '100%',
    justifyContent: 'flex-start',
    paddingLeft: FOOTER_PADDING_HORIZONTAL,
  },
});

export default ChatView;
