import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {
  Animated,
  Image,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
  Easing,
  Text,
  ScrollView,
} from 'react-native';
import Constants from 'expo-constants';
import * as Haptics from 'expo-haptics';
import * as Device from 'expo-device';
import * as Location from 'expo-location';
import * as Sentry from 'sentry-expo';

import {UserContext} from '../../contexts/UserContext';
import {SettingsContext} from '../../contexts/SettingsContext';
import {ThemeContext} from '../../contexts/ThemeContext';
import Api from '../../constants/Api';
import useRecorder from '../../hooks/useRecorder';
import useSpeaker from '../../hooks/useSpeaker';
import uuid from 'react-native-uuid';
import {useHeaderHeight} from '@react-navigation/elements';

let microphonePermissionsRequested = false;

const BUTTON_SIZE = 200;
const BORDER_SIZE = 10;

const CustomProgressBar = ({loading, rotationDegree}) => {
  const theme = useContext(ThemeContext);

  const COLOR = theme.light ? 'rgba(0, 0, 0, 0.3)' : '#fff';

  return (
    <View
      accessibilityRole="progressbar"
      style={{
        position: 'absolute',
        width: BUTTON_SIZE + (BORDER_SIZE * 2) - 2,
        height: BUTTON_SIZE + (BORDER_SIZE * 2) - 2,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
      }}
    >
      <View
        style={{
          width: '100%',
          height: '100%',
          borderRadius: BUTTON_SIZE / 2,
        }}
      />

      {loading && (
        <Animated.View
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: (BUTTON_SIZE + BORDER_SIZE) / 2,
            borderWidth: BORDER_SIZE,
            // Note: Using almost transparent color since transparent does not work on android
            //  see: https://github.com/facebook/react-native/issues/34722
            borderLeftColor: '#00000001',
            borderRightColor: '#00000001',
            borderBottomColor: '#00000001',
            borderTopColor: COLOR,
            transform: [{
              rotateZ: rotationDegree.interpolate({
                inputRange: [0, 360],
                outputRange: ['0deg', '360deg'],
              }),
            }],
          }}
        />
      )}
    </View>
  );
};

const MicrophoneView = ({discussionId}) => {
  const {userToken} = useContext(UserContext);
  const {settings: {language}} = useContext(SettingsContext);
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme), [theme]);
  const headerHeight = useHeaderHeight();

  const recorder = useRecorder();
  const speaker = useSpeaker();

  const [loading, setLoading] = useState(false);
  const [lastTranscript, setLastTranscript] = useState(null);
  const [lastResponse, setLastResponse] = useState(null);

  const [viewPressed, setViewPressed] = useState(false);

  // TODO: refactor createQuery with the one in ChatView
  const createQuery = useCallback(async data => {
    const formData = new FormData();

    formData.append('user_token', userToken);

    if (discussionId) {
      formData.append('query[discussion_id]', discussionId);
    }

    formData.append('query[channel]', data.channel);
    formData.append('query[ref]', data.ref);
    formData.append('query[text]', data.text);

    if (data.recordingId) {
      formData.append('query[recording_id]', data.recordingId);
    }

    formData.append('query[language]', language); // fr-FR
    formData.append('query[device_brand]', Device.brand); // Android: "google", "xiaomi"; iOS: "Apple"; web: null
    formData.append('query[device_name]', Device.deviceName); // "Vivian's iPhone XS"
    formData.append('query[device_manufacturer]', Device.manufacturer); // Android: "Google", "xiaomi"; iOS: "Apple"; web: "Google", null
    formData.append('query[device_os_name]', Platform.OS);
    formData.append('query[device_os_version]', Device.osVersion);
    formData.append('query[device_model_name]', Device.modelName);
    formData.append('query[expo_session_id]', Constants.sessionId);
    formData.append('query[expo_installation_id]', Constants.installationId);

    const deviceType = await Device.getDeviceTypeAsync();
    if(deviceType === 0)       { formData.append('query[device_type]', 'unknown'); }
    else if(deviceType === 1)  { formData.append('query[device_type]', 'phone'); }
    else if(deviceType === 2)  { formData.append('query[device_type]', 'tablet'); }
    else if(deviceType === 3)  { formData.append('query[device_type]', 'desktop'); }
    else if(deviceType === 4)  { formData.append('query[device_type]', 'tv'); }
    else { formData.append('query[device_type]', 'UNKNOWN'); }

    const locationPerm = await Location.getForegroundPermissionsAsync();
    console.log('Location perm before getLastKnownPositionAsync', locationPerm);

    if (locationPerm.granted) {
      const location = await Location.getLastKnownPositionAsync();
      formData.append('query[latitude]', location.coords.latitude);
      formData.append('query[longitude]', location.coords.longitude);
    }

    const response = await fetch(`${Api.apiBaseUrl}/queries.json`, {
      method: 'POST',
      body: formData,
    });

    const query = await response.json();
    console.log('Query created', query);

    if (query && query.status === 'responded') {
      if (query.responses && query.responses.length && query.responses[0].message) {
        speaker.speak(query.responses[0].message, query.responses[0].language);
      }
    }

    // const updatedLocalQueries = (localQueriesByDiscussionId[discussionId || 'new'] || [])
    //   .filter(({ref}) => query.ref && query.ref === ref);
    //
    // if (discussionId) {
    //   setLocalQueriesByDiscussionId({
    //     ...localQueriesByDiscussionId,
    //     [discussionId]: updatedLocalQueries,
    //   });
    // } else {
    //   // Note: If we weren't on a discussion (eg: first use, after a clear discussion) we reset the
    //   //  special 'new' key, update the discussionId and fetchDiscussions to update the drawer content
    //   setLocalQueriesByDiscussionId({
    //     ...localQueriesByDiscussionId,
    //     new: [],
    //     [query.discussion_id]: updatedLocalQueries,
    //   });
    //
    //   updateDiscussionId(query.discussion_id);
    //   fetchDiscussions();
    // }
    //
    // setQueries([...queries, query]);
    return query;
  }, [userToken, language, discussionId, speaker]);

  // TODO: refactor handleUploadRecording with the one in ChatView
  const handleUploadRecording = useCallback(async (uri, ref) => {
    try {
      const formData = new FormData();

      formData.append('user_token', userToken);
      formData.append('recording[ref]', ref);
      formData.append('recording[file]', {
        uri,
        type: 'audio/x-wav',
        name: 'recording_' + Constants.sessionId + '_' + Date.now() + '.wav',
      });

      formData.append('recording[language]', language); // fr-FR
      formData.append('recording[device_brand]', Device.brand); // Android: "google", "xiaomi"; iOS: "Apple"; web: null
      formData.append('recording[device_name]', Device.deviceName); // "Vivian's iPhone XS"
      formData.append('recording[device_manufacturer]', Device.manufacturer); // Android: "Google", "xiaomi"; iOS: "Apple"; web: "Google", null
      formData.append('recording[device_os_name]', Platform.OS);
      formData.append('recording[device_os_version]', Device.osVersion);
      formData.append('recording[device_model_name]', Device.modelName);
      formData.append('recording[expo_session_id]', Constants.sessionId);
      formData.append('recording[expo_installation_id]', Constants.installationId);

      if(Platform.OS === 'ios') {
        formData.append('recording[encoding]', 'LINEAR16');
        formData.append('recording[sample_rate]', '48000');
        formData.append('recording[bit_rate]', '128000');
      } else {
        formData.append('recording[encoding]', 'AMR_WB');
        formData.append('recording[sample_rate]', '16000');
        formData.append('recording[bit_rate]', '16000');
      }

      const deviceType = await Device.getDeviceTypeAsync();
      if(deviceType === 0)       { formData.append('recording[device_type]', 'unknown'); }
      else if(deviceType === 1)  { formData.append('recording[device_type]', 'phone'); }
      else if(deviceType === 2)  { formData.append('recording[device_type]', 'tablet'); }
      else if(deviceType === 3)  { formData.append('recording[device_type]', 'desktop'); }
      else if(deviceType === 4)  { formData.append('recording[device_type]', 'tv'); }
      else { formData.append('recording[device_type]', 'UNKNOWN'); }

      const locationPerm = await Location.getForegroundPermissionsAsync();
      console.log('Location perm before getLastKnownPositionAsync', locationPerm);

      if (locationPerm.granted) {
        const location = await Location.getLastKnownPositionAsync();
        formData.append('recording[latitude]', location.coords.latitude);
        formData.append('recording[longitude]', location.coords.longitude);
      }

      const response = await fetch(`${Api.apiBaseUrl}/recordings.json`, {
        method: 'POST',
        body: formData,
      });

      return await response.json();
    } catch (error) {
      // TODO: Handle errors
      console.log('There was an error during upload.', error);

      if (Platform.OS === 'web') {
        Sentry.Browser.captureException(error);
      } else {
        Sentry.captureException(error);
      }
    }
  }, [userToken, language]);

  const shakeAnim = useRef(new Animated.Value(0)).current;
  const rotationDegree = useRef(new Animated.Value(0)).current;
  const scaleAnim = React.useRef(new Animated.Value(1)).current;

  const breathingAnimation = Animated.loop(
    Animated.sequence([
      Animated.timing(scaleAnim, {toValue: 1.0, duration: 0, useNativeDriver: true}),
      Animated.timing(scaleAnim, {toValue: 1.2, duration: 1000, useNativeDriver: true}),
      Animated.timing(scaleAnim, {toValue: 1.0, duration: 1500, useNativeDriver: true}),
    ])
  );

  useEffect(() => {
    breathingAnimation.start();
  }, []);

  const loadingAnimation = useMemo(() => (
    Animated.loop(Animated.timing(
      rotationDegree,
      {
        toValue: 360,
        duration: 1000,
        easing: Easing.linear,
        useNativeDriver: true,
      }
    ))
  ), [rotationDegree]);

  const handleLongPress = useCallback(async () => {
    // Note: If this press triggered a permission request we don't want to do anything else
    if (microphonePermissionsRequested) {
      microphonePermissionsRequested = false;
      return;
    }

    speaker.stop();

    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.High);

    await recorder.start();
  }, [recorder, speaker]);

  const handlePress = useCallback(async () => {
    // Note: If this press triggered a permission request we don't want to do anything else
    if (microphonePermissionsRequested) {
      microphonePermissionsRequested = false;
      return;
    }

    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);

    Animated.sequence([
      Animated.timing(shakeAnim, {toValue: 10, duration: 100, useNativeDriver: true}),
      Animated.timing(shakeAnim, {toValue: -10, duration: 100, useNativeDriver: true}),
      Animated.timing(shakeAnim, {toValue: 10, duration: 100, useNativeDriver: true}),
      Animated.timing(shakeAnim, {toValue: -10, duration: 100, useNativeDriver: true}),
      Animated.timing(shakeAnim, {toValue: 10, duration: 100, useNativeDriver: true}),
      Animated.timing(shakeAnim, {toValue: 0, duration: 100, useNativeDriver: true}),
    ]).start(() => {
      breathingAnimation.start();
    });
  }, []);

  const handlePressIn = useCallback(async () => {
    await recorder.requestPermissions(() => {
      microphonePermissionsRequested = true;
    });

    breathingAnimation.stop();
    breathingAnimation.reset();
  });

  const handlePressOut = useCallback(async () => {
    if (recorder.state.status === 'starting') {
      recorder.stop();
    } else if (recorder.state.status === 'recording') {
      try {
        setLoading(true);
        loadingAnimation.start();

        Haptics.impactAsync(Haptics.ImpactFeedbackStyle.High);

        const uri = await recorder.stop();

        const ref = uuid.v4();

        // TODO: Figure out what to do about the whole localQuery thing
        // const localQuery = {
        //   ref,
        //   text: null,
        //   channel: 'recording',
        //   created_at: new Date(),
        //   updated_at: new Date(),
        //   responses: [],
        // };
        //
        // // Note: Optimistically add a fake query until the server return the result
        // const localQueries = pushLocalQuery(localQuery);

        const recording = await handleUploadRecording(uri, ref);

        setLastResponse(null);
        setLastTranscript(recording.transcription);

        // // Note: Update localQuery text with the transcription of the recording
        // localQuery.text = recording.transcription;
        //
        // if (recording.status === 'failed') {
        //   localQuery.text = 'Sorry, we didn\'t detect any audio.';
        //   localQuery.status = 'empty'
        // }
        //
        // updateLocalQuery(localQueries, localQuery)
        //
        // /*Animated.timing(translateAnim, {
        //   toValue: Dimensions.get('window').width,
        //   duration: 300,
        //   useNativeDriver: true
        // }).start(async () => {*/

        if (recording.status !== 'failed') {
          const query = await createQuery({
            ref,
            channel: 'recording',
            text: recording.transcription,
            recordingId: recording.id,
          });

          Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);

          // TODO: Figure out if it's safe to not test that the status is responded
          if (query /* && query.status=== 'responded'*/) {
            if (query.responses && query.responses.length && query.responses[0].message) {
              setLastResponse(query.responses[0].message);
            }
          }
        }

        setLoading(false);
        loadingAnimation.stop();
        loadingAnimation.reset();

        // /*});*/
      } catch (error) {
        console.log('There was an error during upload.', error);

        if (Platform.OS === 'web') {
          Sentry.Browser.captureException(error);
        } else {
          Sentry.captureException(error);
        }
      }

      breathingAnimation.start();
    }

  }, [recorder, setLastResponse, setLastTranscript/*, pushLocalQuery, updateLocalQuery, createQuery*/]);

  return (
      <View style={styles.container}>
        <TouchableOpacity
          onPress={handlePress}
          onLongPress={handleLongPress}
          onPressIn={handlePressIn}
          onPressOut={handlePressOut}
          style={{alignSelf: 'center', marginTop: -headerHeight}}
        >
          <Animated.View style={{transform: [{translateX: shakeAnim}, {scale: scaleAnim}]}}>
            <View
              style={{
                width: BUTTON_SIZE,
                height: BUTTON_SIZE,
                borderRadius: BUTTON_SIZE / 2,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: recorder.state.status === 'recording' ? '#fff' : theme.colorPrimary,
                opacity: viewPressed ? 0.25 : lastResponse ? 0.80 : 1,
              }}
            >
              <CustomProgressBar loading={loading} rotationDegree={rotationDegree}/>

              <Image
                source={require('../../assets/icon-white.png')}
                style={{
                  width: 100,
                  height: 100,
                  tintColor: recorder.state.status === 'recording' ? '#000' : '#fff',
                }}
              />
            </View>
          </Animated.View>
        </TouchableOpacity>

        <ScrollView
          style={{
            position: 'absolute',
            top: 0,
            height: '100%',
            width: '100%',
            alignSelf: 'center',
            zIndex: -1,
          }}
          contentContainerStyle={{
            paddingVertical: 12,
            paddingHorizontal: 12,
            paddingBottom: 500,
          }}
          onScrollBeginDrag={() => setViewPressed(true)}
          onScrollEndDrag={() => setViewPressed(false)}
        >
          <Text style={{color: theme.textPrimary, fontSize: 17, fontWeight: 'bold', marginBottom: 24}}>
            {lastTranscript}
          </Text>

          <Text style={{color: theme.textPrimary, fontSize: 17}}>
            {lastResponse}
          </Text>
        </ScrollView>
      </View>
  );
};


const getStyles = () => StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    paddingBottom: 20,
    paddingTop: 20,
  },
});

export default MicrophoneView;
