import React, {useContext, useMemo} from 'react';
import {Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import * as Localization from 'expo-localization';

import {ThemeContext} from '../../../contexts/ThemeContext';
import openLink from '../../../utils/openLink';

const PlaceCard = ({card, isLast}) => {
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme), [theme]);

  let distance_text = '';

  if (card.distance < 1.0) {
    distance_text = `${Math.round(card.distance * 100) * 10} m`;
  } else {
    distance_text = `${Math.round(card.distance)} km`;
  }

  return (
    <View style={[styles.container, {marginRight: isLast ? 0 : 12}]}>
      <View style={{paddingHorizontal: 12, paddingBottom: 10, paddingTop: 2}}>
        {card.title ?
          <Text style={styles.title}>{card.title}</Text>
          : null}

        {card.subtitle || card.distance ? (
          <Text style={styles.subtitle}>
            {card.distance ? <Text style={{fontWeight: 'bold'}}>{`${distance_text} - `}</Text> : null}
            {card.subtitle}
          </Text>
        ) : null}

        {card.rating !== undefined && card.rating !== null ?
          <Text style={styles.subtitle}>
            {`⭐ ${card.rating}${card.total_ratings ? ` (${card.total_ratings})` : ''}${card.price_level ? ` - ${(Localization.getLocales()[0].currencySymbol || '$').repeat(card.price_level)}` : ''}`}
          </Text>
          : null}

        {card.link_1_title ?
          <TouchableOpacity onPress={() => openLink(card.link_1_url)}>
            <Text style={styles.link}>{card.link_1_title}</Text>
          </TouchableOpacity>
          : null}
      </View>
    </View>
  );
};

const WeatherCard = ({card, isLast}) => {
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme), [theme]);

  return (
    <View style={[styles.container, {width: 260, marginRight: isLast ? 0 : 12}]}>
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        {card.image_url ?
          <Image source={{uri: card.image_url}} style={{flex: 1, height: 80}} resizeMode="cover"/>
          : null}

        <View style={{flex: 1}}>
          {card.data.temperature !== undefined && card.data.temperature !== null ?
            <Text
              style={{
                fontSize: 40,
                fontWeight: 100,
                color: theme.textPrimary,
              }}
            >
              {card.data.temperature}°C
            </Text>
            : null}

          {(card.data.min_temperature !== undefined && card.data.min_temperature !== null)
          || (card.data.max_temperature !== undefined && card.data.max_temperature !== null) && (
            <View style={{flex: 1, flexDirection: 'row'}}>
              {card.data.min_temperature !== undefined && card.data.min_temperature !== null ?
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: 200,
                    color: theme.textPrimary,
                    marginRight: 8,
                  }}
                >
                  ↓ {card.data.min_temperature}°C
                </Text>
                : null}

              {card.data.max_temperature !== undefined && card.data.max_temperature !== null ?
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: 200,
                    color: theme.textPrimary,
                  }}
                >
                  ↑ {card.data.max_temperature}°C
                </Text>
                : null}
            </View>
          )}
        </View>
      </View>

      <View style={{paddingHorizontal: 12, paddingBottom: 10}}>
        {card.title ?
          <Text
            style={{
              fontSize: 15,
              textAlign: 'left',
              fontWeight: 'bold',
              color: theme.textPrimary,
            }}
          >
            {card.title}
          </Text>
          : null}
      </View>
    </View>
  );
};

const Card = ({card, isLast}) => {
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme), [theme]);

  switch (card.card_type) {
    case 'place':
      return <PlaceCard card={card} isLast={isLast}/>;
    case 'weather':
      return <WeatherCard card={card} isLast={isLast}/>;
    default:
      return (
        <View style={[styles.container, {marginRight: isLast ? 0 : 12}]}>
          {card.image_url ?
            <Image source={{uri: card.image_url}} style={styles.image} />
            : null}

          <View style={{paddingHorizontal: 12, paddingBottom: 10, paddingTop: 2}}>
            {card.title ?
              <Text style={styles.title}>{card.title}</Text>
              : null}

            {card.subtitle ?
              <Text style={styles.subtitle}>{card.subtitle}</Text>
              : null}

            {card.description ?
              <Text style={styles.description}>{card.description}</Text>
              : null}

            {card.link_1_title ?
              <TouchableOpacity onPress={() => openLink(card.link_1_url)}>
                <Text style={styles.link}>{card.link_1_title}</Text>
              </TouchableOpacity>
              : null}

            {card.link_2_title ?
              <TouchableOpacity onPress={() => openLink(card.link_2_url)}>
                <Text style={styles.link}>{card.link_2_title}</Text>
              </TouchableOpacity>
              : null}

            {card.link_3_title ?
              <TouchableOpacity onPress={() => openLink(card.link_3_url)}>
                <Text style={styles.link}>{card.link_3_title}</Text>
              </TouchableOpacity>
              : null}

            {card.link_4_title ?
              <TouchableOpacity onPress={() => openLink(card.link_4_url)}>
                <Text style={styles.link}>{card.link_4_title}</Text>
              </TouchableOpacity>
              : null}

            {card.link_5_title ?
              <TouchableOpacity onPress={() => openLink(card.link_5_url)}>
                <Text style={styles.link}>{card.link_5_title}</Text>
              </TouchableOpacity>
              : null}

            {card.link_6_title ?
              <TouchableOpacity onPress={() => openLink(card.link_6_url)}>
                <Text style={styles.link}>{card.link_6_title}</Text>
              </TouchableOpacity>
              : null}
          </View>
        </View>
      );
  }
};

const getStyles = theme => StyleSheet.create({
  container: {
    marginTop: 12,
    backgroundColor: theme.backgroundSecondary,
    borderRadius: 12,
    borderColor: theme.backgroundSecondary,
    borderWidth: 1,
    borderStyle: 'solid',
    width: 202,
  },
  image: {
    width: 200,
    height: 200,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  title: {
    fontSize: 15,
    textAlign: 'left',
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 5,
    color: theme.textPrimary,
  },
  subtitle: {
    fontSize: 14,
    textAlign: 'left',
    marginBottom: 5,
    color: theme.textSecondary,
  },
  description: {
    fontSize: 13,
    textAlign: 'left',
    marginBottom: 5,
    color: theme.textSecondary,
  },
  link: {
    fontSize: 15,
    textAlign: 'left',
    color: '#0279CC',
    marginBottom: 5,
  },
});

export default Card;
