import React, {useCallback, useContext, useMemo, useRef} from 'react';
import {
  Text,
  View,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  Share,
  Platform,
  ActivityIndicator,
} from 'react-native';

import {FontAwesome, MaterialIcons} from '@expo/vector-icons';
import {useTranslation} from 'react-i18next';
import * as Sentry from 'sentry-expo';

import Api from '../../../constants/Api';
import {ThemeContext, WEB_MAX_WIDTH} from '../../../contexts/ThemeContext';
import {formatDate, formatTime} from '../../../helpers/dateHelper';
import {debounce} from '../../../utils/debounce';

import Card from './Card';
import Bubble from './Bubble';
import ResponseLinks from './ResponseLinks';

let Maps;
let PlacesMap;

if (Platform.OS !== 'web') {
  Maps = require('react-native-maps');

  // eslint-disable-next-line react/display-name
  PlacesMap = ({cards}) => {
    const mapViewRef = useRef();

    return (
      <View
        style={{
          marginTop: 6,
          height: 200,
          borderRadius: 12,
          overflow: 'hidden',
        }}
      >
        <Maps.default
          ref={mapViewRef}
          provider={Maps.PROVIDER_GOOGLE}
          style={{
            width: '100%',
            height: '100%',
          }}
          showsUserLocation
          scrollEnabled={false}
          onMapReady={() => {
            setTimeout(() => mapViewRef.current && mapViewRef.current.fitToSuppliedMarkers(
              cards.map(({id}) => id.toString()),
              {
                animated: false,
                edgePadding: Platform.OS === 'ios'
                  ? {
                    top: 40,
                    right: 15,
                    bottom: 15,
                    left: 15,
                  }
                  : {
                    top: 150,
                    right: 50,
                    bottom: 50,
                    left: 50,
                  },
              }
            ), 0);
          }}
        >
          {cards.map(card => card.card_type === 'place' && (
            <Maps.Marker
              key={card.id}
              identifier={card.id.toString()}
              coordinate={{latitude: card.latitude, longitude: card.longitude}}
              title={card.title}
            />
          ))}
        </Maps.default>
      </View>
    );
  };
} else {
  Maps = require('@react-google-maps/api');

  // eslint-disable-next-line react/display-name
  PlacesMap = ({cards}) => {
    const {isLoaded, loadError} = Maps.useLoadScript({
      googleMapsApiKey: 'AIzaSyC6fgqbEA1FjrlKFs1Xz85Fps8tm_enBvI',
    });

    const renderMap = () => {
      // wrapping to a function is useful in case you want to access `window.google`
      // to eg. setup options or create latLng object, it won't be available otherwise
      // feel free to render directly if you don't need that

      function onLoad (mapInstance) {
        // do something with map Instance
        const bounds = new window.google.maps.LatLngBounds();

        cards.forEach(card => {
          if (card.card_type === 'place') {
            bounds.extend(new window.google.maps.LatLng(card.latitude, card.longitude));
          }
        });

        mapInstance.fitBounds(bounds);
      }

      return (
        <View
          style={{
            marginTop: 6,
            height: 200,
            borderRadius: 12,
            overflow: 'hidden',
          }}
        >
          <Maps.GoogleMap
            mapContainerStyle={{width: '100%', height: '100%'}}
            zoom={10}
            onLoad={onLoad}
          >
            {cards.map(card => card.card_type === 'place' && (
              <Maps.Marker
                key={card.id}
                position={{lat: card.latitude, lng: card.longitude}}
                title={card.title}
              />
            ))}
          </Maps.GoogleMap>
        </View>
      );
    };

    if (loadError) {
      return <div>Map cannot be loaded right now, sorry.</div>;
    }

    return isLoaded ? renderMap() : <ActivityIndicator size="small" style={{marginVertical: 2}}/>;
  };
}

const CHANNEL_ICONS = {
  Recording: <FontAwesome name="microphone" size={14}/>,
  recording: <FontAwesome name="microphone" size={14}/>,
  Web: <FontAwesome name="laptop" size={14}/>,
  web: <FontAwesome name="laptop" size={14}/>,
  Whatsapp: <FontAwesome name="whatsapp" size={16}/>,
  whatsapp: <FontAwesome name="whatsapp" size={16}/>,
  Email: <FontAwesome name="envelope-o" size={13}/>,
  email: <FontAwesome name="envelope-o" size={13}/>,
  Slack: <FontAwesome name="slack" size={14}/>,
  slack: <FontAwesome name="slack" size={14}/>,
  Telegram: <FontAwesome name="telegram" size={14}/>,
  telegram: <FontAwesome name="telegram" size={14}/>,
  Keyboard: <FontAwesome name="keyboard-o" size={14}/>,
  keyboard: <FontAwesome name="keyboard-o" size={14}/>,
  Sms: <MaterialIcons name="sms" size={14}/>,
  sms: <MaterialIcons name="sms" size={14}/>,
  Message: <MaterialIcons name="sms" size={14}/>,
  message: <MaterialIcons name="sms" size={14}/>,
};

const Chat = ({scrollViewRef, queries, setQueryText}) => {
  const {t} = useTranslation();
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme), [theme]);

  let currentDate = queries.length ? new Date(queries[0].created_at) : null;

  const examples = useMemo(() => [
    t('MainScreen.ChatView.Chat.example1', 'Explain quantum computing in simple terms'),
    t('MainScreen.ChatView.Chat.example2', 'Got any creative ideas for a 10 year old’s birthday?'),
    t('MainScreen.ChatView.Chat.example3', 'How do I make an HTTP request in Javascript?'),
  ], [t]);

  const handleShare = useCallback((query, response) => debounce(async () => {
    try {
      let cardsText = '';

      if (response.cards.length) {
        if (response.cards.length === 1) {
          // TODO: Handle weather and shazam cards
        } else {
          const texts = response.cards.reduce((result, card) => {
            if (card.card_type === 'place') {
              result.push(`- ${card.title} (${card.link_1_url})`);
            }

            return result;
          }, []);

          if (texts.length) {
            cardsText = `\n\n${texts.join('\n')}`;
          }
        }
      }

      const result = await Share.share({
        message: `${response.message}${cardsText}\n\n${t('MainScreen.ChatView.Chat.sharedByText', 'Message shared via {{appName}}.', {appName: Api.appName})}`,
      }, {
        subject: query.text,
      });

      console.log(result);

      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      // TODO: Handle errors
      if (Platform.OS === 'web') {
        Sentry.Browser.captureException(error);
      } else {
        Sentry.captureException(error);
      }
    }
  }, 300, true), [t]);

  return (
    <ScrollView
      ref={scrollViewRef}
      contentContainerStyle={{
        alignSelf: 'center',
        maxWidth: WEB_MAX_WIDTH,
        width: '100%',
        flexDirection: 'column',
        paddingHorizontal: 12,
        paddingBottom: 8,
      }}
    >
      {!queries.length && (
        <View style={styles.examplesContainer}>
          <Text style={[styles.text, {marginTop: 30, marginBottom: 30, maxWidth: 300, alignSelf: 'center'}]}>
            {t(
              'MainScreen.ChatView.Chat.exampleIntroduction',
              'Here are some examples of the things you can ask :'
            )}
          </Text>

          {examples.map(example => (
            <TouchableOpacity key={example} onPress={() => setQueryText(example)}>
              <Bubble variant="query" text={example}/>
            </TouchableOpacity>
          ))}
        </View>
      )}

      {queries.map((query, i) => {
        let shouldDisplayDate = i === 0 ? true : false;

        const queryDate = new Date(query.created_at);

        if (
          queryDate.getDate() !== currentDate.getDate()
          || queryDate.getMonth() !== currentDate.getMonth()
          || queryDate.getFullYear() !== currentDate.getFullYear()
        ) {
          currentDate = queryDate;
          shouldDisplayDate = true;
        }

        return (
          <View key={`${query.ref}-${query.id}`}>
            {shouldDisplayDate && (
              <Bubble variant="date" text={formatDate(currentDate, t)}/>
            )}

            <Bubble
              variant={query.status === 'empty' ? 'errorQuery' : 'query'}
              text={query.text}
            />

            <Text style={{fontSize: 11, color: theme.textSecondary, alignSelf: 'flex-end', marginRight: 12}}>
              {CHANNEL_ICONS[query.channel]} {formatTime(queryDate)}
            </Text>

            {query.status === 'empty'
              ? null
              : !query.responses || !query.responses.length
                ? queryDate.getTime() > Date.now() - 60000
                  // Note: Loading
                  ? query.text ? <Bubble variant="response"/> : null
                  // Note: Timed out
                  : (
                    <Bubble
                      variant="response"
                      text={t('MainScreen.ChatView.Chat.timeoutBubble', 'I am sorry, but I wasn\'t able to process this query.')}
                    />
                  )
                // Note: Actual responses
                : query.responses.map(response => (
                  <View key={response.id}>
                    <View key={response.id} style={{flexDirection: 'row', alignItems: 'center'}}>
                      <Bubble
                        variant="response"
                        text={response.message}
                      >
                        {(response.cards || []).length > 0 && response.cards.find(({card_type}) => card_type === 'place') && (
                          <PlacesMap cards={response.cards}/>
                        )}

                        {(response.cards || []).length > 0 && (
                          <ScrollView horizontal showsHorizontalScrollIndicator={false} style={{flexGrow: 0}}>
                            {response.cards.map((card, i) => (
                              <Card
                                key={card.id}
                                card={card}
                                isLast={i === response.cards.length - 1}
                              />
                            ))}
                          </ScrollView>
                        )}

                        <ResponseLinks response={response}/>
                      </Bubble>

                      <TouchableOpacity
                        style={{width: 40, height: 40, alignItems: 'center', justifyContent: 'center'}}
                        onPress={handleShare(query, response)}
                      >
                        <View style={{borderRadius: 20, padding: 6, backgroundColor: theme.light ? '#ffffff' : '#383838'}}>
                          <FontAwesome name="share" size={14} color={theme.colorSecondary}/>
                        </View>
                      </TouchableOpacity>
                    </View>

                    <Text style={{fontSize: 11, color: theme.textSecondary, alignSelf: 'flex-start', marginLeft: 12}}>
                      {Api.appName === 'ChaTTTTT'
                        ? formatTime(new Date(response.created_at))
                        : <>{CHANNEL_ICONS[response.channel]}  {formatTime(new Date(response.created_at))}{response.source ? ` ${t('MainScreen.ChatView.Chat.source', '- Source: {{source}}', {source: response.source})}`: ''}</>
                      }
                    </Text>
                  </View>
                ))
            }
          </View>
        );
      })}
    </ScrollView>
  );
};

const getStyles = theme => StyleSheet.create({
  examplesContainer: {
    flex: 1,
    paddingTop: 20,
  },
  text: {
    textAlign: 'center',
    color: theme.textPrimary,
    fontSize: 16,
  },
});

export default Chat;
