import {createContext} from 'react';
import * as Localization from 'expo-localization';

export const DEFAULT_SETTINGS = {
  theme: 'dark',
  muted: false,
  language: Localization.locale,
};

export const SettingsContext = createContext(DEFAULT_SETTINGS);
