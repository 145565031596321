import {useCallback, useContext, useEffect} from 'react';
import {Platform} from 'react-native';

import * as Speech from 'expo-speech';
import {Audio} from 'expo-av';

import {SettingsContext} from '../contexts/SettingsContext';
import {SpeakerContext} from '../contexts/SpeakerContext';

let responseBuffer = '';
let tokenBuffer = '';
let shouldSpeakQuery = true;
let speakingFalseInterval = null;

const useSpeaker = () => {
  const {settings: {muted, language}} = useContext(SettingsContext);
  const {setSpeaking} = useContext(SpeakerContext);

  const stop = useCallback(async () => {
    shouldSpeakQuery = false;
    Speech.stop();
    setSpeaking(false);
  }, [setSpeaking]);

  useEffect(() => {
    if (muted) {
      stop();
    }
  }, [muted]);

  const speak = useCallback(async (text, languageToSpeak = language) => {
    if (Platform.OS === 'ios') {
      await Audio.setAudioModeAsync({
        playsInSilentModeIOS: true,
        allowsRecordingIOS: false,
      });

      // HACK: We must play a sound via Audio from expo for allowsRecordingIOS: false to take
      // effect and the next sounds to be played through the speakers and not the earpiece
      // Follow up on: https://github.com/expo/expo/issues/20943
      const {sound} = await Audio.Sound.createAsync(require('../assets/silent.wav'));
      await sound.playAsync();
    }

    if (!muted) {
      Speech.speak(text, {
        language: languageToSpeak,
        onStart: () => {
          if (Platform.OS === 'web') {
            // Note: We use an interval to check if we need to set speaking to false every second
            //  to handle Safari which doesn't trigger the onDone callback for some reason...
            speakingFalseInterval = setInterval(() => {
              if (!window.speechSynthesis.speaking) {
                setSpeaking(false);
                clearInterval(speakingFalseInterval);
              }
            }, 1000);
          }

          setSpeaking(true);
        },
        onResume: () => {
          setSpeaking(true);
        },
        onPause: () => {
          setSpeaking(false);
        },
        onStopped: () => {
          setSpeaking(false);
        },
        onDone: () => {
          if (Platform.OS === 'web' && window.speechSynthesis.pending) {
            return;
          }

          setSpeaking(false);
        },
      });
    }
  }, [language, muted, setSpeaking]);

  const initSpeakQueryState = useCallback(() => {
    shouldSpeakQuery = true;
  }, []);

  const speakToken = useCallback(async (token, languageToSpeak = language) => {
    // console.log('-----------------------------');
    // console.log(`speakToken(${token})`);
    // console.log(`tokenBuffer: ${tokenBuffer} -> ${tokenBuffer}${token}`);
    // console.log(`responseBuffer: ${responseBuffer} -> ${responseBuffer}${tokenBuffer}`);
    // console.log(`${token.includes('.') || token.includes('\n') ? '*** Speak CALLED ***' : ''}`);
    // console.log('-----------------------------');

    tokenBuffer = `${tokenBuffer}${token}`;

    if (token.includes('.') || token.includes('\n')) {
      if (shouldSpeakQuery) {
        speak(tokenBuffer, languageToSpeak);
      }

      responseBuffer = `${responseBuffer}${tokenBuffer}`;
      tokenBuffer = '';
    }
  }, [language, speak]);

  const emptyResponseBuffer = useCallback(async (message, languageToSpeak = language) => {
    if (message.length > responseBuffer.length) {
      if (shouldSpeakQuery) {
        speak(message.slice(responseBuffer.length), languageToSpeak);
      }
    }

    responseBuffer = '';
    tokenBuffer = '';
  }, [language, speak]);

  return {
    speak,
    stop,
    initSpeakQueryState,
    speakToken,
    emptyResponseBuffer,
  };
};

export default useSpeaker;
