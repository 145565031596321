import React, {useContext, useMemo} from 'react';
import {ActivityIndicator, Dimensions, Platform, StyleSheet, Text, View} from 'react-native';

import Markdown from 'react-native-markdown-display';

import {ThemeContext, WEB_MAX_WIDTH} from '../../../contexts/ThemeContext';
import openLink from '../../../utils/openLink';

const PADDING_VERTICAL = 8;

const openUrl = (url, customCallback) => {
  if (customCallback) {
    const result = customCallback(url);
    if (url && result && typeof result === 'boolean') {
      openLink(url);
    }
  } else if (url) {
    openLink(url);
  }
};

const renderRules = {
  // Emphasis
  strong: (node, children, parent, styles) => (
    <Text key={node.key} style={styles.strong} selectable>
      {children}
    </Text>
  ),
  em: (node, children, parent, styles) => (
    <Text key={node.key} style={styles.em} selectable>
      {children}
    </Text>
  ),
  s: (node, children, parent, styles) => (
    <Text key={node.key} style={styles.s} selectable>
      {children}
    </Text>
  ),

  // Code
  code_inline: (node, children, parent, styles, inheritedStyles = {}) => (
    <Text key={node.key} style={[inheritedStyles, styles.code_inline, {padding: 0}]} selectable>
      {node.content}
    </Text>
  ),
  code_block: (node, children, parent, styles, inheritedStyles = {}) => {
    // we trim new lines off the end of code blocks because the parser sends an extra one.
    let {content} = node;

    if (
      typeof node.content === 'string' &&
      node.content.charAt(node.content.length - 1) === '\n'
    ) {
      content = node.content.substring(0, node.content.length - 1);
    }

    return (
      <Text key={node.key} style={[inheritedStyles, styles.code_block]} selectable>
        {content}
      </Text>
    );
  },
  fence: (node, children, parent, styles, inheritedStyles = {}) => {
    // we trim new lines off the end of code blocks because the parser sends an extra one.
    let {content} = node;

    if (
      typeof node.content === 'string' &&
      node.content.charAt(node.content.length - 1) === '\n'
    ) {
      content = node.content.substring(0, node.content.length - 1);
    }

    return (
      <Text key={node.key} style={[inheritedStyles, styles.fence]} selectable>
        {content}
      </Text>
    );
  },

  // Links
  link: (node, children, parent, styles, onLinkPress) => (
    <Text
      key={node.key}
      style={styles.link}
      onPress={() => openUrl(node.attributes.href, onLinkPress)}
      selectable
    >
      {children}
    </Text>
  ),

  // Text Output
  text: (node, children, parent, styles, inheritedStyles = {}) => (
    <Text key={node.key} style={[inheritedStyles, styles.text]} selectable>
      {node.content}
    </Text>
  ),
  textgroup: (node, children, parent, styles) => (
    <Text key={node.key} style={styles.textgroup} selectable>
      {children}
    </Text>
  ),
  hardbreak: (node, children, parent, styles) => (
    <Text key={node.key} style={styles.hardbreak} selectable>
      {'\n'}
    </Text>
  ),
  softbreak: (node, children, parent, styles) => (
    <Text key={node.key} style={styles.softbreak} selectable>
      {'\n'}
    </Text>
  ),

  inline: (node, children, parent, styles) => (
    <Text key={node.key} style={styles.inline} selectable>
      {children}
    </Text>
  ),
  span: (node, children, parent, styles) => (
    <Text key={node.key} style={styles.span} selectable>
      {children}
    </Text>
  ),
};

const Bubble = ({variant, text, children}) => {
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme), [theme]);

  const isMarkdown = text && variant === 'response' && text.search('```') !== -1;

  const content = useMemo(() => {
    // Note: Detect link only in response
    if (!text || variant !== 'response') return text;

    const linkRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s|,.]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s|,.]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s|,.]{2,}|www\.[a-zA-Z0-9]+\.[^\s|,.]{2,})/gm;

    const matches = text.matchAll(linkRegex);

    const result = [];
    let index = 0;

    for (const match of matches) {
      let start = match.index;
      const end = match.index + match[0].length;

      const fullLink = text.slice(start, end);

      const link = !fullLink.startsWith('http') ? `https://${fullLink}` : fullLink;

      result.push(text.slice(index, start));
      result.push(
        <Text
          key={`link-${index}`}
          style={[styles.text, styles[`${variant}Text`], {color: theme.link}]}
          onPress={() => openLink(link)}
          selectable
        >
          {fullLink}
        </Text>
      );

      index = end;
    }

    if (!result.length) {
      result.push(text);
    } else if (index < text.length) {
      result.push(text.slice(index, text.length));
    }

    return result;
  }, [styles, text, theme.link, variant]);

  return (
    <View
      style={[
        styles.bubble,
        {
          paddingVertical: isMarkdown ? 0 : PADDING_VERTICAL,
          maxWidth: Math.min(Dimensions.get('window').width, WEB_MAX_WIDTH) - 70,
        },
        styles[`${variant}Bubble`]]
      }
    >
      {text
        ? isMarkdown
          ? (
            <Markdown
              rules={renderRules}
              style={{
                body: {
                  paddingTop: 0,
                  color: theme.textPrimary,
                  fontSize: 16,
                },
                paragraph: {
                  marginTop: PADDING_VERTICAL,
                  marginBottom: PADDING_VERTICAL,
                },
                code_inline: {
                  backgroundColor: theme.backgroundSecondary,
                  ...Platform.select({
                    ios: {
                      fontFamily: 'Courier New',
                    },
                    android: {
                      fontFamily: 'monospace',
                    },
                  }),
                },
                code_block: {
                  backgroundColor: theme.backgroundSecondary,
                  borderColor: theme.backgroundSecondary,
                  fontSize: 14,
                  ...Platform.select({
                    ios: {
                      fontFamily: 'Courier New',
                    },
                    android: {
                      fontFamily: 'monospace',
                    },
                  }),
                },
                fence: {
                  backgroundColor: theme.backgroundSecondary,
                  borderColor: theme.backgroundSecondary,
                  fontSize: 14,
                  ...Platform.select({
                    ios: {
                      fontFamily: 'Courier New',
                    },
                    android: {
                      fontFamily: 'monospace',
                    },
                  }),
                },
              }}
            >
              {text}
            </Markdown>
          )
          : (
            <Text style={[styles.text, styles[`${variant}Text`]]} selectable>
              {content}
            </Text>
          )
        : <ActivityIndicator size="small"/>
      }

      {children}
    </View>
  );
};

// Styles
// ***********************************************

const getStyles = theme => StyleSheet.create({
  bubble: {
    borderRadius: 20,
    paddingHorizontal: 12,
    marginVertical: 6,
  },
  dateBubble: {
    marginTop: 50,
    backgroundColor: '#6C757D',
    alignSelf: 'center',
  },
  queryBubble: {
    backgroundColor: theme.colorPrimary, // '#167347',
    alignSelf: 'flex-end',
  },
  errorQueryBubble: {
    backgroundColor: theme.error,
    alignSelf: 'flex-end',
  },
  errorBubble: {
    backgroundColor: theme.error,
    alignSelf: 'flex-start',
  },
  responseBubble: {
    backgroundColor: theme.light ? '#ffffff' : '#383838',
    alignSelf: 'flex-start',
    marginRight: 6,
  },
  responseText: {
    color: theme.textPrimary,
  },
  text: {
    color: '#ffffff',
    fontSize: 16,
  },
});

export default Bubble;
