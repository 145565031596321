import React, {useCallback, useContext, useMemo, useState} from 'react';
import {Platform, StyleSheet, Text, View} from 'react-native';

import * as Notifications from 'expo-notifications';
import * as Sentry from 'sentry-expo';
import {useTranslation} from 'react-i18next';

import {ThemeContext} from '../../contexts/ThemeContext';
import {UserContext} from '../../contexts/UserContext';
import Api from '../../constants/Api';
import OnboardingLayout from './components/OnboardingLayout';
import StyledButton from '../../components/StyledButton';
import Bubble from '../MainScreen/Chat/Bubble';

const NEXT_SCREEN = 'ContactsPermission';

const LOADING_INITIAL_STATE = {loading: false, buttonClicked: null};

const PushNotificationPermissionScreen = ({navigation}) => {
	const {t} = useTranslation();
	const {userId, userToken, user, setUser} = useContext(UserContext);
	const theme = useContext(ThemeContext);
	const styles = useMemo(() => getStyles(theme), [theme]);

	const [loadingState, setLoadingState] = useState(LOADING_INITIAL_STATE);
	const [errorMessage, setErrorMessage] = useState(null);

	const updateUserHasCompletedOnboarding = useCallback(async (buttonClicked, tokenInfos = {}) => {
		try {
			setLoadingState({loading: true, buttonClicked});
			setErrorMessage(null);

			const result = await fetch(`${Api.apiBaseUrl}/users/${userId}`, {
				method: 'PATCH',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					user_token: userToken,
					has_completed_guest_onboarding: user.status === 'guest' ? true : undefined,
					...tokenInfos,
				}),
			});

			const updatedUser = await result.json();

			setUser(updatedUser);
			setLoadingState(LOADING_INITIAL_STATE);
		} catch (error) {
			setLoadingState(LOADING_INITIAL_STATE);

			setErrorMessage(t('PushNotificationPermissionScreen.errors.request', 'Something went wrong, try again later.'));

			if (Platform.OS === 'web') {
				Sentry.Browser.captureException(error);
			} else {
				Sentry.captureException(error);
			}
		}
	}, [userId, userToken, user.status, setUser, t]);

	const handleNotNowButtonClick = useCallback(async () => {
		if (user.status === 'guest') {
			updateUserHasCompletedOnboarding('notNowButton');
		} else {
			navigation.navigate(NEXT_SCREEN);
		}
	}, [navigation, updateUserHasCompletedOnboarding, user.status]);

	const handleAllowButtonClick = useCallback(async () => {
		const {status} = await Notifications.requestPermissionsAsync();

		if (status === 'granted') {
			try {
				const token = (await Notifications.getExpoPushTokenAsync()).data;

				updateUserHasCompletedOnboarding('allowButton', {
					push_token: token,
					has_notifications_enabled: true,
				});

				if (user.status !== 'guest') {
					navigation.navigate(NEXT_SCREEN);
				}
			} catch (error) {
				setLoadingState(LOADING_INITIAL_STATE);

				setErrorMessage(t('PushNotificationPermissionScreen.errors.request', 'Something went wrong, try again later.'));

				if (Platform.OS === 'web') {
					Sentry.Browser.captureException(error);
				} else {
					Sentry.captureException(error);
				}
			}
		}
	}, [updateUserHasCompletedOnboarding, user.status, navigation, t]);

	return (
		<OnboardingLayout
			footer={(
				<View style={{flexDirection: 'row'}}>
					<View style={{flex: 1, paddingRight: 6}}>
						<StyledButton
							variant="outlined"
							text={t('OnboardingLayout.notNow', 'Not now')}
							loading={loadingState.buttonClicked === 'notNowButton' && loadingState.loading}
							onPress={handleNotNowButtonClick}
						/>
					</View>

					<View style={{flex: 1, paddingLeft: 6}}>
						<StyledButton
							text={t('OnboardingLayout.allow', 'Allow →')}
							loading={loadingState.buttonClicked === 'allowButton' && loadingState.loading}
							onPress={handleAllowButtonClick}
						/>
					</View>
				</View>
			)}
		>
			{errorMessage && (
				<Text style={{textAlign: 'center', color: 'red', marginBottom: 10, fontSize: 17}}>
					{errorMessage}
				</Text>
			)}

			<Text style={[styles.text, {marginBottom: 14}]}>
				{t(
					'OnboardingLayout.introduction',
					'Here are some examples of the things you will be able to ask Secrétaire:'
				)}
			</Text>

			<Bubble variant="query" text={t('PushNotificationPermissionScreen.examples.1', 'Remind me to stop the oven in 30 minutes.')}/>

			<Bubble variant="query" text={t('PushNotificationPermissionScreen.examples.2', 'Add to my calendar the meeting with John on Tuesday at 10am.')}/>

			<Bubble variant="query" text={t('PushNotificationPermissionScreen.examples.3', 'Notify me when I receive my flight tickets by email.')}/>
		</OnboardingLayout>
	);
};

const getStyles = theme => StyleSheet.create({
	text: {
		textAlign: 'center',
		color: theme.textPrimary,
		fontSize: 17,
	},
});

export default PushNotificationPermissionScreen;
