import React, {useState, useContext, useMemo} from 'react';

import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  Platform,
} from 'react-native';
import Constants from 'expo-constants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useTranslation} from 'react-i18next';
import * as Device from 'expo-device';
import * as Localization from 'expo-localization';
import * as Sentry from 'sentry-expo';

import Api from '../../../constants/Api';
import {DEVICE_TYPE_LABELS} from '../../../constants/device';
import {ThemeContext} from '../../../contexts/ThemeContext';
import {UserContext} from '../../../contexts/UserContext';
import StyledButton from '../../../components/StyledButton';

const ConfirmForm = ({setStatus, phoneNumber}) => {
  const {t} = useTranslation();
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme), [theme]);
  const {user, userToken, deviceToken, setUser, setUserId, setUserToken} = useContext(UserContext);

  const [confirmationCode, setConfirmationCode] = useState('');
  const [loading, setLoading] = useState(false);

  const handleConfirmPress = async () => {
    if (confirmationCode === '') {
      alert(t('SignupScreen.confirm.alerts.missingConfirmationCode.message', 'Please enter the confirmation code received by SMS.'));
    } else {
      try {
        setLoading(true);

        const deviceType = await Device.getDeviceTypeAsync();

        let response = await fetch(`${Api.apiBaseUrl}/api/confirm`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            // Note: upgrade and user_token are used to upgrade guest accounts
            upgrade: !!user,
            user_token: userToken,

            phone_number: phoneNumber,
            confirmation_code: confirmationCode,
            expo_installation_id: Constants.installationId,

            country_code: Localization.getLocales()[0].regionCode,
            timezone: Localization.timezone,

            device_token: deviceToken,

            device_type: DEVICE_TYPE_LABELS[deviceType] || 'UNKNOWN',
            device_model_name: Device.modelName,
            brand: Device.brand, // Android: "google", "xiaomi"; iOS: "Apple"; web: null
            manufacturer: Device.manufacturer, // Android: "Google", "xiaomi"; iOS: "Apple"; web: "Google", null
            os_name: Platform.OS,
            os_version: Device.osVersion,
            name: Device.deviceName, // "Vivian's iPhone XS"
          }),
        });
        let responseJson = await response.json();

        console.log('****** response ******');
        console.log(responseJson);

        if (responseJson.status === 'ok') {
          const userResponse = await fetch(
            `${Api.apiBaseUrl}/users/${responseJson.user_id}.json?user_token=${responseJson.user_token}`,
            {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
            }
          );

          const userResponseJson = await userResponse.json();

          console.log('userResponseJson');
          console.log(userResponseJson);

          if(userResponseJson && userResponseJson.id) {
            console.log('fetchUser OK');

            setUser(userResponseJson);
          }

          _storeToken(responseJson.user_id, responseJson.user_token);
          setLoading(false);
        } else {
          alert(t('SignupScreen.confirm.alerts.invalidConfirmationCode.message', 'Invalid confirmation code.'));
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setStatus('error');
        setLoading(false);

        if (Platform.OS === 'web') {
          Sentry.Browser.captureException(error);
        } else {
          Sentry.captureException(error);
        }
      }
    }
  };

  const handleCancelPress = () => {
    setStatus('initial');
    setConfirmationCode('');
  };

  const _storeToken = async (userId, userToken) => {
    try {
      await AsyncStorage.setItem('userId', userId);
      await AsyncStorage.setItem('userToken', userToken);

      setUserId(userId);
      setUserToken(userToken);
    } catch (error) {
      console.error(error);

      if (Platform.OS === 'web') {
        Sentry.Browser.captureException(error);
      } else {
        Sentry.captureException(error);
      }
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.subtitle}>
        {t('SignupScreen.confirm.title', 'Please enter the confirmation code that was just sent to {{phoneNumber}}.', {phoneNumber})}
      </Text>

      <View style={styles.inputContainer} rounded>
        <TextInput
          style={styles.input}
          placeholder={t('SignupScreen.confirm.codeInput.placeholder', 'Enter confirmation code')}
          placeholderTextColor={theme.textSecondary}
          keyboardType="phone-pad"
          returnKeyType="done"
          autoCapitalize="none"
          autoCorrect={false}
          secureTextEntry={false}
          value={confirmationCode}
          onChangeText={(val) => setConfirmationCode(val)}
        />
      </View>

      <StyledButton
        style={styles.button}
        text={t('SignupScreen.confirm.buttons.confirm.text', 'Confirm')}
        loading={loading}
        onPress={handleConfirmPress}
      />

      <TouchableOpacity style={styles.cancelButton} onPress={handleCancelPress}>
        <Text style={styles.cancelButtonText}>
          {t('SignupScreen.confirm.buttons.cancel.text', 'Cancel')}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

// TODO: Refactor styles with the other component
const getStyles = theme => StyleSheet.create({
  container: {
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'center',
    maxWidth: 250,
    color: theme.textPrimary,
  },
  inputContainer: {
    flexDirection: 'row',
    maxWidth: 320,
    marginTop: 15,
    marginBottom: 25,
    padding: 10,
    borderRadius: 50,
    backgroundColor: theme.inputBackgroundColor, // '#f7f8fc',
    borderColor: theme.inputBorderColor, // '#f7f8fc',
  },
  input: {
    flex: 1,
    padding: 6,
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.textPrimary,
  },
  button: {
    paddingVertical: 15,
    paddingHorizontal: 80,
    marginBottom: 30,
  },
  cancelButtonText: {
    fontSize: 16,
    color: theme.textSecondary,
  },
});

export default ConfirmForm;
