import React, {useContext} from 'react';
import {StatusBar} from 'react-native';

import {ThemeContext} from '../contexts/ThemeContext';

const StyledStatusBar = () => {
	const theme = useContext(ThemeContext);

	return (
		<StatusBar
			barStyle={theme.light ? 'dark-content' : 'light-content'}
			backgroundColor={theme.headerBackground}
		/>
	);
};

export default StyledStatusBar;
