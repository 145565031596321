import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {
  View,
  Text,
  Modal,
  FlatList,
  StyleSheet,
  SafeAreaView,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity,
  Keyboard,
  TouchableWithoutFeedback,
} from 'react-native';

import {useTranslation} from 'react-i18next';

import {ThemeContext} from '../contexts/ThemeContext';

import StyledButton from './StyledButton';
import StyledInput from './StyledInput';

const SearchModal = ({
  data,
  open,
  close,
  onClick,
  getText = item => item.name,
}) => {
  const {t} = useTranslation();
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme), [theme]);

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setSearchText('');
  }, [open]);

  const onChangeText = useCallback(value => setSearchText(value), [setSearchText]);

  const handleClick = useCallback(item => () => {
    onClick(item);
    close();
  }, [onClick, close]);

  const filteredList = useMemo(() => {
    return data.filter(({name}) => name.toLowerCase().includes(searchText.toLowerCase()));
  }, [data, searchText]);

  return (
    <Modal
      animationType="slide"
      transparent={false}
      visible={open}
      onRequestClose={close}
      onDismiss={close}
    >
      <SafeAreaView style={styles.container}>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : null}
          style={{flex: 1}}
        >
          <TouchableWithoutFeedback style={{flex: 1}} accessible={false}>
            <View style={{flex: 1}}>
              <View style={{flex: 1}}>
                <View style={{padding: 16}}>
                  <StyledInput
                    value={searchText}
                    onChangeText={onChangeText}
                    placeholder={t('SearchModal.input.placeholder', 'Filter')}
                  />
                </View>

                <FlatList
                  keyboardShouldPersistTaps="handled"
                  data={filteredList}
                  keyExtractor={(item) => item.code}
                  renderItem={({item}) => (
                    <TouchableOpacity onPress={handleClick(item)}>
                      <View style={styles.countryStyle}>
                        <Text style={{fontSize: 36}}>
                          {item.flag}
                        </Text>

                        <Text style={styles.text}>
                          {getText(item)}
                        </Text>
                      </View>
                    </TouchableOpacity>
                  )}
                />
              </View>

              <View style={styles.closeContainer}>
                <StyledButton
                  variant="outlined"
                  text={t('SearchModal.buttons.close', 'Close')}
                  onPress={close}
                />
              </View>
            </View>
          </TouchableWithoutFeedback>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </Modal>
  );
};

const getStyles = theme => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.backgroundPrimary,
  },
  countryStyle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopColor: theme.headerBorderColor,
    borderTopWidth: 1,
    paddingHorizontal: 20,
  },
  text: {
    fontSize: 16,
    color: theme.textPrimary,
  },
  closeContainer: {
    padding: 16,
    borderTopColor: theme.footerBorderColor,
    borderTopWidth: 1,
  },
});

export default SearchModal;
