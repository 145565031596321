import React, {useContext, useMemo, useCallback} from 'react';
import {Alert, Image, Platform, StyleSheet, Text, View} from 'react-native';
import {useTranslation} from 'react-i18next';
import {useNavigation} from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import * as Sentry from 'sentry-expo';


import {UserContext} from '../contexts/UserContext';
import {ThemeContext} from '../contexts/ThemeContext';
import StyledButton from '../components/StyledButton';
import Api from '../constants/Api';

const AccountScreen = () => {
	const {t} = useTranslation();
	const {userId, userToken, user, signOut, subscribed} = useContext(UserContext);
	const theme = useContext(ThemeContext);
	const styles = useMemo(() => getStyles(theme), [theme]);
	const {navigate} = useNavigation();

	const linkBankAccount = useCallback(async () => {
		let result = await WebBrowser.openAuthSessionAsync(`${Api.apiBaseUrl}/bank_accounts?user_token=${userToken}&persist=true`);

		console.log('webbrowser result', result);
	}, [userToken]);

	const logOut = useCallback(() => {
		if (Platform.OS === 'web') {
			if (window.confirm(t('AccountScreen.alerts.logout.message', 'Are you sure you want to log out ?'))) {
				signOut();
			}
		} else {
			Alert.alert(
				t('AccountScreen.alerts.logout.title', 'Log out'),
				t('AccountScreen.alerts.logout.message', 'Are you sure you want to log out ?'),
				[
					{text: t('AccountScreen.alerts.logout.buttons.cancel', 'Cancel'), style: 'cancel'},
					{
						text: t('AccountScreen.alerts.logout.buttons.confirm', 'Log out'),
						onPress: () => {
							signOut();
						},
					},
				]
			);
		}
	}, [signOut, t]);

	const deleteAccount = useCallback(async () => {
		if (Platform.OS === 'web') {
			if (window.confirm(t('AccountScreen.alerts.delete.message', 'Are you sure to PERMANENTLY delete your account? This action is irreversible.'))) {
				try {
					await fetch(`${Api.apiBaseUrl}/users/${userId}`, {
						method: 'PATCH',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							user_token: userToken,
							status: 'deleted',
						}),
					});

					signOut();
				} catch (error) {
					// TODO: Handle error
					if (Platform.OS === 'web') {
						Sentry.Browser.captureException(error);
					} else {
						Sentry.captureException(error);
					}
				}
			}
		} else {
			Alert.alert(
				t('AccountScreen.alerts.delete.title', 'Delete account'),
				t('AccountScreen.alerts.delete.message', 'Are you sure to PERMANENTLY delete your account? This action is irreversible.'),
				[
					{text: t('AccountScreen.alerts.delete.buttons.cancel', 'Cancel'), style: 'cancel'},
					{
						text: t('AccountScreen.alerts.delete.buttons.confirm', 'Delete account'),
						onPress: async () => {
							try {
								await fetch(`${Api.apiBaseUrl}/users/${userId}`, {
									method: 'PATCH',
									headers: {
										'Accept': 'application/json',
										'Content-Type': 'application/json',
									},
									body: JSON.stringify({
										user_token: userToken,
										status: 'deleted',
									}),
								});

								signOut();
							} catch (error) {
								// TODO: Handle error
								if (Platform.OS === 'web') {
									Sentry.Browser.captureException(error);
								} else {
									Sentry.captureException(error);
								}
							}
						},
					},
				]
			);
		}
	}, [signOut, t, userId, userToken]);

	return (
		<View style={styles.mainContainer}>
			<View style={styles.topContainer}>
				<Image
					source={(user || {}).image_url ? user.image_url : require('../assets/images/user-blank.png')}
					style={styles.image}
				/>

				<Text style={styles.name}>
					{user && user.status !== 'guest' ? `${user.first_name || ''} ${user.last_name || ''}` : t('AccountScreen.guest', 'Guest user')}
				</Text>

				{(user && user.status !== 'guest')
					? (
						<Text style={{color: theme.textSecondary, fontSize: 16, marginBottom: 20}}>
							{user.email || ''}
						</Text>
					)
					: (
						<StyledButton
							style={{width: 250, marginBottom: 20}}
							text={t('AccountScreen.buttons.createAccount.text', 'Create my free account')}
							onPress={() => navigate('Signup')}
						/>
					)}

				{subscribed && (
					<Text style={{color: theme.success, fontSize: 16, marginBottom: 20}}>
						{t('AccountScreen.subscriptionActive', '★ Subscription active ★')}
					</Text>
				)}

				<StyledButton
					style={{width: 250, marginBottom: 20}}
					variant="textButtonPrimary"
					text={t('AccountScreen.buttons.linkBankAccount.text', 'Link bank account')}
					onPress={linkBankAccount}
				/>
			</View>

			<View>
				<StyledButton
					style={{width: 250, marginBottom: 20}}
					text={t('AccountScreen.buttons.logOut.text', 'Log out')}
					variant="outlinedSecondary"
					onPress={logOut}
				/>

				<StyledButton
					text={t('AccountScreen.buttons.delete.text', 'Delete account')}
					variant="textButtonSecondary"
					onPress={deleteAccount}
				/>
			</View>
		</View>
	);
};

const getStyles = theme => StyleSheet.create({
	mainContainer: {
		flex: 1,
		paddingHorizontal: 12,
		paddingTop: 40,
		paddingBottom: 20,
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: theme.backgroundPrimary,
	},
	topContainer: {
		alignItems: 'center',
	},
	image: {
		width: 100,
		height: 100,
		borderRadius: 50,
		marginBottom: 20,
	},
	name: {
		fontSize: 20,
		color: theme.textPrimary,
		marginBottom: 10,
	},
});

export default AccountScreen;
