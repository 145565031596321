import React, {useCallback, useContext, useMemo, useState} from 'react';
import {
  Text,
  View,
  StyleSheet,
  Platform,
} from 'react-native';

import * as Contacts from 'expo-contacts';
import * as Sentry from 'sentry-expo';
import {useTranslation} from 'react-i18next';

import {ThemeContext} from '../../contexts/ThemeContext';
import {UserContext} from '../../contexts/UserContext';
import Api from '../../constants/Api';
import OnboardingLayout from './components/OnboardingLayout';
import StyledButton from '../../components/StyledButton';
import Bubble from '../MainScreen/Chat/Bubble';

const LOADING_INITIAL_STATE = {loading: false, buttonClicked: null};

const ContactsPermissionScreen = ({navigation}) => {
  const {t} = useTranslation();
  const {userId, userToken, user, setUser} = useContext(UserContext);
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme), [theme]);

  const [loadingState, setLoadingState] = useState(LOADING_INITIAL_STATE);
  const [errorMessage, setErrorMessage] = useState(null);

  const updateUserHasCompletedOnboarding = useCallback(async buttonClicked => {
    navigation.navigate('Upgrade');
    // try {
    //   setLoadingState({loading: true, buttonClicked});
    //   setErrorMessage(null);
    //
    //   const result = await fetch(`${Api.apiBaseUrl}/users/${userId}`, {
    //     method: 'PATCH',
    //     headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({
    //       user_token: userToken,
    //       ...(user.status === 'guest'
    //         ? {has_completed_guest_onboarding: true}
    //         : {has_completed_onboarding: true}
    //       ),
    //     }),
    //   });
    //
    //   const updatedUser = await result.json();
    //
    //   setUser(updatedUser);
    //   setLoadingState(LOADING_INITIAL_STATE);
    // } catch (error) {
    //   setLoadingState(LOADING_INITIAL_STATE);
    //
    //   setErrorMessage(t('ContactsPermissionScreen.errors.request', 'Something went wrong, try again later.'));
    //
    //   if (Platform.OS === 'web') {
    //     Sentry.Browser.captureException(error);
    //   } else {
    //     Sentry.captureException(error);
    //   }
    // }
  }, [userId, userToken, user.status, setUser, t]);

  const handleNotNowButtonClick = useCallback(() => {
    updateUserHasCompletedOnboarding('notNowButton');
  }, [updateUserHasCompletedOnboarding]);

  const handleAllowButtonClick = useCallback(async () => {
    const {status} = await Contacts.requestPermissionsAsync();

    const updateUserContacts = async () => {
      try {
        const result = await Contacts.getContactsAsync({
          fields: [
            Contacts.Fields.Addresses,
            Contacts.Fields.Birthday,
            Contacts.Fields.Company,
            Contacts.Fields.ContactType,
            Contacts.Fields.Dates,
            // Note: Undefined on IOS
            // Contacts.Fields.Departement,
            Contacts.Fields.Emails,
            Contacts.Fields.ExtraNames,
            Contacts.Fields.FirstName,
            Contacts.Fields.ID,
            Contacts.Fields.Image,
            Contacts.Fields.ImageAvailable,
            Contacts.Fields.InstantMessageAddresses,
            Contacts.Fields.JobTitle,
            Contacts.Fields.LastName,
            Contacts.Fields.MaidenName,
            Contacts.Fields.MiddleName,
            Contacts.Fields.Name,
            Contacts.Fields.NamePrefix,
            Contacts.Fields.NameSuffix,
            Contacts.Fields.Nickname,
            Contacts.Fields.NonGregorianBirthday,
            // Note: Require additional permissions on IOS
            // Contacts.Fields.Note,
            Contacts.Fields.PhoneNumbers,
            Contacts.Fields.PhoneticFirstName,
            Contacts.Fields.PhoneticLastName,
            Contacts.Fields.PhoneticMiddleName,
            Contacts.Fields.RawImage,
            Contacts.Fields.Relationships,
            Contacts.Fields.SocialProfiles,
            Contacts.Fields.UrlAddresses,
          ],
        });

        const contacts = result.data;

        fetch(`${Api.apiBaseUrl}/contacts`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_token: userToken,
            contact: contacts.map(contact => {
              const facebookProfile = contact.socialProfiles && contact.socialProfiles.length && contact.socialProfiles.find(({service}) => service === 'Facebook');

              return ({
                user_id: userId,

                origin: 'device',
                ref: contact.id,
                contact_type: contact.contactType,

                name: contact.name,
                first_name: contact.firstName,
                last_name: contact.lastName,
                middle_name: contact.middleName,

                email_address_1: contact.emails && contact.emails.length && contact.emails[0] && contact.emails[0].email ? contact.emails[0].email : null,
                email_address_1_label: contact.emails && contact.emails.length && contact.emails[0] && contact.emails[0].label ? contact.emails[0].label : null,

                email_address_2: contact.emails && contact.emails.length && contact.emails[1] && contact.emails[1].email ? contact.emails[1].email : null,
                email_address_2_label: contact.emails && contact.emails.length && contact.emails[1] && contact.emails[1].label ? contact.emails[1].label : null,

                email_address_3: contact.emails && contact.emails.length && contact.emails[2] && contact.emails[2].email ? contact.emails[2].email : null,
                email_address_3_label: contact.emails && contact.emails.length && contact.emails[2] && contact.emails[2].label ? contact.emails[2].label : null,

                phone_number_1: contact.phoneNumbers && contact.phoneNumbers.length && contact.phoneNumbers[0] && (contact.phoneNumbers[0].digits || contact.phoneNumbers[0].number) ? (contact.phoneNumbers[0].digits || contact.phoneNumbers[0].number) : null,
                phone_number_1_formatted: contact.phoneNumbers && contact.phoneNumbers.length && contact.phoneNumbers[0] && contact.phoneNumbers[0].number ? contact.phoneNumbers[0].number : null,
                phone_number_1_label: contact.phoneNumbers && contact.phoneNumbers.length && contact.phoneNumbers[0] && contact.phoneNumbers[0].label ? contact.phoneNumbers[0].label : null,

                phone_number_2: contact.phoneNumbers && contact.phoneNumbers.length && contact.phoneNumbers[1] && (contact.phoneNumbers[1].digits || contact.phoneNumbers[1].number) ? (contact.phoneNumbers[1].digits || contact.phoneNumbers[1].number) : null,
                phone_number_2_formatted: contact.phoneNumbers && contact.phoneNumbers.length && contact.phoneNumbers[1] && contact.phoneNumbers[1].number ? contact.phoneNumbers[1].number : null,
                phone_number_2_label: contact.phoneNumbers && contact.phoneNumbers.length && contact.phoneNumbers[1] && contact.phoneNumbers[1].label ? contact.phoneNumbers[1].label : null,

                phone_number_3: contact.phoneNumbers && contact.phoneNumbers.length && contact.phoneNumbers[2] && (contact.phoneNumbers[2].digits || contact.phoneNumbers[2].number) ? (contact.phoneNumbers[2].digits || contact.phoneNumbers[2].number) : null,
                phone_number_3_formatted: contact.phoneNumbers && contact.phoneNumbers.length && contact.phoneNumbers[2] && contact.phoneNumbers[2].number ? contact.phoneNumbers[2].number : null,
                phone_number_3_label: contact.phoneNumbers && contact.phoneNumbers.length && contact.phoneNumbers[2] && contact.phoneNumbers[2].label ? contact.phoneNumbers[2].label : null,

                phone_number_4: contact.phoneNumbers && contact.phoneNumbers.length && contact.phoneNumbers[3] && (contact.phoneNumbers[3].digits || contact.phoneNumbers[3].number) ? (contact.phoneNumbers[3].digits || contact.phoneNumbers[3].number) : null,
                phone_number_4_formatted: contact.phoneNumbers && contact.phoneNumbers.length && contact.phoneNumbers[3] && contact.phoneNumbers[3].number ? contact.phoneNumbers[3].number : null,
                phone_number_4_label: contact.phoneNumbers && contact.phoneNumbers.length && contact.phoneNumbers[3] && contact.phoneNumbers[3].label ? contact.phoneNumbers[3].label : null,

                phone_number_5: contact.phoneNumbers && contact.phoneNumbers.length && contact.phoneNumbers[4] && (contact.phoneNumbers[4].digits || contact.phoneNumbers[4].number) ? (contact.phoneNumbers[4].digits || contact.phoneNumbers[4].number) : null,
                phone_number_5_formatted: contact.phoneNumbers && contact.phoneNumbers.length && contact.phoneNumbers[4] && contact.phoneNumbers[4].number ? contact.phoneNumbers[4].number : null,
                phone_number_5_label: contact.phoneNumbers && contact.phoneNumbers.length && contact.phoneNumbers[4] && contact.phoneNumbers[4].label ? contact.phoneNumbers[4].label : null,

                facebook_username: facebookProfile ? facebookProfile.username : null,
                facebook_url: facebookProfile ? facebookProfile.url : null,
              });
            }),
          }),
        });
      } catch (error) {
        // Note: We just ignore if this fail since it's not critical
        console.log(error);
      }
    };

    if (status === 'granted') {
      // Note: We don't await here to not block the user on the page while we save his contacts since
      //  it's not critical
      updateUserContacts();

      updateUserHasCompletedOnboarding('allowButton');

      // Note: This Alert ask if the user wants to add Secretaire AI as a contact, useless for now
      //
      // return Alert.alert(
      //   t('ContactsPermissionScreen.alert.title', 'Do you want to add Secrétaire contact information ?'),
      //   t('ContactsPermissionScreen.alert.message', 'This will add Secrétaire contact information to your contacts, you can use them to make queries by email, phone and Whatsapp.'),
      //   [
      //     {text: t('ContactsPermissionScreen.alert.buttons.no', 'No'), style: 'cancel', onPress: () => updateUserHasCompletedOnboarding('allowButton')},
      //     {
      //       text: t('ContactsPermissionScreen.alert.buttons.yes', 'Yes'),
      //       onPress: async () => {
      //         await Contacts.addContactAsync({
      //           [Contacts.Fields.Name]: 'Secrétaire AI',
      //           [Contacts.Fields.Company]: 'Secrétaire AI',
      //           [Contacts.Fields.ContactType]: 'company',
      //           [Contacts.Fields.Emails]: [
      //             {email: 'secretaire@secretaire.ai', label: 'Main'},
      //             {email: `${user.username}@secretaire.ai`, label: 'Your personal address'},
      //           ],
      //           [Contacts.Fields.PhoneNumbers]: [
      //             {number: '+15854400009', label: 'Sms'},
      //             {number: '+33780974620', label: 'Whatsapp'},
      //           ],
      //           [Contacts.Fields.UrlAddresses]: [
      //             {url: 'https://www.secretaire.ai', label: 'Website'},
      //           ],
      //         });
      //
      //         updateUserHasCompletedOnboarding('allowButton');
      //       },
      //     },
      //   ]
      // );
    }
  }, [updateUserHasCompletedOnboarding, userId, userToken]);

  return (
    <OnboardingLayout
      footer={(
        <View style={{flexDirection: 'row'}}>
          <View style={{flex: 1, paddingRight: 6}}>
            <StyledButton
              variant="outlined"
              text={t('OnboardingLayout.notNow', 'Not now')}
              loading={loadingState.buttonClicked === 'notNowButton' && loadingState.loading}
              onPress={handleNotNowButtonClick}
            />
          </View>

          <View style={{flex: 1, paddingLeft: 6}}>
            <StyledButton
              text={t('OnboardingLayout.allow', 'Allow →')}
              loading={loadingState.buttonClicked === 'allowButton' && loadingState.loading}
              onPress={handleAllowButtonClick}
            />
          </View>
        </View>
      )}
    >
      {errorMessage && (
        <Text style={{textAlign: 'center', color: 'red', marginBottom: 10, fontSize: 17}}>
          {errorMessage}
        </Text>
      )}

      <Text style={[styles.text, {marginBottom: 14}]}>
        {t(
          'OnboardingLayout.introduction',
          'Here are some examples of the things you will be able to ask Secrétaire:'
        )}
      </Text>

      <Bubble variant="query" text={t('ContactsPermissionScreen.examples.1', 'Send the recipe of the chocolate cake to Mom.')}/>

      <Bubble variant="query" text={t('ContactsPermissionScreen.examples.2', 'Tell my brother I\'m on my way by Whatsapp.')}/>

      <Bubble variant="query" text={t('ContactsPermissionScreen.examples.3', 'Forward my flight tickets confirmation to Veronica.')}/>
    </OnboardingLayout>
  );
};

const getStyles = theme => StyleSheet.create({
  text: {
    textAlign: 'center',
    color: theme.textPrimary,
    fontSize: 17,
  },
});

export default ContactsPermissionScreen;
