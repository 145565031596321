import React, {useContext} from 'react';
import {
  View,
  Text,
  Linking,
} from 'react-native';

import {useNavigation} from '@react-navigation/native';
import {useTranslation} from 'react-i18next';
import * as Location from 'expo-location';

import {ThemeContext} from '../../../contexts/ThemeContext';
import {UserContext} from '../../../contexts/UserContext';
import StyledButton from '../../../components/StyledButton';
import openLink from '../../../utils/openLink';

const ResponseLink = ({type, url, title}) => {
  const {t} = useTranslation();
  const {user} = useContext(UserContext);
  const {navigate} = useNavigation();
  const theme = useContext(ThemeContext);

  switch (type) {
    case 'SIGN_UP':
      return (
        <>
          <Text style={{fontSize: 16, color: theme.textPrimary, marginVertical: 10}}>
            {user.status === 'guest'
              ? t(
                'UpgradeMenu.textGuest',
                'You get {{count}} free queries every day as a guest. Create your account to get more.',
                {count: user.free_queries},
              )
              : t(
                'UpgradeMenu.textUser',
                'You get {{count}} free queries every day. Upgrade to a premium account to get unlimited queries and much more.',
                {count: user.free_queries},
              )
            }
          </Text>

          <StyledButton
            style={{backgroundColor: theme.success}}
            text={user.status === 'guest'
              ? t('UpgradeMenu.buttons.createAccount', 'Create my free account')
              : t('UpgradeMenu.buttons.upgrade', 'Upgrade account')
            }
            onPress={() => user.status === 'guest'
              ? navigate('Signup')
              : navigate('Upgrade')
            }
          />
        </>
      );
    case 'PERMISSION_LOCATION':
      return (
        <StyledButton
          text={t('ResponseLinks.button.permissionLocation', 'Activate geolocation')}
          onPress={async () => {
            const {canAskAgain} = await Location.getForegroundPermissionsAsync();

            if (canAskAgain) {
              Location.requestForegroundPermissionsAsync();
            } else {
              Linking.openSettings();
            }
          }}
        />
      );
    case 'URL':
      return (
        <StyledButton
          text={title}
          onPress={() => openLink(url)}
        />
      );
  }
};

const ResponseLinks = ({response}) => {
  const results = [];

  for (let i = 1; i < 5; i++) {
    if (response[`link_${i}_type`]) {
      results.push(
        <ResponseLink
          key={`${response.id}-link-${i}`}
          type={response[`link_${i}_type`]}
          url={response[`link_${i}_url`]}
          title={response[`link_${i}_title`]}
        />
      );
    }
  }

  if (!results.length) return null;

  return (
    <View style={{gap: 12, marginVertical: 10}}>
      {results}
    </View>
  );
};

export default ResponseLinks;
