export const formatDate = (date, t) => {
	const today = new Date();

	if (
		date.getDate() === today.getDate()
		&& date.getMonth() === today.getMonth()
		&& date.getFullYear() === today.getFullYear()
	) {
		return t('dateHelper.today', 'Today');
	}

	const months = [
		t('dateHelper.months.jan', 'Jan'),
		t('dateHelper.months.feb', 'Feb'),
		t('dateHelper.months.mar', 'Mar'),
		t('dateHelper.months.apr', 'Apr'),
		t('dateHelper.months.may', 'May'),
		t('dateHelper.months.jun', 'Jun'),
		t('dateHelper.months.jul', 'Jul'),
		t('dateHelper.months.aug', 'Aug'),
		t('dateHelper.months.sep', 'Sep'),
		t('dateHelper.months.oct', 'Oct'),
		t('dateHelper.months.nov', 'Nov'),
		t('dateHelper.months.dec', 'Dec'),
	];

	const monthName = months[date.getMonth()];
	const day = date.getDate();
	const year = date.getFullYear();

	return `${monthName} ${day}, ${year}`;
};

export const formatHoursAndMinutes = (hours, minutes) => {
	if (hours < 10) {
		hours = `0${hours}`;
	}

	if (minutes < 10) {
		minutes = `0${minutes}`;
	}

	return `${hours}:${minutes}`;
};

export const formatTime = date => formatHoursAndMinutes(date.getHours(), date.getMinutes());

