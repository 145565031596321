if (__DEV__) {
  console.log('***** App running in Development mode. *****');
} else {
  console.log('***** App running in Production mode. *****');
}

const appName = 'Secretaire';

// const apiBaseUrl = 'http://192.168.1.183:3000'

const apiBaseUrl = 'http://localhost:3000';
const assetsBaseUrl = 'http://localhost:3000';
const webBaseUrl = 'http://localhost:3000';
const webSocketBaseUrl = 'wss://localhost:3000/cable';

// const apiBaseUrl = 'https://api.secretaire.ai';
// const assetsBaseUrl = 'https://api.secretaire.ai';
// const webBaseUrl = 'https://www.secretaire.ai';
// const webSocketBaseUrl = 'wss://www.secretaire.ai/cable';

console.log('apiBaseUrl', apiBaseUrl);
console.log('assetsBaseUrl', assetsBaseUrl);

export default {
  appName,
  apiBaseUrl,
  assetsBaseUrl,
  webBaseUrl,
  webSocketBaseUrl,
};
