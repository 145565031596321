import React, {useContext, useMemo} from 'react';
import {Text, TouchableOpacity, StyleSheet, ActivityIndicator} from 'react-native';
import {ThemeContext} from '../contexts/ThemeContext';

const StyledButton = ({
  text,
  disabled,
  loading,
  onPress,
  variant = 'regular',
  fontSize = 16,
  style,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme), [theme]);

  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled || loading}
      style={[
        styles.button,
        styles[variant],
        ...(style && style.length ? style || [] : [style]),
      ]}
      {...props}
    >
      {loading
        ? <ActivityIndicator size="small" style={{marginVertical: 2}}/>
        : <Text style={[{fontSize}, styles[`${variant}Text`]]}>{text}</Text>}
    </TouchableOpacity>
  );
};

const getStyles = theme => StyleSheet.create({
  button: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 15,
    textAlign: 'center',
    alignItems: 'center',
  },
  regular: {
    backgroundColor: theme.colorPrimary,
  },
  outlined: {
    borderColor: theme.colorPrimary,
    borderWidth: 1,
  },
  outlinedSecondary: {
    borderColor: theme.textSecondary,
    borderWidth: 1,
  },
  outlinedError: {
    borderColor: theme.error,
    borderWidth: 1,
  },
  textButtonPrimary: {},
  textButtonSecondary: {},
  textButtonError: {},
  regularText: {
    color: theme.buttonText,
  },
  outlinedText: {
    color: theme.colorPrimary,
  },
  outlinedSecondaryText: {
    color: theme.textPrimary,
  },
  outlinedErrorText: {
    color: theme.error,
  },
  textButtonPrimaryText: {
    color: theme.textPrimary,
  },
  textButtonSecondaryText: {
    color: theme.textSecondary,
  },
  textButtonErrorText: {
    color: theme.error,
  },
});

export default StyledButton;
