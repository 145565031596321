import {Platform} from 'react-native';
import * as Linking from 'expo-linking';

const openLink = url => {
	if (Platform.OS === 'web') {
		window.open(url,'_blank');
	} else {
		Linking.openURL(url);
	}
};

export default openLink;
