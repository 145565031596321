export function debounce(func, wait, immediate, context) {
	let result;
	let timeout = null;

	return function() {
		const ctx = context || this, args = arguments;
		const later = function () {
			timeout = null;
			if (!immediate) result = func.apply(ctx, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) result = func.apply(ctx, args);
		return result;
	};
}
