import React, {useState, useContext, useMemo} from 'react';
import {Platform, StyleSheet, Text, View} from 'react-native';
import {useTranslation} from 'react-i18next';
import * as Sentry from 'sentry-expo';

import {UserContext} from '../../contexts/UserContext';
import {ThemeContext} from '../../contexts/ThemeContext';
import Api from '../../constants/Api';
import StyledInput from '../../components/StyledInput';
import StyledButton from '../../components/StyledButton';
import OnboardingLayout from './components/OnboardingLayout';

const NameScreen = ({navigation}) => {
  const {t} = useTranslation();
  const {setUser, userId, user, userToken} = useContext(UserContext);
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme), [theme]);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState((user || {}).email && (user || {}).email.startsWith('tmp_') ? '' : (user || {}).email);
  const [firstName, setFirstName] = useState((user || {}).first_name);
  const [lastName, setLastName] = useState((user || {}).last_name);
  const [birthDateDay, setBirthDateDay] = useState((user || {}).birth_date ? user.birth_date.split('-')[2] : '');
  const [birthDateMonth, setBirthDateMonth] = useState((user || {}).birth_date ? user.birth_date.split('-')[1] : '');
  const [birthDateYear, setBirthDateYear] = useState((user || {}).birth_date ? user.birth_date.split('-')[0] : '');

  const [errors, setErrors] = useState({});

  const handlePress = async () => {
    const validationError = {};

    if (!firstName) {
      validationError.first_name = [t('NameScreen.errors.missingFirstName', 'Please enter your name.')];
    }

    if (!lastName) {
      validationError.last_name = [t('NameScreen.errors.missingLastName', 'Please enter your name.')];
    }

    if (!email) {
      validationError.email = [t('NameScreen.errors.missingEmail', 'Please enter your email address.')];
    }

    if (!birthDateDay || !birthDateMonth || !birthDateYear) {
      validationError.birth_date = [t('NameScreen.errors.missingBirthDate', 'Please enter your birth date.')];
    }

    if (Object.keys(validationError).length) {
      setErrors(validationError);
      return;
    }

    try {
      setErrors({});
      setLoading(true);

      const response = await fetch(`${Api.apiBaseUrl}/users/${userId}`, {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_token: userToken,
          first_name: firstName,
          last_name: lastName,
          email: email,
          birth_date: `${birthDateYear}-${birthDateMonth}-${birthDateDay}`,
        }),
      });

      const responseJson = await response.json();

      console.log('#######responseJson');
      console.log(responseJson);

      setLoading(false);

      if(responseJson && responseJson.email === email) {
        setUser(responseJson);
        navigation.navigate('Username', {onboarding: true});
      } else {
        setErrors(responseJson);
      }

      return responseJson;
    } catch (error) {
      console.error('error');

      if (Platform.OS === 'web') {
        Sentry.Browser.captureException(error);
      } else {
        Sentry.captureException(error);
      }
    }
  };

  return (
    <OnboardingLayout footer={<StyledButton text={t('OnboardingLayout.continue', 'Continue →')} loading={loading} onPress={handlePress}/>}>
      <Text style={styles.rowTitle}>
        {t('NameScreen.form.nameLabel', 'What\'s your name ?')}
      </Text>

      <View style={styles.formRow}>
        <View style={{flex: 1, marginRight: 8}}>
          <StyledInput
            placeholder={t('NameScreen.form.fields.firstName.placeholder', 'First name')}
            value={firstName || ''}
            autoCorrect={false}
            onChangeText={(val) => {
              setFirstName(val);
            }}
            errors={errors.first_name}
          />
        </View>

        <View style={{flex: 1}}>
          <StyledInput
            placeholder={t('NameScreen.form.fields.lastName.placeholder', 'Last name')}
            value={lastName || ''}
            autoCorrect={false}
            onChangeText={(val) => {
              setLastName(val);
            }}
            errors={errors.last_name}
          />
        </View>
      </View>

      <Text style={styles.rowTitle}>
        {t('NameScreen.form.emailLabel', 'What is your email address ?')}
      </Text>

      <View style={styles.formRow}>
        <StyledInput
          placeholder={t('NameScreen.form.fields.email.placeholder', 'Email address')}
          value={email || ''}
          keyboardType="email-address"
          returnKeyType="done"
          autoCapitalize="none"
          autoCorrect={false}
          onChangeText={(val) => {
            setEmail(val);
          }}
          errors={errors.email}
        />
      </View>

      <Text style={styles.rowTitle}>
        {t('NameScreen.form.birthDateLabel', 'When were you born ?')}
      </Text>

      <View>
        <View style={styles.formRow}>
          <View style={{flex: 1, marginRight: 8}}>
            <StyledInput
              inputStyle={(errors.birth_date || []).length ? {borderColor: theme.error} : undefined}
              placeholder={t('NameScreen.form.fields.day.placeholder', 'Day')}
              keyboardType="phone-pad"
              autoCorrect={false}
              value={birthDateDay || ''}
              onChangeText={(val) => {
                setBirthDateDay(val);
              }}
            />
          </View>

          <View style={{flex: 1, marginRight: 8}}>
            <StyledInput
              inputStyle={(errors.birth_date || []).length ? {borderColor: theme.error} : undefined}
              placeholder={t('NameScreen.form.fields.month.placeholder', 'Month')}
              keyboardType="phone-pad"
              autoCorrect={false}
              value={birthDateMonth || ''}
              onChangeText={(val) => {
                setBirthDateMonth(val);
              }}
            />
          </View>

          <View style={{flex: 1}}>
            <StyledInput
              inputStyle={(errors.birth_date || []).length ? {borderColor: theme.error} : undefined}
              placeholder={t('NameScreen.form.fields.year.placeholder', 'Year')}
              keyboardType="phone-pad"
              autoCorrect={false}
              value={birthDateYear || ''}
              onChangeText={(val) => {
                setBirthDateYear(val);
              }}
            />
          </View>
        </View>

        <View style={{width: '100%', flexDirection: 'column'}}>
          {(errors.birth_date || []).map(error => (
            <Text key={error} style={{color: theme.error}}>
              {error}
            </Text>
          ))}
        </View>
      </View>
    </OnboardingLayout>
  );
};

const getStyles = theme => StyleSheet.create({
  formRow: {
    flexDirection: 'row',
    marginBottom: 16,
  },
  rowTitle: {
    textAlign: 'center',
    color: theme.textPrimary,
    fontSize: 17,
    fontWeight: 'bold',
    marginBottom: 16,
  },
});

export default NameScreen;
