import * as React from 'react';

export const NotificationsContext = React.createContext({
  notificationsCount: null,
  setNotificationsCount: () => {},
  unreadNotificationsCount: null,
  setUnreadNotificationsCount: () => {},
  unansweredNotificationsCount: null,
  setUnansweredNotificationsCount: () => {},
  undismissdNotificationsCount: null,
  setUndismissNotificationsCount: () => {},
});
