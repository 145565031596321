import {useState, useCallback} from 'react';

import {Alert, Linking} from 'react-native';
import {Audio, InterruptionModeAndroid, InterruptionModeIOS} from 'expo-av';
import {useTranslation} from 'react-i18next';

const recordingOptions = {
  isMeteringEnabled: true,
  android: {
    extension: '.m4a',
    outputFormat: Audio.AndroidOutputFormat.AMR_WB,
    audioEncoder: Audio.AndroidAudioEncoder.AMR_WB,
    sampleRate: 16000,
    numberOfChannels: 1,
    bitRate: 16000,
  },
  ios: {
    extension: '.wav',
    // audioQuality: Audio.RECORDING_OPTION_IOS_AUDIO_QUALITY_HIGH,
    audioQuality: Audio.IOSAudioQuality.HIGH,
    sampleRate: 44100,
    numberOfChannels: 1,
    bitRate: 128000,
    linearPCMBitDepth: 16,
    linearPCMIsBigEndian: false,
    linearPCMIsFloat: false,
  },
  web: {
    mimeType: 'audio/webm',
    bitsPerSecond: 128000,
  },
};

const INITIAL_STATE = {
  status: 'initial',
  recordingFile: null,
  startingPromise: null,
};

const useRecorder = () => {
  const {t} = useTranslation();
  const [state, setState] = useState(INITIAL_STATE);

  const requestPermissions = useCallback(async beforeRequestingCallback => {
    const audioPerm = await Audio.getPermissionsAsync();
    console.log('Audio perm before request', audioPerm);

    if (audioPerm.granted === false) {
      beforeRequestingCallback();

      if (audioPerm.canAskAgain === false) {
        return Alert.alert(
          t('useRecorder.alert.title', 'Microphone permission needed'),
          t('useRecorder.alert.message', 'Microphone permission is denied, please go to settings to enable it.'),
          [
            {text: t('useRecorder.alert.buttons.cancel', 'Cancel'), style: 'cancel'},
            {text: t('useRecorder.alert.buttons.confirm', 'Open settings'), onPress: () => Linking.openSettings()},
          ]
        );
      }

      console.log('Requesting permissions..');
      return Audio.requestPermissionsAsync();
    }
  }, []);

  const start = useCallback(async () => {
    if (state.status !== 'initial') {
      return ;
    }

    const audioRecording = new Audio.Recording();

    // eslint-disable-next-line no-async-promise-executor
    const startingPromise = new Promise(async (resolve) => {
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        interruptionModeIOS: InterruptionModeIOS.MixWithOthers,
        playsInSilentModeIOS: true,
        shouldDuckAndroid: true,
        interruptionModeAndroid: InterruptionModeAndroid.DuckOthers,
        playThroughEarpieceIOS: false,
        playThroughEarpieceAndroid: false,
      });

      const beforePrep = Date.now();
      await audioRecording.prepareToRecordAsync(recordingOptions);
      console.log('prepareToRecordAsync duration', Date.now() - beforePrep);

      const beforeStart = Date.now();
      await audioRecording.startAsync();
      console.log('startAsync duration', Date.now() - beforeStart);

      console.log('Recording started: ', audioRecording);

      setState({status: 'recording', recordingFile: audioRecording, startingPromise});

      resolve(audioRecording);
    });

    setState({status: 'starting', recordingFile: audioRecording, startingPromise});

    return startingPromise;
  }, [state]);

  const stop = useCallback(async () => {
    if (state.status === 'initial') {
      return ;
    }

    await state.startingPromise;

    try {
      await state.recordingFile.stopAndUnloadAsync();

      console.log('Recording stopped');

      setState(INITIAL_STATE);

      return state.recordingFile.getURI();
    } catch (error) {
      if (error.code === 'E_AUDIO_NODATA') {
        // Note: This error happen on android when recording is stopped too quickly, in this case
        //  the recording file should be discarded.
        //  See: https://docs.expo.dev/versions/v47.0.0/sdk/audio/#stopandunloadasync

        setState(INITIAL_STATE);
      }

      // TODO: Handle error
      console.log('Error stopping the recording', error);
    }
  }, [state]);

  return {
    state,
    requestPermissions,
    start,
    stop,
  };
};

export default useRecorder;
