import React, {useCallback, useContext, useMemo, useState} from 'react';
import {Platform, StyleSheet, Text, View} from 'react-native';

import * as Location from 'expo-location';
import * as Sentry from 'sentry-expo';
import {useTranslation} from 'react-i18next';

import {ThemeContext} from '../../contexts/ThemeContext';
import {UserContext} from '../../contexts/UserContext';
import Api from '../../constants/Api';
import OnboardingLayout from './components/OnboardingLayout';
import StyledButton from '../../components/StyledButton';
import Bubble from '../MainScreen/Chat/Bubble';

const NEXT_SCREEN = 'PushNotificationPermission';

const GeolocationPermissionScreen = ({navigation}) => {
	const {t} = useTranslation();
	const {userId, userToken} = useContext(UserContext);
	const theme = useContext(ThemeContext);
	const styles = useMemo(() => getStyles(theme), [theme]);

	const [loading, setLoading] = useState(false);

	const handleNotNowButtonClick = useCallback(() => {
		navigation.navigate(NEXT_SCREEN);
	}, [navigation]);

	const handleAllowButtonClick = useCallback(async () => {
		setLoading(true);
		const {status} = await Location.requestForegroundPermissionsAsync();

		const updateUserLocation = async () => {
			try {
				const location = await Location.getCurrentPositionAsync({enableHighAccuracy: false});

				fetch(`${Api.apiBaseUrl}/users/${userId}`, {
					method: 'PATCH',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						user_token: userToken,
						latitude: location.coords.latitude,
						longitude: location.coords.longitude,
					}),
				});
			} catch (error) {
				// Note: We just ignore if this fail since it's not critical
				console.log(error);

				if (Platform.OS === 'web') {
					Sentry.Browser.captureException(error);
				} else {
					Sentry.captureException(error);
				}
			}
		};

		if (status === 'granted') {
			// Note: We don't await here to not block the user on the page while we save his position since
			//  it's not critical
			updateUserLocation();
		}

		navigation.navigate(NEXT_SCREEN);
		setLoading(false);
	}, [navigation, userId, userToken]);

	return (
		<OnboardingLayout
			footer={(
				<View style={{flexDirection: 'row'}}>
					<View style={{flex: 1, paddingRight: 6}}>
						<StyledButton
							variant="outlined"
							text={t('OnboardingLayout.notNow', 'Not now')}
							onPress={handleNotNowButtonClick}
						/>
					</View>

					<View style={{flex: 1, paddingLeft: 6}}>
						<StyledButton
							text={t('OnboardingLayout.allow', 'Allow →')}
							loading={loading}
							onPress={handleAllowButtonClick}
						/>
					</View>
				</View>
			)}
		>
			<Text style={[styles.text, {marginBottom: 14}]}>
				{t(
					'OnboardingLayout.introduction',
					'Here are some examples of the things you will be able to ask Secrétaire:'
				)}
			</Text>

			<Bubble variant="query" text={t('GeolocationPermissionScreen.examples.1', 'Where are the nearest indian restaurants ?')}/>

			<Bubble variant="query" text={t('GeolocationPermissionScreen.examples.2', 'What is the weather like ?')}/>

			<Bubble variant="query" text={t('GeolocationPermissionScreen.examples.3', 'Order me a cab home.')}/>
		</OnboardingLayout>
	);
};

const getStyles = theme => StyleSheet.create({
	text: {
		textAlign: 'center',
		color: theme.textPrimary,
		fontSize: 17,
	},
});

export default GeolocationPermissionScreen;
