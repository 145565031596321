import React, {useContext, useMemo} from 'react';
import {
  ActivityIndicator,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import {useHeaderHeight} from '@react-navigation/elements';

import {UserContext} from '../../../contexts/UserContext';
import {ThemeContext, WEB_MAX_WIDTH} from '../../../contexts/ThemeContext';

const OnboardingLayout = ({footer, children}) => {
  const {userId} = useContext(UserContext);
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme), [theme]);

  const height = useHeaderHeight();

  return (
    <SafeAreaView style={styles.safeArea}>
      <KeyboardAvoidingView
        keyboardVerticalOffset={height}
        behavior={Platform.OS === 'ios' ? 'padding' : null}
        style={{flex: 1}}
      >
        {userId ?
          <View style={styles.content}>
            <ScrollView>
              <View style={styles.container}>
                {children}
              </View>
            </ScrollView>

            <View style={styles.footer}>
              <View style={styles.footerInner}>
                {footer}
              </View>
            </View>
          </View>
          :
          <View style={{paddingHorizontal: 20}}>
            <ActivityIndicator />
          </View>
        }
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

const getStyles = theme => StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: theme.headerBackground,
  },
  content: {
    flex: 1,
    backgroundColor: theme.backgroundPrimary,
  },
  stepImage: {
    marginBottom: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  container: {
    maxWidth: WEB_MAX_WIDTH,
    alignSelf: 'center',
    flex: 1,
    paddingTop: 20,
    paddingHorizontal: 24,
  },
  footer: {
    backgroundColor: theme.footerBackground,
    borderTopWidth: 1,
    borderTopColor: theme.footerBorderColor,
    borderStyle: 'solid',
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  footerInner: {
    width: '100%',
    maxWidth: WEB_MAX_WIDTH,
    alignSelf: 'center',
  },
});

export default OnboardingLayout;
