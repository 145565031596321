import React, {useContext, useMemo, useCallback, useState, useEffect} from 'react';
import {
	Platform,
	ScrollView,
	StyleSheet,
	Text,
	View,
	SafeAreaView,
	TouchableOpacity,
	Image,
	ActivityIndicator,
} from 'react-native';
import {useTranslation} from 'react-i18next';
import Constants from 'expo-constants';
import * as Sentry from 'sentry-expo';
import {useNavigation} from '@react-navigation/native';

import {PURCHASES_STUB} from '../MainStackNavigator';
import {UserContext} from '../contexts/UserContext';
import {ThemeContext, WEB_MAX_WIDTH} from '../contexts/ThemeContext';
import StyledButton from '../components/StyledButton';
import Api from '../constants/Api';

let Purchases = PURCHASES_STUB;

if (Constants.appOwnership !== 'expo' && Platform.OS !== 'web') {
	Purchases = require('react-native-purchases').default;
}

const PurchasePackage = ({purchasePackage, selected, setSelectedPackage}) => {
	const {t} = useTranslation();
	const theme = useContext(ThemeContext);
	const {user} = useContext(UserContext);

	const {product: {priceString, subscriptionPeriod}} = purchasePackage;

	const SUBSCRIPTION_PERIOD_TO_TITLE = useMemo(() => ({
		FREE: t(
			'UpgradeScreen.titles.free',
			'Limited to {{count}} free queries per day.',
			{count: user.free_queries},
		),
		P1M: t('UpgradeScreen.titles.monthly', 'Premium Subscription - Monthly'),
		P1Y: t('UpgradeScreen.titles.yearly', 'Premium Subscription - Yearly'),
	}), [t, user.free_queries]);

	return (
		<TouchableOpacity
			style={{
				flex: 1,
				paddingVertical: 20,
				paddingHorizontal: 8,
				borderWidth: 2,
				backgroundColor: theme.inputBackgroundColor,
				borderColor: selected ? theme.success : theme.inputBorderColor,
				borderRadius: 10,
				justifyContent: 'center',
			}}
			onPress={() => setSelectedPackage(purchasePackage)}
		>
			{priceString && (
				<Text
					style={{
						textAlign: 'center',
						fontSize: 20,
						fontWeight: 600,
						color: theme.textPrimary,
						marginBottom: 8,
					}}
				>
					{priceString}
				</Text>
			)}

			<Text
				style={{
					textAlign: 'center',
					fontSize: 14,
					color: theme.textSecondary,
				}}
			>
				{SUBSCRIPTION_PERIOD_TO_TITLE[subscriptionPeriod]}
			</Text>
		</TouchableOpacity>
	);
};

const UpgradeScreen = ({onboarding}) => {
	const {t} = useTranslation();
	const {userToken, userId, user, setUser, setSubscribed} = useContext(UserContext);
	const theme = useContext(ThemeContext);
	const styles = useMemo(() => getStyles(theme), [theme]);
	const {navigate} = useNavigation();

	const [loading, setLoading] = useState(true);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [packages, setPackages] = useState([]);

	const FREE_PACKAGE = useMemo(() => ({
		identifier: 'free',
		product: {
			priceString: t('UpgradeScreen.freePackage.priceString', 'Free'),
			subscriptionPeriod: 'FREE',
		},
	}), [t]);

	const [selectedPackage, setSelectedPackage] = useState(!onboarding ? FREE_PACKAGE : null);

	console.log('Purchases', Purchases);
	console.log('Purchases.getOfferings', Purchases.getOfferings);

	useEffect(() => {
		const getOfferings = async () => {
			try {
				console.log('Purchases.getOfferings()');
				const offerings = await Purchases.getOfferings();
				console.log('offerings', JSON.stringify(offerings));

				if (offerings.current !== null && offerings.current.availablePackages.length !== 0) {
					const packages = [FREE_PACKAGE, ...offerings.current.availablePackages];

					setPackages(packages);
				}
			} catch (error) {
				// TODO: Handle errors
				console.log('getOffering error', error);

				setErrorMessage(t('ContactsPermissionScreen.errors.request', 'Something went wrong, try again later.'));

				if (Platform.OS === 'web') {
					Sentry.Browser.captureException(error);
				} else {
					Sentry.captureException(error);
				}
			} finally {
				// TODO: Handle error
				setLoading(false);
			}
		};

		console.log('getOfferings() called');
		getOfferings();
	}, [FREE_PACKAGE, t, user.free_queries]);

	const updateUserHasCompletedOnboarding = useCallback(async () => {
		try {
			setConfirmLoading(true);
			setErrorMessage(null);

			const result = await fetch(`${Api.apiBaseUrl}/users/${userId}`, {
				method: 'PATCH',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					user_token: userToken,
					has_completed_onboarding: true,
				}),
			});

			const updatedUser = await result.json();

			console.log('### updated user', updatedUser);

			setUser(updatedUser);
			setConfirmLoading(false);
		} catch (error) {
			setErrorMessage(t('ContactsPermissionScreen.errors.request', 'Something went wrong, try again later.'));

			if (Platform.OS === 'web') {
				Sentry.Browser.captureException(error);
			} else {
				Sentry.captureException(error);
			}
		} finally {
			setConfirmLoading(false);
		}
	}, [userId, userToken, setUser, t]);

	const buy = useCallback(async purchasePackage => {
		try {
			setConfirmLoading(true);
			const purchaseMade = await Purchases.purchasePackage(purchasePackage);

			if (typeof purchaseMade.customerInfo.entitlements.active['premium'] !== 'undefined') {
				setSubscribed(true);

				if (onboarding) {
					await updateUserHasCompletedOnboarding();
				} else {
					navigate('MainStack');
				}
			}
		} catch (error) {
			if (!error.userCancelled) {
				// TODO: Handle error
				setErrorMessage(t('ContactsPermissionScreen.errors.request', 'Something went wrong, try again later.'));

				if (Platform.OS === 'web') {
					Sentry.Browser.captureException(error);
				} else {
					Sentry.captureException(error);
				}
			}
		} finally {
			setConfirmLoading(false);
		}
	}, [navigate, onboarding, setSubscribed, t, updateUserHasCompletedOnboarding]);

	return (
		<SafeAreaView
			style={{
				flex: 1,
				width: '100%',
				backgroundColor: theme.backgroundPrimary,
			}}
		>
			{loading
				? (
					<View style={{flex: 1, justifyContent: 'center'}}>
						<ActivityIndicator size="large"/>
					</View>
				)
				: (
					<ScrollView contentContainerStyle={styles.mainContainer}>
						<View style={{flex: 1, justifyContent: 'space-between'}}>
							<View style={{gap: 16, alignItems: 'center'}}>
								<Image
									source={require('../assets/icon.png')}
									style={styles.logo}
								/>

								<Text
									style={{
										textAlign: 'center',
										fontSize: 24,
										color: theme.textPrimary,
									}}
								>
									{t('UpgradeScreen.title', 'Premium access')}
								</Text>

								<Text
									style={{
										textAlign: 'center',
										fontSize: 16,
										color: theme.textSecondary,
										maxWidth: 300,
									}}
								>
									{t('UpgradeScreen.subtitle', 'Get access to an even more powerful assistant and unlimited queries.')}
								</Text>

								<View
									style={{
										display: 'flex',
										flexDirection: 'row',
										gap: 10,
									}}
								>
									{packages.map(purchasePackage => (
										<PurchasePackage
											key={purchasePackage.identifier}
											purchasePackage={purchasePackage}
											selected={selectedPackage && (purchasePackage.identifier === selectedPackage.identifier)}
											setSelectedPackage={setSelectedPackage}
										/>
									))}
								</View>

								<Text
									style={{
										textAlign: 'center',
										fontSize: 16,
										color: theme.textSecondary,
									}}
								>
									{t('UpgradeScreen.cancel', 'Cancel anytime')}
								</Text>

								{/*<View style={{gap: 8}}>*/}
								{/*	<Text*/}
								{/*		style={{*/}
								{/*			color: theme.textSecondary,*/}
								{/*		}}*/}
								{/*	>*/}
								{/*		By unlocking premium access you get access to:*/}
								{/*	</Text>*/}

								{/*	<Text*/}
								{/*		style={{*/}
								{/*			color: theme.textSecondary,*/}
								{/*		}}*/}
								{/*	>*/}
								{/*		• <Text style={{fontWeight: 600}}>Unlimited queries</Text>, have as many request as you'd like, anytime !*/}
								{/*	</Text>*/}

								{/*	<Text*/}
								{/*		style={{*/}
								{/*			color: theme.textSecondary,*/}
								{/*		}}*/}
								{/*	>*/}
								{/*		• <Text style={{fontWeight: 600}}>GPT-4 AI model</Text>, an even more powerful model that takes your personnal assistant to the next level.*/}
								{/*	</Text>*/}
								{/*</View>*/}
							</View>

							<View>
								{errorMessage && (
									<Text style={{textAlign: 'center', color: 'red', marginBottom: 10, fontSize: 17}}>
										{errorMessage}
									</Text>
								)}

								<StyledButton
									style={{
										opacity: !selectedPackage || (!onboarding && selectedPackage.identifier === 'free') ? 0.5 : 1,
										marginBottom: 12,
										backgroundColor: theme.success,
									}}
									loading={confirmLoading}
									text={t('UpgradeScreen.buttons.continue', 'Continue')}
									onPress={selectedPackage
										? selectedPackage.identifier === 'free'
											? updateUserHasCompletedOnboarding
											: () => buy(selectedPackage)
										: () => {}
									}
									disabled={!selectedPackage || (!onboarding && selectedPackage.identifier === 'free')}
								/>
							</View>
						</View>
					</ScrollView>
				)
			}
		</SafeAreaView>
	);
};

const getStyles = () => StyleSheet.create({
	mainContainer: {
		alignSelf: 'center',
		maxWidth: WEB_MAX_WIDTH,
		width: '100%',
		height: '100%',
		flexDirection: 'column',
		paddingHorizontal: 12,
		paddingBottom: 8,
	},

	logo: {
		width: 80,
		height: 80,
		borderRadius: 40,
		marginTop: 20,
		marginBottom: 10,
		resizeMode: 'contain',
	},
});

export default UpgradeScreen;
