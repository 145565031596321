import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {
	ActivityIndicator,
	Alert,
	Dimensions,
	Platform,
	SafeAreaView,
	ScrollView,
	StyleSheet,
	Text,
	TouchableOpacity,
	TouchableWithoutFeedback,
	View,
} from 'react-native';
import {EvilIcons, FontAwesome, Ionicons, MaterialIcons, AntDesign} from '@expo/vector-icons';
import {useTranslation} from 'react-i18next';
import {useNavigation} from '@react-navigation/native';
import * as Sentry from 'sentry-expo';

import Api from '../../constants/Api';
import {ThemeContext} from '../../contexts/ThemeContext';
import {UserContext} from '../../contexts/UserContext';
import {SettingsContext} from '../../contexts/SettingsContext';
import StyledButton from '../../components/StyledButton';
import SearchModal from '../../components/SearchModal';
import StyledInput from '../../components/StyledInput';
import languageList from '../../assets/languages-list';

const DRAWER_MAX_WIDTH = 300;

const TextButton = ({text, onPress, selected = false, icon = null}) => {
	const theme = useContext(ThemeContext);

	return (
		<TouchableOpacity onPress={onPress} style={{flexDirection: 'row', alignItems: 'center', paddingVertical: 8}}>
			<View style={{width: 24, alignItems: 'center', justifyContent: 'center'}}>
				{icon}
			</View>

			<Text
				style={{
					flex:1,
					fontSize: 16,
					color: selected ? theme.colorPrimary : theme.textPrimary,
					marginLeft: icon ? 8 : 0,
				}}
				numberOfLines={1}
			>
				{text}
			</Text>
		</TouchableOpacity>
	);
};

const EDIT_DISCUSSION_INITIAL_STATE = {
	id: null,
	name: '',
};

const Drawer = ({
	drawerOpen,
	closeDrawer,
	setChatMode,
	discussions,
	setDiscussions,
	discussionsLoading,
	discussionId,
	updateDiscussionId,
	fetchDiscussions,
	setDiscussionsLoading,
}) => {
	const {t} = useTranslation();
	const theme = useContext(ThemeContext);
	const styles = useMemo(() => getStyles(theme), [theme]);
	const {userToken, user, subscribed} = useContext(UserContext);
	const {settings, updateSettings} = useContext(SettingsContext);
	const {navigate} = useNavigation();

	const [createDiscussionLoading, setCreateDiscussionLoading] = useState(false);
	const [clearDiscussionLoading, setClearDiscussionLoading] = useState(false);
	const [editDiscussionState, setEditDiscussionState] = useState(EDIT_DISCUSSION_INITIAL_STATE);
	const [languageModalOpen, setLanguageModalOpen] = useState(false);

	useEffect(() => {
		setEditDiscussionState(EDIT_DISCUSSION_INITIAL_STATE);
	}, [drawerOpen]);

	const createDiscussion = useCallback(async () => {
		setCreateDiscussionLoading(true);

		const response = await fetch(`${Api.apiBaseUrl}/discussions/new.json?user_token=${userToken}`, {method: 'GET'});
		const data = await response.json();

		setCreateDiscussionLoading(false);

		if (data.error) {
			// TODO: handle error
			console.log(data.error);
			return;
		}

		setDiscussions([data, ...discussions]);
		updateDiscussionId(data.id);
		closeDrawer();
	}, [userToken, closeDrawer, updateDiscussionId, setDiscussions, discussions]);

	const clearDiscussion = useCallback(async clearDiscussionId => {
		try {
			setClearDiscussionLoading(true);

			// TODO: Handle response and error
			const response = await fetch(`${Api.apiBaseUrl}/discussions/${clearDiscussionId}/clear?user_token=${userToken}`, {method: 'DELETE'});
			console.log(response);

			// TODO: handle error

			const updatedDiscussions = await fetchDiscussions();

			if (clearDiscussionId === discussionId) {
				updateDiscussionId(updatedDiscussions[0] ? updatedDiscussions[0].id : null);
			}

			setClearDiscussionLoading(false);
		} catch (error) {
			// TODO: handle error
			if (Platform.OS === 'web') {
				Sentry.Browser.captureException(error);
			} else {
				Sentry.captureException(error);
			}
		}
	}, [userToken, setClearDiscussionLoading, fetchDiscussions, discussionId, updateDiscussionId]);

	const onClearClick = useCallback(clearDiscussionId => () => {
		if (Platform.OS === 'web') {
			if (window.confirm(t('MainScreen.Drawer.alerts.clearDiscussion.message', 'Are you sure you want to clear this discussion ?'))) {
				clearDiscussion(clearDiscussionId);
			}
		} else {
			Alert.alert(
				t('MainScreen.Drawer.alerts.clearDiscussion.title', 'Clear discussion'),
				t('MainScreen.Drawer.alerts.clearDiscussion.message', 'Are you sure you want to clear this discussion ?'),
				[
					{
						text: t('MainScreen.Drawer.alerts.buttons.cancel', 'Cancel'),
						style: 'cancel',
					},
					{
						text: t('MainScreen.Drawer.alerts.buttons.confirm', 'Confirm'),
						onPress: () => clearDiscussion(clearDiscussionId),
					},
				]
			);
		}
	}, [clearDiscussion]);

	const onEditClick = useCallback(discussion => () => {
		setEditDiscussionState({id: discussion.id, name: discussion.name});
	}, [setEditDiscussionState]);

	const onEditDiscussionTitle = useCallback(value => {
		setEditDiscussionState({...editDiscussionState, name: value});
	}, [setEditDiscussionState, editDiscussionState]);

	const onValidateEdit = useCallback(async () => {
		try {
			setDiscussionsLoading(true);

			const result = await fetch(`${Api.apiBaseUrl}/discussions/${editDiscussionState.id}.json`, {
				method: 'PATCH',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					user_token: userToken,
					name: editDiscussionState.name,
				}),
			});

			const updatedDiscussion = await result.json();
			console.log(updatedDiscussion);

			setEditDiscussionState(EDIT_DISCUSSION_INITIAL_STATE);
			fetchDiscussions();
		} catch (error) {
			setDiscussionsLoading(false);
			// TODO: Handle error
			if (Platform.OS === 'web') {
				Sentry.Browser.captureException(error);
			} else {
				Sentry.captureException(error);
			}
		}
	}, [userToken, setEditDiscussionState, editDiscussionState, fetchDiscussions]);

	const clearDiscussions = useCallback(async () => {
		setClearDiscussionLoading(true);

		// TODO: Handle response and error
		const response = await fetch(`${Api.apiBaseUrl}/discussions/clear?user_token=${userToken}`, {method: 'DELETE'});
		console.log(response);
		// const data = await response.json();
		// console.log(data);

		setClearDiscussionLoading(false);

		// if (data.error) {
		// 	// TODO: handle error
		// 	console.log(data.error);
		// 	return;
		// }

		setDiscussions([]);
		updateDiscussionId(null);
	}, [userToken, updateDiscussionId, setDiscussions, discussions]);

	const toggleTheme = useCallback(() => {
		updateSettings({...settings, theme: settings.theme === 'light' ? 'dark' : 'light'});
	}, [updateSettings, settings]);

	const currentLanguage = useMemo(() => {
		// Note: Special case to make default en: en-US
		if (settings.language === 'en') {
			return languageList.find(language => language.code === 'en-US');
		}

		return languageList.find(language => settings.language.length === 2
			? settings.language === language.code.slice(0, 2)
			: settings.language === language.code
		) || languageList.find(language => language.code === 'en-US');
	}, [settings]);

	const openLanguageModal = useCallback(() => {
		setLanguageModalOpen(true);
	}, [setLanguageModalOpen]);

	const closeLanguageModal = useCallback(() => {
		setLanguageModalOpen(false);
	}, [setLanguageModalOpen]);

	const handleChangeLanguage = useCallback(newLanguage => {
		updateSettings({...settings, language: newLanguage.code});
		closeDrawer();
	}, [updateSettings, settings]);

	const handleClearDiscussion = useCallback(() => {
		if (Platform.OS === 'web') {
			if (window.confirm(t('MainScreen.Drawer.alerts.clearDiscussions.message', 'Are you sure you want to clear discussions ?'))) {
				clearDiscussions();
			}
		} else {
			Alert.alert(
				t('MainScreen.Drawer.alerts.clearDiscussions.title', 'Clear discussions'),
				t('MainScreen.Drawer.alerts.clearDiscussions.message', 'Are you sure you want to clear discussions ?'),
				[
					{
						text: t('MainScreen.Drawer.alerts.buttons.cancel', 'Cancel'),
						style: 'cancel',
					},
					{
						text: t('MainScreen.Drawer.alerts.buttons.confirm', 'Confirm'),
						onPress: clearDiscussions,
					},
				]
			);
		}
	}, [clearDiscussions]);

	const handleSettingsClick = useCallback(() => {
		navigate('Account');
		closeDrawer();
	}, [closeDrawer, navigate]);

	if (!drawerOpen) {
		return null;
	}

	return (
		<SafeAreaView
			style={[styles.drawerContainer, Dimensions.get('window').width < 1300
				? {width: '100%'}
				: {width: DRAWER_MAX_WIDTH},
			]}
		>
			<View
				style={[styles.drawer, Dimensions.get('window').width < 1300
					? {width: '70%'}
					: {width: DRAWER_MAX_WIDTH},
				]}
			>
				<View style={{flex: 1}}>
					<StyledButton
						style={{marginBottom: 12}}
						text={t('MainScreen.Drawer.newDiscussion', '+ New discussion')}
						onPress={createDiscussion}
						loading={createDiscussionLoading}
						variant="outlinedSecondary"
					/>

					{discussionsLoading || clearDiscussionLoading
						? (
							<View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
								<ActivityIndicator size="large"/>
							</View>
						)
						: (
							<ScrollView>
								{discussions.map(discussion => (
									<View key={discussion.id} style={{flexDirection: 'row'}}>
										<View style={{flex: 1}}>
											{discussion.id === editDiscussionState.id
												? (
													<StyledInput
														value={editDiscussionState.name}
														onChangeText={onEditDiscussionTitle}
													/>
												)
												: (
													<TextButton
														text={discussion.name}
														onPress={() => {
															closeDrawer();
															setChatMode(true);
															updateDiscussionId(discussion.id);
														}}
														selected={discussionId === discussion.id}
														icon={<Ionicons name="chatbox-outline" size={20} color={theme.textSecondary}/>}
													/>
												)}
										</View>

										<TouchableOpacity
											style={{paddingVertical: 8, marginRight: 8}}
											onPress={discussion.id === editDiscussionState.id
												? onValidateEdit
												: onEditClick(discussion)
											}
										>
											<View style={{width: 24, alignItems: 'center', justifyContent: 'center'}}>
												{discussion.id === editDiscussionState.id
													? <FontAwesome style={{marginLeft: 6}} name="check" size={20} color={theme.textSecondary}/>
													: <EvilIcons name="pencil" size={28} color={theme.textSecondary}/>}
											</View>
										</TouchableOpacity>

										{discussion.id !== editDiscussionState.id && (
											<TouchableOpacity
												style={{paddingVertical: 10}}
												onPress={onClearClick(discussion.id)}
											>
												<View style={{width: 24, alignItems: 'center', justifyContent: 'center'}}>
													<AntDesign name="delete" size={18} color={theme.textSecondary}/>
												</View>
											</TouchableOpacity>
										)}
									</View>
								))}
							</ScrollView>
						)}
				</View>

				<View style={styles.bottomContainer}>
					{user
					&& (user.daily_queries !== undefined && user.free_queries !== undefined)
					&& (user.status === 'guest' || subscribed === false) && (
						<StyledButton
							style={{backgroundColor: theme.success}}
							text={user.status === 'guest'
								? t('UpgradeMenu.buttons.createAccount', 'Create my free account')
								: t('UpgradeMenu.buttons.upgrade', 'Upgrade account')
							}
							onPress={() => user.status === 'guest'
								? navigate('Signup')
								: navigate('Upgrade')
							}
						/>
					)}

					<TextButton
						text={t('MainScreen.Drawer.clearDiscussions', 'Clear discussions')}
						onPress={handleClearDiscussion}
						icon={<MaterialIcons name="delete" size={24} color={theme.textSecondary}/>}
					/>

					<TextButton
						text={settings.theme === 'light'
							? t('MainScreen.Drawer.switchToDarkMode', 'Switch to dark mode')
							: t('MainScreen.Drawer.switchToLightMode', 'Switch to light mode')
						}
						onPress={toggleTheme}
						icon={(
							<Ionicons
								name={settings.theme === 'light' ? 'moon' : 'sunny'}
								size={settings.theme === 'light' ? 20 : 22}
								color={theme.textSecondary}
							/>
						)}
					/>

					<TextButton
						text={t('MainScreen.Drawer.account', 'My account')}
						onPress={handleSettingsClick}
						icon={<Ionicons name="person-circle-outline" size={24} color={theme.textSecondary}/>}
					/>

					<TextButton
						text={currentLanguage.name}
						onPress={openLanguageModal}
						icon={<Ionicons>{currentLanguage.flag}</Ionicons>}
					/>

					<SearchModal
						data={languageList}
						open={languageModalOpen}
						close={closeLanguageModal}
						onClick={handleChangeLanguage}
					/>
				</View>
			</View>

			{Dimensions.get('window').width < 1300 && (
				<TouchableWithoutFeedback onPress={closeDrawer}>
					<View style={styles.closeArea}/>
				</TouchableWithoutFeedback>
			)}
		</SafeAreaView>
	);
};

const getStyles = theme => StyleSheet.create({
	drawerContainer: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		flexDirection: 'row',

		zIndex: 10,
		elevation: 10,
	},
	drawer: {
		backgroundColor: theme.headerBackground,
		borderStyle: 'solid',
		borderRightWidth: 1,
		borderRightColor: theme.headerBorderColor,
		width: '70%',
		maxWidth: DRAWER_MAX_WIDTH,
		height: '100%',
		padding: 12,
	},
	bottomContainer: {
		paddingTop: 12,
		borderStyle: 'solid',
		borderTopWidth: 1,
		borderTopColor: theme.inputBorderColor,
	},
	closeArea: {
		flex: 1,
		height: '100%',
	},
});

export default Drawer;
