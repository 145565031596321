import * as React from 'react';

export const UserContext = React.createContext({
  isLoggedIn: false,
  user: null,
  userId: null,
  userToken: null,
  deviceToken: null,
  setUserId: () => {},
  setUserToken: () => {},
  setDeviceToken: () => {},
  setUser: () => {},
  signOut: () => {},
});
