import React, {useState, useContext, useMemo} from 'react';
import {Platform, StyleSheet, Text, View} from 'react-native';
import {useTranslation} from 'react-i18next';
import * as Sentry from 'sentry-expo';

import {ThemeContext} from '../../contexts/ThemeContext';
import {UserContext} from '../../contexts/UserContext';
import Api from '../../constants/Api';
import OnboardingLayout from './components/OnboardingLayout';
import StyledInput from '../../components/StyledInput';
import StyledButton from '../../components/StyledButton';

const UsernameScreen = ({navigation}) => {
  const {t} = useTranslation();
  const {setUser, user, userId, userToken} = useContext(UserContext);
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme), [theme]);

  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState((user || {}).username && (user || {}).username.startsWith('tmp_') ? '' : (user || {}).username);
  const [errorMessage, setErrorMessage] = useState(null);

  const handlePress = async () => {
    if (!username) {
      setErrorMessage(t('UsernameScreen.errors.missingUsername', 'Please choose a username.'));
      return;
    }

    try {
      setLoading(true);

      const response = await fetch(`${Api.apiBaseUrl}/users/${userId}`, {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'user_token': userToken,
          'username': username,
        }),
      });

      const responseJson = await response.json();
      console.log('#######responseJson');
      console.log(responseJson);

      setLoading(false);

      if(responseJson && responseJson.username === username) {
        setUser(responseJson);
        navigation.navigate('GeolocationPermission', {onboarding: true});
      } else {
        setErrorMessage(responseJson.username);
      }

      return responseJson;
    } catch (error) {
      console.error('Error during username selection.');
      console.error(error);
      setErrorMessage(t('UsernameScreen.errors.request', 'Error during username selection.'));

      if (Platform.OS === 'web') {
        Sentry.Browser.captureException(error);
      } else {
        Sentry.captureException(error);
      }
    }
  };

  return (
    <OnboardingLayout footer={<StyledButton text={t('OnboardingLayout.continue', 'Continue →')} loading={loading} onPress={handlePress}/>}>
      {errorMessage
        ? (
          <Text style={{textAlign: 'center', color: 'red', marginBottom: 10, fontSize: 17}}>
            {errorMessage}
          </Text>
        ) : null
      }

      <Text style={[styles.text, styles.bold]}>
        {t('UsernameScreen.form.usernameLabel', 'What username do you want ?')}
      </Text>

      <View style={{paddingBottom: 20}}>
        <StyledInput
          placeholder={t('UsernameScreen.form.fields.username.placeholder', 'Username')}
          value={username}
          autoCapitalize="none"
          autoCorrect={false}
          onChangeText={(val) => {
            setUsername(val);
          }}
        />
      </View>

      <Text style={styles.text}>
        {t('UsernameScreen.form.emailLabel', 'Your email address will be :')}
      </Text>

      <Text style={[styles.text, styles.bold]}>
        {username && username.length > 0 ? username + '@secretaire.ai' : '<username>@secretaire.ai'}
      </Text>
    </OnboardingLayout>
  );
};

const getStyles = theme => StyleSheet.create({
  text: {
    textAlign: 'center',
    color: theme.textPrimary,
    fontSize: 17,
    marginBottom: 16,
  },
  bold: {
    fontWeight: 'bold',
  },
});

export default UsernameScreen;
