import {registerRootComponent} from 'expo';
import * as Sentry from 'sentry-expo';
import 'intl-pluralrules';

import './i18n';
import App from './App';

Sentry.init({
	dsn: 'https://59488a0eb7d54984a03338b886218de8@o4505266437816320.ingest.sentry.io/4505267738116096',
	enableInExpoDevelopment: false,
	debug: false,
	sampleRate: 0.2,
});

registerRootComponent(App);
