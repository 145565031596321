import React, {useCallback, useContext, useState} from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {useTranslation} from 'react-i18next';
import {createStackNavigator} from '@react-navigation/stack';
import {Ionicons} from '@expo/vector-icons';

import MainScreen from './screens/MainScreen/MainScreen';
import SignupScreen from './screens/onboarding/SignupScreen/SignupScreen';

import Api from './constants/Api';
import {SettingsContext} from './contexts/SettingsContext';
import {SpeakerContext} from './contexts/SpeakerContext';
import {ThemeContext} from './contexts/ThemeContext';
import {UserContext} from './contexts/UserContext';
import useSpeaker from './hooks/useSpeaker';
import AccountScreen from './screens/AccountScreen';
import UpgradeScreen from './screens/UpgradeScreen';
import UpgradeMenu from './UpgradeMenu';

const MainStack = createStackNavigator();

const MainStackNavigator = ({drawerOpen, toggleDrawer, closeDrawer}) => {
  const {t} = useTranslation();
  const {user, subscribed} = useContext(UserContext);
  const theme = useContext(ThemeContext);
  const {settings, updateSettings} = useContext(SettingsContext);
  const {speaking} = useContext(SpeakerContext);
  const speaker = useSpeaker();

  const [chatMode, setChatMode] = useState(true);

  const toggleMuted = useCallback(() => updateSettings({...settings, muted: !settings.muted}), [settings, updateSettings]);

  return (
    <MainStack.Navigator
      screenOptions={{
        headerTitleAlign: 'center',
        headerStyle: {
          backgroundColor: theme.headerBackground,
        },
        headerBackgroundContainerStyle: {
          borderColor: theme.footerBorderColor,
          borderBottomWidth: 2,
          borderStyle: 'solid',
        },
        headerTitleStyle: {
          color: theme.textPrimary,
        },
        headerTintColor: theme.textSecondary,
        headerShadowVisible: false,
        cardStyle: {flex: 1},
      }}
    >
      <MainStack.Screen
        name="MainStack"
        options={{
          title: Api.appName === 'ChaTTTTT' ? 'ChaTTTTT' : 'Secrétaire',
          headerLeft: () => (
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <TouchableOpacity onPress={toggleDrawer} style={{padding: 10, marginLeft: 7}}>
                <Ionicons name="menu" size={28} color={theme.textSecondary}/>
              </TouchableOpacity>
            </View>
          ),
          headerRight: () => (
            /*
            <TouchableOpacity
              onPress={() => setChatMode(!chatMode)}
              style={{padding: 10, marginRight: 5}}
            >
              <FontAwesome name="refresh" size={20} color={theme.textSecondary} />
            </TouchableOpacity>
            */
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              {user
                && (user.daily_queries !== undefined && user.free_queries !== undefined)
                && (user.status === 'guest' || subscribed === false) && (
                <UpgradeMenu/>
              )}

              <TouchableOpacity
                onPress={speaking ? speaker.stop : toggleMuted}
                style={{padding: 10, marginRight: 9}}
              >
                <Ionicons name={settings.muted ? 'volume-mute' : 'volume-high'} size={24} color={speaking ? theme.colorPrimary : theme.textSecondary}/>
              </TouchableOpacity>
            </View>
          ),
        }}
      >
        {() => <MainScreen chatMode={chatMode} setChatMode={setChatMode} drawerOpen={drawerOpen} closeDrawer={closeDrawer}/>}
      </MainStack.Screen>

      <MainStack.Screen
        name="Signup"
        component={SignupScreen}
        options={{
          title: t('SignupScreen.createAccountFromGuest.title', 'Create my account'),
          headerBackTitleVisible: false,
        }}
      />

      <MainStack.Screen
        name="Account"
        component={AccountScreen}
        options={{
          title: t('MainScreen.Drawer.account', 'My account'),
          headerBackTitleVisible: false,
        }}
      />

      <MainStack.Screen
        name="Upgrade"
        component={UpgradeScreen}
        options={{
          title: t('UpgradeScreen.headerTitle', 'Upgrade your account'),
          headerBackTitleVisible: false,
        }}
      />
    </MainStack.Navigator>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
});

export default MainStackNavigator;
