const languageList = [
  {
    "name": "Afrikaans (South Africa)",
    "code": "af-ZA",
    "flag": "🇿🇦",
  },
  {
    "name": "Albanian (Albania)",
    "code": "sq-AL",
    "flag": "🇦🇱",
  },
  {
    "name": "Amharic (Ethiopia)",
    "code": "am-ET",
    "flag": "🇪🇹",
  },
  {
    "name": "Arabic (Algeria)",
    "code": "ar-DZ",
    "flag": "🇩🇿",
  },
  {
    "name": "Arabic (Bahrain)",
    "code": "ar-BH",
    "flag": "🇧🇭",
  },
  {
    "name": "Arabic (Egypt)",
    "code": "ar-EG",
    "flag": "🇪🇬",
  },
  {
    "name": "Arabic (Iraq)",
    "code": "ar-IQ",
    "flag": "🇮🇶",
  },
  {
    "name": "Arabic (Israel)",
    "code": "ar-IL",
    "flag": "🇮🇱",
  },
  {
    "name": "Arabic (Jordan)",
    "code": "ar-JO",
    "flag": "🇯🇴",
  },
  {
    "name": "Arabic (Kuwait)",
    "code": "ar-KW",
    "flag": "🇰🇼",
  },
  {
    "name": "Arabic (Lebanon)",
    "code": "ar-LB",
    "flag": "🇱🇧",
  },
  {
    "name": "Arabic (Mauritania)",
    "code": "ar-MR",
    "flag": "🇲🇷",
  },
  {
    "name": "Arabic (Morocco)",
    "code": "ar-MA",
    "flag": "🇲🇦",
  },
  {
    "name": "Arabic (Oman)",
    "code": "ar-OM",
    "flag": "🇴🇲",
  },
  {
    "name": "Arabic (Qatar)",
    "code": "ar-QA",
    "flag": "🇶🇦",
  },
  {
    "name": "Arabic (Saudi Arabia)",
    "code": "ar-SA",
    "flag": "🇸🇦",
  },
  {
    "name": "Arabic (State of Palestine)",
    "code": "ar-PS",
    "flag": "🇵🇸",
  },
  {
    "name": "Arabic (Tunisia)",
    "code": "ar-TN",
    "flag": "🇹🇳",
  },
  {
    "name": "Arabic (United Arab Emirates)",
    "code": "ar-AE",
    "flag": "🇦🇪",
  },
  {
    "name": "Arabic (Yemen)",
    "code": "ar-YE",
    "flag": "🇾🇪",
  },
  {
    "name": "Armenian (Armenia)",
    "code": "hy-AM",
    "flag": "🇦🇲",
  },
  {
    "name": "Azerbaijani (Azerbaijan)",
    "code": "az-AZ",
    "flag": "🇦🇿",
  },
  {
    "name": "Basque (Spain)",
    "code": "eu-ES",
    "flag": "🇪🇸",
  },
  {
    "name": "Bengali (Bangladesh)",
    "code": "bn-BD",
    "flag": "🇧🇩",
  },
  {
    "name": "Bengali (India)",
    "code": "bn-IN",
    "flag": "🇮🇳",
  },
  {
    "name": "Bosnian (Bosnia and Herzegovina)",
    "code": "bs-BA",
    "flag": "🇧🇦",
  },
  {
    "name": "Bulgarian (Bulgaria)",
    "code": "bg-BG",
    "flag": "🇧🇬",
  },
  {
    "name": "Burmese (Myanmar)",
    "code": "my-MM",
    "flag": "🇲🇲",
  },
  {
    "name": "Catalan (Spain)",
    "code": "ca-ES",
    "flag": "🇪🇸",
  },
  {
    "name": "Chinese Cantonese (Traditional, Hong Kong)",
    "code": "zh-HK",
    "flag": "🇭🇰",
  },
  {
    "name": "Chinese Mandarin (Simplified, China)",
    "code": "zh-CN",
    "flag": "🇨🇳",
  },
  {
    "name": "Chinese Mandarin (Traditional, Taiwan)",
    "code": "zh-TW",
    "flag": "🇹🇼",
  },
  {
    "name": "Croatian (Croatia)",
    "code": "hr-HR",
    "flag": "🇭🇷",
  },
  {
    "name": "Czech (Czech Republic)",
    "code": "cs-CZ",
    "flag": "🇨🇿",
  },
  {
    "name": "Danish (Denmark)",
    "code": "da-DK",
    "flag": "🇩🇰",
  },
  {
    "name": "Dutch (Belgium)",
    "code": "nl-BE",
    "flag": "🇧🇪",
  },
  {
    "name": "Dutch (Netherlands)",
    "code": "nl-NL",
    "flag": "🇳🇱",
  },
  {
    "name": "English (Australia)",
    "code": "en-AU",
    "flag": "🇦🇺",
  },
  {
    "name": "English (Canada)",
    "code": "en-CA",
    "flag": "🇨🇦",
  },
  {
    "name": "English (Ghana)",
    "code": "en-GH",
    "flag": "🇬🇭",
  },
  {
    "name": "English (Hong Kong)",
    "code": "en-HK",
    "flag": "🇭🇰",
  },
  {
    "name": "English (India)",
    "code": "en-IN",
    "flag": "🇮🇳",
  },
  {
    "name": "English (Ireland)",
    "code": "en-IE",
    "flag": "🇮🇪",
  },
  {
    "name": "English (Kenya)",
    "code": "en-KE",
    "flag": "🇰🇪",
  },
  {
    "name": "English (New Zealand)",
    "code": "en-NZ",
    "flag": "🇳🇿",
  },
  {
    "name": "English (Nigeria)",
    "code": "en-NG",
    "flag": "🇳🇬",
  },
  {
    "name": "English (Pakistan)",
    "code": "en-PK",
    "flag": "🇵🇰",
  },
  {
    "name": "English (Philippines)",
    "code": "en-PH",
    "flag": "🇵🇭",
  },
  {
    "name": "English (Singapore)",
    "code": "en-SG",
    "flag": "🇸🇬",
  },
  {
    "name": "English (South Africa)",
    "code": "en-ZA",
    "flag": "🇿🇦",
  },
  {
    "name": "English (Tanzania)",
    "code": "en-TZ",
    "flag": "🇹🇿",
  },
  {
    "name": "English (United Kingdom)",
    "code": "en-GB",
    "flag": "🇬🇧",
  },
  {
    "name": "English (United States)",
    "code": "en-US",
    "flag": "🇺🇸",
  },
  {
    "name": "Estonian (Estonia)",
    "code": "et-EE",
    "flag": "🇪🇪",
  },
  {
    "name": "Filipino (Philippines)",
    "code": "fil-PH",
    "flag": "🇵🇭",
  },
  {
    "name": "Finnish (Finland)",
    "code": "fi-FI",
    "flag": "🇫🇮",
  },
  {
    "name": "French (Belgium)",
    "code": "fr-BE",
    "flag": "🇧🇪",
  },
  {
    "name": "French (Canada)",
    "code": "fr-CA",
    "flag": "🇨🇦",
  },
  {
    "name": "French (France)",
    "code": "fr-FR",
    "flag": "🇫🇷",
  },
  {
    "name": "French (Switzerland)",
    "code": "fr-CH",
    "flag": "🇨🇭",
  },
  {
    "name": "Galician (Spain)",
    "code": "gl-ES",
    "flag": "🇪🇸",
  },
  {
    "name": "Georgian (Georgia)",
    "code": "ka-GE",
    "flag": "🇬🇪",
  },
  {
    "name": "German (Austria)",
    "code": "de-AT",
    "flag": "🇦🇹",
  },
  {
    "name": "German (Germany)",
    "code": "de-DE",
    "flag": "🇩🇪",
  },
  {
    "name": "German (Switzerland)",
    "code": "de-CH",
    "flag": "🇨🇭",
  },
  {
    "name": "Greek (Greece)",
    "code": "el-GR",
    "flag": "🇬🇷",
  },
  {
    "name": "Gujarati (India)",
    "code": "gu-IN",
    "flag": "🇮🇳",
  },
  {
    "name": "Hebrew (Israel)",
    "code": "iw-IL",
    "flag": "🇮🇱",
  },
  {
    "name": "Hindi (India)",
    "code": "hi-IN",
    "flag": "🇮🇳",
  },
  {
    "name": "Hungarian (Hungary)",
    "code": "hu-HU",
    "flag": "🇭🇺",
  },
  {
    "name": "Icelandic (Iceland)",
    "code": "is-IS",
    "flag": "🇮🇸",
  },
  {
    "name": "Indonesian (Indonesia)",
    "code": "id-ID",
    "flag": "🇮🇩",
  },
  {
    "name": "Italian (Italy)",
    "code": "it-IT",
    "flag": "🇮🇹",
  },
  {
    "name": "Italian (Switzerland)",
    "code": "it-CH",
    "flag": "🇨🇭",
  },
  {
    "name": "Japanese (Japan)",
    "code": "ja-JP",
    "flag": "🇯🇵",
  },
  {
    "name": "Javanese (Indonesia)",
    "code": "jv-ID",
    "flag": "🇮🇩",
  },
  {
    "name": "Kannada (India)",
    "code": "kn-IN",
    "flag": "🇮🇳",
  },
  {
    "name": "Kazakh (Kazakhstan)",
    "code": "kk-KZ",
    "flag": "🇰🇿",
  },
  {
    "name": "Khmer (Cambodia)",
    "code": "km-KH",
    "flag": "🇰🇭",
  },
  {
    "name": "Korean (South Korea)",
    "code": "ko-KR",
    "flag": "🇰🇷",
  },
  {
    "name": "Lao (Laos)",
    "code": "lo-LA",
    "flag": "🇱🇦",
  },
  {
    "name": "Latvian (Latvia)",
    "code": "lv-LV",
    "flag": "🇱🇻",
  },
  {
    "name": "Lithuanian (Lithuania)",
    "code": "lt-LT",
    "flag": "🇱🇹",
  },
  {
    "name": "Macedonian (North Macedonia)",
    "code": "mk-MK",
    "flag": "🇲🇰",
  },
  {
    "name": "Malay (Malaysia)",
    "code": "ms-MY",
    "flag": "🇲🇾",
  },
  {
    "name": "Malayalam (India)",
    "code": "ml-IN",
    "flag": "🇮🇳",
  },
  {
    "name": "Marathi (India)",
    "code": "mr-IN",
    "flag": "🇮🇳",
  },
  {
    "name": "Mongolian (Mongolia)",
    "code": "mn-MN",
    "flag": "🇲🇳",
  },
  {
    "name": "Nepali (Nepal)",
    "code": "ne-NP",
    "flag": "🇳🇵",
  },
  {
    "name": "Norwegian Bokmål (Norway)",
    "code": "no-NO",
    "flag": "🇳🇴",
  },
  {
    "name": "Persian (Iran)",
    "code": "fa-IR",
    "flag": "🇮🇷",
  },
  {
    "name": "Polish (Poland)",
    "code": "pl-PL",
    "flag": "🇵🇱",
  },
  {
    "name": "Portuguese (Brazil)",
    "code": "pt-BR",
    "flag": "🇧🇷",
  },
  {
    "name": "Portuguese (Portugal)",
    "code": "pt-PT",
    "flag": "🇵🇹",
  },
  {
    "name": "Punjabi (Gurmukhi India)",
    "code": "pa-Guru-IN",
    "flag": "🇮🇳",
  },
  {
    "name": "Romanian (Romania)",
    "code": "ro-RO",
    "flag": "🇷🇴",
  },
  {
    "name": "Russian (Russia)",
    "code": "ru-RU",
    "flag": "🇷🇺",
  },
  {
    "name": "Kinyarwanda (Rwanda)",
    "code": "rw-RW",
    "flag": "🇷🇼",
  },
  {
    "name": "Serbian (Serbia)",
    "code": "sr-RS",
    "flag": "🇷🇸",
  },
  {
    "name": "Sinhala (Sri Lanka)",
    "code": "si-LK",
    "flag": "🇱🇰",
  },
  {
    "name": "Slovak (Slovakia)",
    "code": "sk-SK",
    "flag": "🇸🇰",
  },
  {
    "name": "Slovenian (Slovenia)",
    "code": "sl-SI",
    "flag": "🇸🇮",
  },
  {
    "name": "Swati (South Africa)",
    "code": "ss-latn-za",
    "flag": "🇿🇦",
  },
  {
    "name": "Southern Sotho (South Africa)",
    "code": "st-ZA",
    "flag": "🇿🇦",
  },
  {
    "name": "Spanish (Argentina)",
    "code": "es-AR",
    "flag": "🇦🇷",
  },
  {
    "name": "Spanish (Bolivia)",
    "code": "es-BO",
    "flag": "🇧🇴",
  },
  {
    "name": "Spanish (Chile)",
    "code": "es-CL",
    "flag": "🇨🇱",
  },
  {
    "name": "Spanish (Colombia)",
    "code": "es-CO",
    "flag": "🇨🇴",
  },
  {
    "name": "Spanish (Costa Rica)",
    "code": "es-CR",
    "flag": "🇨🇷",
  },
  {
    "name": "Spanish (Dominican Republic)",
    "code": "es-DO",
    "flag": "🇩🇴",
  },
  {
    "name": "Spanish (Ecuador)",
    "code": "es-EC",
    "flag": "🇪🇨",
  },
  {
    "name": "Spanish (El Salvador)",
    "code": "es-SV",
    "flag": "🇸🇻",
  },
  {
    "name": "Spanish (Guatemala)",
    "code": "es-GT",
    "flag": "🇬🇹",
  },
  {
    "name": "Spanish (Honduras)",
    "code": "es-HN",
    "flag": "🇭🇳",
  },
  {
    "name": "Spanish (Mexico)",
    "code": "es-MX",
    "flag": "🇲🇽",
  },
  {
    "name": "Spanish (Nicaragua)",
    "code": "es-NI",
    "flag": "🇳🇮",
  },
  {
    "name": "Spanish (Panama)",
    "code": "es-PA",
    "flag": "🇵🇦",
  },
  {
    "name": "Spanish (Paraguay)",
    "code": "es-PY",
    "flag": "🇵🇾",
  },
  {
    "name": "Spanish (Peru)",
    "code": "es-PE",
    "flag": "🇵🇪",
  },
  {
    "name": "Spanish (Puerto Rico)",
    "code": "es-PR",
    "flag": "🇵🇷",
  },
  {
    "name": "Spanish (Spain)",
    "code": "es-ES",
    "flag": "🇪🇸",
  },
  {
    "name": "Spanish (United States)",
    "code": "es-US",
    "flag": "🇺🇸",
  },
  {
    "name": "Spanish (Uruguay)",
    "code": "es-UY",
    "flag": "🇺🇾",
  },
  {
    "name": "Spanish (Venezuela)",
    "code": "es-VE",
    "flag": "🇻🇪",
  },
  {
    "name": "Sundanese (Indonesia)",
    "code": "su-ID",
    "flag": "🇮🇩",
  },
  {
    "name": "Swahili (Kenya)",
    "code": "sw-KE",
    "flag": "🇰🇪",
  },
  {
    "name": "Swahili (Tanzania)",
    "code": "sw-TZ",
    "flag": "🇹🇿",
  },
  {
    "name": "Swedish (Sweden)",
    "code": "sv-SE",
    "flag": "🇸🇪",
  },
  {
    "name": "Tamil (India)",
    "code": "ta-IN",
    "flag": "🇮🇳",
  },
  {
    "name": "Tamil (Malaysia)",
    "code": "ta-MY",
    "flag": "🇲🇾",
  },
  {
    "name": "Tamil (Singapore)",
    "code": "ta-SG",
    "flag": "🇸🇬",
  },
  {
    "name": "Tamil (Sri Lanka)",
    "code": "ta-LK",
    "flag": "🇱🇰",
  },
  {
    "name": "Telugu (India)",
    "code": "te-IN",
    "flag": "🇮🇳",
  },
  {
    "name": "Thai (Thailand)",
    "code": "th-TH",
    "flag": "🇹🇭",
  },
  {
    "name": "Setswana (South Africa)",
    "code": "tn-latn-za",
    "flag": "🇿🇦",
  },
  {
    "name": "Turkish (Turkey)",
    "code": "tr-TR",
    "flag": "🇹🇷",
  },
  {
    "name": "Tsonga (South Africa)",
    "code": "ts-ZA",
    "flag": "🇿🇦",
  },
  {
    "name": "Ukrainian (Ukraine)",
    "code": "uk-UA",
    "flag": "🇺🇦",
  },
  {
    "name": "Urdu (India)",
    "code": "ur-IN",
    "flag": "🇮🇳",
  },
  {
    "name": "Urdu (Pakistan)",
    "code": "ur-PK",
    "flag": "🇵🇰",
  },
  {
    "name": "Uzbek (Uzbekistan)",
    "code": "uz-UZ",
    "flag": "🇺🇿",
  },
  {
    "name": "Venda (South Africa)",
    "code": "ve-ZA",
    "flag": "🇿🇦",
  },
  {
    "name": "Vietnamese (Vietnam)",
    "code": "vi-VN",
    "flag": "🇻🇳",
  },
  {
    "name": "isiXhosa (South Africa)",
    "code": "xh-ZA",
    "flag": "🇿🇦",
  },
  {
    "name": "Zulu (South Africa)",
    "code": "zu-ZA",
    "flag": "🇿🇦",
  },
];

export default languageList;
