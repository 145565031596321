import React, {useCallback, useContext, useMemo, useRef, useState} from 'react';

import {Animated, Dimensions, Image, Platform, StyleSheet, Text} from 'react-native';
import uuid from 'react-native-uuid';
import {useTranslation} from 'react-i18next';
import * as Device from 'expo-device';
import * as Location from 'expo-location';
import * as Haptics from 'expo-haptics';
import * as Sentry from 'sentry-expo';
import Constants from 'expo-constants';

import Api from '../../constants/Api';
import {UserContext} from '../../contexts/UserContext';
import {SettingsContext} from '../../contexts/SettingsContext';
import {ThemeContext} from '../../contexts/ThemeContext';
import {formatHoursAndMinutes} from '../../helpers/dateHelper';
import useRecorder from '../../hooks/useRecorder';
import useSpeaker from '../../hooks/useSpeaker';
import Menu from '../../components/Menu';

import FooterButton from './FooterButton';

const LONG_PRESS_TIME = 300;

let alertCloseTimeout;
let timerInterval;
let microphonePermissionsRequested = false;

const Recorder = ({pushLocalQuery, updateLocalQuery, createQuery, scrollViewRef}) => {
  const {t} = useTranslation();
  const {userToken} = useContext(UserContext);
  const {settings: {language}} = useContext(SettingsContext);
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme), [theme]);

  const recordButtonRef = useRef(null);

  const recorder = useRecorder();
  const speaker = useSpeaker();
  const [shortPressAlertVisible, setShortPressAlertVisible] = useState(false);
  const [displayTime, setDisplayTime] = useState(0);
  const [uploading, setUploading] = useState(false);

  const handleUploadRecording = useCallback(async (uri, ref) => {
    try {
      const formData = new FormData();

      formData.append('user_token', userToken);
      formData.append('recording[ref]', ref);

      if(Platform.OS === 'web') {
        const blob = await (await fetch(uri)).blob();

        formData.append('recording[file]', blob, 'recording_' + Constants.sessionId + '_' + Date.now() + '.wav');
      } else {

        formData.append('recording[file]', {
          uri,
          type: 'audio/x-wav',
          name: 'recording_' + Constants.sessionId + '_' + Date.now() + '.wav',
        });
      }

      formData.append('recording[language]', language); // fr-FR
      formData.append('recording[device_brand]', Device.brand); // Android: "google", "xiaomi"; iOS: "Apple"; web: null
      formData.append('recording[device_name]', Device.deviceName); // "Vivian's iPhone XS"
      formData.append('recording[device_manufacturer]', Device.manufacturer); // Android: "Google", "xiaomi"; iOS: "Apple"; web: "Google", null
      formData.append('recording[device_os_name]', Platform.OS);
      formData.append('recording[device_os_version]', Device.osVersion);
      formData.append('recording[device_model_name]', Device.modelName);
      formData.append('recording[expo_session_id]', Constants.sessionId);
      formData.append('recording[expo_installation_id]', Constants.installationId);

      if(Platform.OS === 'web') {
        formData.append('recording[encoding]', 'WEBM_OPUS');
        formData.append('recording[sample_rate]', '48000');
        formData.append('recording[bit_rate]', '128000');
      } else if(Platform.OS === 'ios') {
        formData.append('recording[encoding]', 'LINEAR16');
        formData.append('recording[sample_rate]', '48000');
        formData.append('recording[bit_rate]', '128000');
      } else {
        formData.append('recording[encoding]', 'AMR_WB');
        formData.append('recording[sample_rate]', '16000');
        formData.append('recording[bit_rate]', '16000');
      }

      const deviceType = await Device.getDeviceTypeAsync();
      if(deviceType === 0)       { formData.append('recording[device_type]', 'unknown'); }
      else if(deviceType === 1)  { formData.append('recording[device_type]', 'phone'); }
      else if(deviceType === 2)  { formData.append('recording[device_type]', 'tablet'); }
      else if(deviceType === 3)  { formData.append('recording[device_type]', 'desktop'); }
      else if(deviceType === 4)  { formData.append('recording[device_type]', 'tv'); }
      else { formData.append('recording[device_type]', 'UNKNOWN'); }

      let locationPerm = {};

      if (Platform.OS === 'web') {
        const {state} = await navigator.permissions.query({name: 'geolocation'});

        locationPerm = {granted: state === 'granted'};
      } else {
        locationPerm = await Location.getForegroundPermissionsAsync();
      }

      if (locationPerm.granted) {
        let location = await Location.getLastKnownPositionAsync({
          requiredAccuracy: 300, // Note: Return null if ~300 meter away from last known location
          maxAge: 600000, // Note: Return null if last known location is more than 10m old
        });

        if (!location) {
          location = await Location.getCurrentPositionAsync({
            accuracy: Location.Accuracy.High,
            mayShowUserSettingsDialog: false,
          });
        }

        formData.append('recording[latitude]', location.coords.latitude);
        formData.append('recording[longitude]', location.coords.longitude);
      }

      const response = await fetch(`${Api.apiBaseUrl}/recordings.json`, {
        method: 'POST',
        body: formData,
      });

      return await response.json();
    } catch (error) {
      // TODO: Handle errors
      console.log('There was an error during upload.', error);
      throw error;
    }
  }, [userToken, language]);

  const scaleAnim = useRef(new Animated.Value(1.0)).current;
  const translateAnim = useRef(new Animated.Value(Dimensions.get('window').width)).current;

  const recorderAnimation = useMemo(() => (
    Animated.stagger(400, [
      Animated.timing(translateAnim, {toValue: 0, duration: 400, useNativeDriver: true}),
      Animated.loop(
        Animated.sequence([
          Animated.timing(scaleAnim, {toValue: 1.0, duration: 400, useNativeDriver: true}),
          Animated.timing(scaleAnim, {toValue: 0.6, duration: 400, useNativeDriver: true}),
          Animated.timing(scaleAnim, {toValue: 1.0, duration: 400, useNativeDriver: true}),
        ])
      ),
    ])
  ), [scaleAnim, translateAnim]);

  const handleLongPress = useCallback(async () => {
    // Note: If this press triggered a permission request we don't want to do anything else
    if (microphonePermissionsRequested) {
      microphonePermissionsRequested = false;
      return;
    }

    speaker.stop();

    if (Platform.OS !== 'web') {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.High);
    }

    await recorder.start();

    const startTime = Date.now();

    timerInterval = setInterval(() => {
      setDisplayTime(Math.round((Date.now() - startTime) / 1000));
    }, 1000);

    recorderAnimation.start();
  }, [recorder, recorderAnimation, speaker]);

  const handlePress = useCallback(async () => {
    // Note: If this press triggered a permission request we don't want to do anything else
    if (microphonePermissionsRequested) {
      microphonePermissionsRequested = false;
      return;
    }

    if (Platform.OS !== 'web') {
      Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
    }

    setShortPressAlertVisible(true);

    // Note: We always clear the last alertCloseTimeout to replace it with the new one
    clearTimeout(alertCloseTimeout);
    alertCloseTimeout = setTimeout(() => setShortPressAlertVisible(false), 2000);
  }, []);

  const handlePressIn = useCallback(async () => {
    await recorder.requestPermissions(() => {
      microphonePermissionsRequested = true;
    });
  }, [recorder]);

  const handlePressOut = useCallback(async () => {
    if (recorder.state.status === 'starting') {
      recorder.stop();
    } else if (recorder.state.status === 'recording') {
      try {
        setUploading(true);

        if (Platform.OS !== 'web') {
          Haptics.impactAsync(Haptics.ImpactFeedbackStyle.High);
        }

        const uri = await recorder.stop();

        const ref = uuid.v4();

        const localQuery = {
          ref,
          text: null,
          channel: 'recording',
          created_at: new Date(),
          updated_at: new Date(),
          responses: [],
        };

        // Note: Optimistically add a fake query until the server return the result
        const localQueries = pushLocalQuery(localQuery);

        setTimeout(() => scrollViewRef && scrollViewRef.current && scrollViewRef.current.scrollToEnd({animated: true}), 0);

        let recording;

        try {
          recording = await handleUploadRecording(uri, ref);

          // Note: Update localQuery text with the transcription of the recording
          localQuery.text = recording.transcription;

          if (recording.status === 'failed') {
            localQuery.text = t('MainScreen.ChatView.Recorder.errors.noAudio', 'Sorry, we didn\'t detect any audio.');
            localQuery.status = 'empty';
          }
        } catch (error) {
          localQuery.text = t('MainScreen.ChatView.Recorder.errors.recordingFailed', 'Sorry, something went wrong, try again later.');
          localQuery.status = 'empty';

          recording = null;

          if (Platform.OS === 'web') {
            Sentry.Browser.captureException(error);
          } else {
            Sentry.captureException(error);
          }
        }

        updateLocalQuery(localQueries, localQuery);

        setTimeout(() => scrollViewRef && scrollViewRef.current && scrollViewRef.current.scrollToEnd({animated: true}), 0);

        Animated.timing(translateAnim, {
          toValue: Dimensions.get('window').width,
          duration: 300,
          useNativeDriver: true,
        }).start(async () => {
          setUploading(false);

          if (Platform.OS !== 'web') {
            Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
          }

          clearInterval(timerInterval);
          recorderAnimation.reset();
          setDisplayTime(0);

          if (recording && recording.status !== 'failed') {
            await createQuery({
              ref,
              channel: 'recording',
              text: recording.transcription,
              recordingId: recording.id,
            }, localQueries, localQuery);

            setTimeout(() => scrollViewRef && scrollViewRef.current && scrollViewRef.current.scrollToEnd({animated: true}), 0);
          }
        });
      } catch (error) {
        console.log('There was an error during upload.', error);

        if (Platform.OS === 'web') {
          Sentry.Browser.captureException(error);
        } else {
          Sentry.captureException(error);
        }
      }
    }
  }, [
    recorder,
    pushLocalQuery,
    updateLocalQuery,
    translateAnim,
    scrollViewRef,
    handleUploadRecording,
    t,
    recorderAnimation,
    createQuery,
  ]);

  return (
    <>
      <Animated.View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          position: 'absolute',
          width: '100%',
          height: 64,
          paddingLeft: 12,
          backgroundColor: theme.footerBackground,
          transform: [{translateX: translateAnim}],
        }}
      >
        {uploading
          ? (
            <Text style={{color: theme.textPrimary, marginLeft: 12}}>
              {t('MainScreen.ChatView.Recorder.uploading', 'Uploading...')}
            </Text>
          )
          : (
            <>
              <Animated.View
                style={{
                  height: 16,
                  width: 16,
                  backgroundColor: 'red',
                  borderRadius: 50,
                  marginRight: 8,
                  transform: [{scale: scaleAnim}],
                }}
              />

              <Text style={{color: theme.textPrimary}}>
                {formatHoursAndMinutes(Math.floor(displayTime / 60), (displayTime % 60))}
              </Text>
            </>
          )}
      </Animated.View>

      {!uploading && (
        <FooterButton
          ref={recordButtonRef}
          onPressIn={handlePressIn}
          onPressOut={handlePressOut}
          onLongPress={handleLongPress}
          onPress={handlePress}
          delayLongPress={LONG_PRESS_TIME}
          disabled={uploading}
          iconBackgroundColor={recorder.state.status === 'recording' ? '#ffffff' : theme.colorPrimary}
          icon={
            <Image
              source={require('../../assets/icon-white.png')}
              style={{
                width: 34,
                height: 34,
                tintColor: recorder.state.status === 'recording' ? '#000' : '#fff',
              }}
            />
          }
        />
      )}

      <Menu
        anchor={recordButtonRef}
        open={shortPressAlertVisible}
        close={() => setShortPressAlertVisible(false)}
        position="top"
        variant="warning"
      >
        <Text style={styles.modalText}>
          {t('MainScreen.ChatView.Recorder.errors.holdButton', 'Hold the button while speaking.')}
        </Text>
      </Menu>
    </>
  );
};

const getStyles = () => StyleSheet.create({
  centeredView: {
    flex: 1,
  },
  modalText: {
    color: '#fff',
    fontSize: 16,
    textAlign: 'center',
  },
});

export default Recorder;
