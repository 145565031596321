import React, {useState, useCallback, useContext, useMemo} from 'react';

import {
  Alert,
  Keyboard,
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableWithoutFeedback,
  TouchableOpacity,
  Platform,
} from 'react-native';
import {Trans, useTranslation} from 'react-i18next';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as Localization from 'expo-localization';
import * as Sentry from 'sentry-expo';
import {FontAwesome} from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';

import Api from '../../../constants/Api';
import {DEVICE_TYPE_LABELS} from '../../../constants/device';
import {ThemeContext} from '../../../contexts/ThemeContext';
import {UserContext} from '../../../contexts/UserContext';
import countries from '../../../assets/countries';
import SearchModal from '../../../components/SearchModal';
import StyledButton from '../../../components/StyledButton';
import openLink from '../../../utils/openLink';

const SignupForm = ({country, setCountry, status, setStatus, phoneNumber, setPhoneNumber}) => {
  const {t} = useTranslation();
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme), [theme]);
  const {user, deviceToken, setUser, setUserId, setUserToken} = useContext(UserContext);

  const [countryModalOpen, setCountryModalOpen] = useState(false);
  const [signingUpAsGuest, setSigningUpAsGuest] = useState(false);

  const openCountryModal = useCallback(() => {
    setCountryModalOpen(true);
  }, [setCountryModalOpen]);

  const closeCountryModal = useCallback(() => {
    setCountryModalOpen(false);
  }, [setCountryModalOpen]);

  const handleSignupPress = async () => {
    if (phoneNumber === '') {
      if (Platform.OS === 'web') {
        window.alert(t('SignupScreen.signup.alerts.missingPhoneNumber.message', 'Please enter your phone number.'));
      } else {
        Alert.alert(
          t('SignupScreen.signup.alerts.missingPhoneNumber.title', 'Phone Number'),
          t('SignupScreen.signup.alerts.missingPhoneNumber.message', 'Please enter your phone number.'),
        );
      }
    } else if (country.dial_code === '+1' && phoneNumber.startsWith('+1')) {
      if (Platform.OS === 'web') {
        window.alert(t('SignupScreen.signup.alerts.noAmericanNumber.message', 'American and Canadian phone numbers are not currently supported.'));
      } else {
        Alert.alert(
          t('SignupScreen.signup.alerts.noAmericanNumber.title', 'Unsupported phone number'),
          t('SignupScreen.signup.alerts.noAmericanNumber.message', 'American and Canadian phone numbers are not currently supported.'),
        );
      }
    } else if (!phoneNumber.match(/^\+[0-9]+$/) && !phoneNumber.match(/^[0-9]+$/)) {
      if (Platform.OS === 'web') {
        window.alert(t('SignupScreen.signup.alerts.onlyDigits.message', 'Please enter digits only.'));
      } else {
        Alert.alert(
          t('SignupScreen.signup.alerts.onlyDigits.title', 'Phone Number'),
          t('SignupScreen.signup.alerts.onlyDigits.message', 'Please enter digits only.'),
        );
      }
    } else {
      setStatus('sending');

      try {
        const response = await fetch(`${Api.apiBaseUrl}/api/signup`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            phone_number: phoneNumber,
            expo_installation_id: Constants.installationId,
            expo_session_id: Constants.sessionId,
          }),
        });

        const responseJson = await response.json();

        console.log('************ response ************');
        console.log(responseJson);

        if (responseJson.status === 'ok') {
          setStatus('sent');
        } else {
          setStatus('error');
        }
      } catch (error) {
        console.error(error);
        setStatus('error');

        if (Platform.OS === 'web') {
          Sentry.Browser.captureException(error);
        } else {
          Sentry.captureException(error);
        }
      }
    }
  };

  const _storeToken = async (userId, userToken) => {
    try {
      await AsyncStorage.setItem('userId', userId);
      await AsyncStorage.setItem('userToken', userToken);

      setUserId(userId);
      setUserToken(userToken);
    } catch (error) {
      console.error(error);

      if (Platform.OS === 'web') {
        Sentry.Browser.captureException(error);
      } else {
        Sentry.captureException(error);
      }
    }
  };

  const handleSignupAsGuestPress = async () => {
    setSigningUpAsGuest(true);

    try {
      const deviceType = await Device.getDeviceTypeAsync();

      const response = await fetch(`${Api.apiBaseUrl}/api/signup_as_guest`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          device_token: deviceToken,

          country_code: Localization.getLocales()[0].regionCode,
          timezone: Localization.timezone,

          device_type: DEVICE_TYPE_LABELS[deviceType] || 'UNKNOWN',
          device_model_name: Device.modelName,
          brand: Device.brand, // Android: "google", "xiaomi"; iOS: "Apple"; web: null
          manufacturer: Device.manufacturer, // Android: "Google", "xiaomi"; iOS: "Apple"; web: "Google", null
          os_name: Platform.OS,
          os_version: Device.osVersion,
          name: Device.deviceName, // "Vivian's iPhone XS"
        }),
      });

      const responseJson = await response.json();

      console.log('************ response ************');
      console.log(responseJson);

      if (responseJson.status === 'ok') {
        const userResponse = await fetch(
          `${Api.apiBaseUrl}/users/${responseJson.user_id}.json?user_token=${responseJson.user_token}`,
          {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
          }
        );

        const userResponseJson = await userResponse.json();

        console.log('userResponseJson');
        console.log(userResponseJson);

        if(userResponseJson && userResponseJson.id) {
          console.log('fetchUser OK');

          setUser(userResponseJson);
        }

        _storeToken(responseJson.user_id, responseJson.user_token);
      } else {
        setStatus('error');
      }

      setSigningUpAsGuest(false);
    } catch (error) {
      console.error(error);
      setSigningUpAsGuest(false);

      if (Platform.OS === 'web') {
        Sentry.Browser.captureException(error);
      } else {
        Sentry.captureException(error);
      }
    }
  };

  return (
    <View style={styles.container}>
      <View style={{alignItems: 'center'}}>
        <Text style={styles.subtitle}>
          {t('SignupScreen.signup.title', 'Sign up using your phone number.')}
        </Text>

        <View style={styles.inputContainer}>
          <FontAwesome
            style={[styles.iconStyle, {marginHorizontal: 15}]}
            name="phone"
            size={28}
          />

          <TouchableOpacity
            style={{flexDirection: 'row'}}
            onPress={() => {
              if (Platform.OS !== 'web') {
                Keyboard.dismiss();
              }
              openCountryModal();
            }}
            accessible={false}
          >
            <Text style={styles.flag}>
              {country.flag}
            </Text>

            <FontAwesome
              style={[styles.iconStyle, {marginLeft: 0, marginRight: 10}]}
              name="caret-down"
              size={28}
            />
          </TouchableOpacity>

          <TouchableWithoutFeedback
            accessible={false}
            onPress={() => {
              if (Platform.OS !== 'web') {
                Keyboard.dismiss();
              }
            }}
          >
            <TextInput
              style={styles.input}
              placeholder={country.dial_code}
              placeholderTextColor={theme.textSecondary}
              keyboardType="phone-pad"
              returnKeyType="done"
              autoCapitalize="none"
              autoCorrect={false}
              secureTextEntry={false}
              value={phoneNumber}
              onChangeText={(val) => {
                const phoneNumberRegex = new RegExp(`^\\${country.dial_code}0+`);

                if (phoneNumber === '') {
                  setPhoneNumber(country.dial_code + val.replace(phoneNumberRegex, country.dial_code));
                } else {
                  setPhoneNumber(val.replace(phoneNumberRegex, country.dial_code));
                }}
              }
            />
          </TouchableWithoutFeedback>

          <SearchModal
            data={countries}
            open={countryModalOpen}
            close={closeCountryModal}
            onClick={item => {
              setPhoneNumber(item.dial_code);
              setCountry(item);
            }}
            getText={item => `${item.name} (${item.dial_code})`}
          />
        </View>

        <StyledButton
          style={styles.button}
          text={t('SignupScreen.signup.signupButton.text', 'Sign Up')}
          loading={status === 'sending'}
          onPress={handleSignupPress}
        />

        <Text style={styles.legalText}>
          <Trans i18nKey="SignupScreen.signup.tosNotice">
            {'By signing up, you accept our '}
            <Text style={styles.hyperlinkStyle} onPress={() => openLink('https://www.secretaire.ai/terms')}>
              Terms of Service
            </Text>
            {' and our '}
            <Text style={styles.hyperlinkStyle} onPress={() => openLink('https://www.secretaire.ai/terms')}>
              Privacy Notice
            </Text>
            .
          </Trans>
        </Text>
      </View>

      {(!user && Platform.OS !== 'web') && (
        <StyledButton
          style={{marginBottom: 10}}
          text={t('SignupScreen.signup.continueAsGuestButton.text', 'Continue as guest')}
          variant="textButtonSecondary"
          loading={signingUpAsGuest}
          onPress={handleSignupAsGuestPress}
        />
      )}
    </View>
  );
};

// TODO: Refactor styles with the other component
const getStyles = theme => StyleSheet.create({
  container: {
    marginTop: 10,
    marginBottom: 10,
    flex: 1,
    justifyContent: 'space-between',
  },
  subtitle: {
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'center',
    maxWidth: 250,
    color: theme.textPrimary,
  },
  inputContainer: {
    flexDirection: 'row',
    maxWidth: 320,
    marginTop: 15,
    marginBottom: 25,
    padding: 10,
    borderRadius: 50,
    backgroundColor: theme.inputBackgroundColor,
    borderColor: theme.inputBorderColor,
  },
  iconStyle: {
    color: theme.colorSecondary,
    marginTop: 5,
    marginBottom: 0,
  },
  flag: {
    fontSize: 28,
    marginLeft: 5,
    marginRight: 5,
  },
  input: {
    flex: 1,
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.textPrimary,
  },
  button: {
    paddingVertical: 15,
    paddingHorizontal: 80,
    marginBottom: 30,
  },
  legalText: {
    color: theme.textSecondary,
    fontSize: 13,
    textAlign: 'center',
    maxWidth: 300,
  },
  hyperlinkStyle: {
    color: theme.link,
  },
});

export default SignupForm;
