import React, {useContext, useMemo} from 'react';
import {View, Text, TextInput, StyleSheet} from 'react-native';

import {ThemeContext} from '../contexts/ThemeContext';

const StyledInput = ({style, inputStyle, errors, ...props}) => {
  const theme = useContext(ThemeContext);
  const textInputStyle = useMemo(() => getStyles(theme), [theme]);

  return (
    <View
      style={[
        {width: '100%', flexDirection: 'column'},
        ...(style && style.length ? style || [] : [style]),
      ]}
    >
      <TextInput
        style={[
          textInputStyle.input,
          ...(inputStyle && inputStyle.length ? inputStyle || [] : [inputStyle]),
          ...((errors || []).length ? [{borderColor: theme.error}] : []),
        ]}
        placeholderTextColor="#666666"
        {...props}
      />

      {(errors || []).map(error => (
        <Text key={error} style={{color: theme.error, marginTop: 8}}>
          {error}
        </Text>
      ))}
    </View>
  );
};

const getStyles = theme => StyleSheet.create({
  input: {
    width: '100%',
    height: 45,
    fontSize: 16,
    padding: 10,
    color: theme.textPrimary,
    backgroundColor: theme.inputBackgroundColor,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: theme.inputBorderColor,
  },
});

export default StyledInput;
