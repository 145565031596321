import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';

import {Dimensions, Pressable, StyleSheet, View} from 'react-native';

import {ThemeContext} from '../contexts/ThemeContext';

const MODAL_HORIZONTAL_MARGIN = 12;
const MODAL_PADDING = 10;
const BORDER_WIDTH = 1;
const TRIANGLE_SIZE = 10;

const Menu = ({
  style,
  anchor,
  open,
  close,
  position = 'top',
  variant = 'regular',
  children,
}) => {
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme, {position, variant}), [theme]);

  const menuRef = useRef(null);

  const [menuMeasurement, setMenuMeasurement] = useState({height: 0, x: -1000, opacity: 0});
  const [anchorMeasurement, setAnchorMeasurement] = useState({width: 0, x: -1000});

  useEffect(() => {
    if (menuRef.current) {
      setTimeout(() => menuRef.current.measure((fx, fy, width, height, px) => {
        setMenuMeasurement({height, x: px, opacity: 1});
      }), 0);
    }
  }, [open]);

  useEffect(() => {
    if (anchor.current) {
      setTimeout(() => anchor.current.measure((fx, fy, width, height, px) => {
        setAnchorMeasurement({width, x: px});
      }), 0);
    }
  }, [anchor.current, open]);

  if (!open) return null;

  return (
    <Pressable
      ref={menuRef}
      style={[
        styles.modalView,
        {
          opacity: menuMeasurement.opacity,
          maxWidth: Dimensions.get('window').width - MODAL_HORIZONTAL_MARGIN * 2,
          [position]: -(menuMeasurement.height + 8),
        },
        ...(style && style.length ? style || [] : [style]),
      ]}
      onPress={close}
    >
      <View
        style={{
          maxWidth: Dimensions.get('window').width - 2 * MODAL_HORIZONTAL_MARGIN - 2 * MODAL_PADDING,
        }}
      >
        {children}
      </View>

      {variant === 'regular' && (
        <View
          style={[
            styles.triangle,
            styles.outerTriangle,
            {
              left: (anchorMeasurement.x - menuMeasurement.x) + (anchorMeasurement.width / 2) - (TRIANGLE_SIZE / 2) - BORDER_WIDTH - 4,
            },
          ]}
        />
      )}

      <View
        style={[
          styles.triangle,
          styles.innerTriangle,
          {
            left: (anchorMeasurement.x - menuMeasurement.x) + (anchorMeasurement.width / 2) - (TRIANGLE_SIZE / 2) - BORDER_WIDTH - 4,
          },
        ]}
      />
    </Pressable>
  );
};

const getStyles = (theme, {position, variant}) => StyleSheet.create({
  modalView: {
    position: 'absolute',
    right: MODAL_HORIZONTAL_MARGIN,
    backgroundColor: variant === 'regular' ? theme.headerBackground : theme[variant],
    borderRadius: 10,
    ...(variant === 'regular'
      ? {
        borderStyle: 'solid',
        borderColor: theme.colorSecondary,
        borderWidth: BORDER_WIDTH,
      }
      : {}
    ),
    padding: MODAL_PADDING,
    alignItems: 'center',
  },
  modalText: {
    color: theme.textPrimary,
    fontSize: 16,
    textAlign: 'center',
  },
  triangle: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderLeftWidth: TRIANGLE_SIZE,
    borderLeftColor: 'transparent',
    borderRightWidth: TRIANGLE_SIZE,
    borderRightColor: 'transparent',
  },
  innerTriangle: {
    ...(position === 'top'
        ? {
          bottom: -(TRIANGLE_SIZE - BORDER_WIDTH),
          borderTopWidth: TRIANGLE_SIZE,
          borderTopColor: variant === 'regular' ? theme.headerBackground : theme[variant],
        }
        : {
          top: -(TRIANGLE_SIZE - BORDER_WIDTH),
          borderBottomWidth: TRIANGLE_SIZE,
          borderBottomColor: variant === 'regular' ? theme.headerBackground : theme[variant],
        }
    ),
  },
  outerTriangle: {
    ...(position === 'top'
        ? {
          bottom: -TRIANGLE_SIZE,
          borderTopWidth: TRIANGLE_SIZE,
          borderTopColor: variant === 'regular' ? theme.colorSecondary : undefined,
        }
        : {
          top: -TRIANGLE_SIZE,
          borderBottomWidth: TRIANGLE_SIZE,
          borderBottomColor: variant === 'regular' ? theme.colorSecondary : undefined,
        }
    ),
  },
});

export default Menu;
