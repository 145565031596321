import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Platform, StyleSheet, View, Dimensions} from 'react-native';
import {useTranslation} from 'react-i18next';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import * as Notifications from 'expo-notifications';
import * as SplashScreen from 'expo-splash-screen';
import * as Linking from 'expo-linking';
import * as Font from 'expo-font';
import * as NavigationBar from 'expo-navigation-bar';
import * as SecureStore from 'expo-secure-store';
import * as Sentry from 'sentry-expo';
import uuid from 'react-native-uuid';
import {Ionicons} from '@expo/vector-icons';
import Constants from 'expo-constants';

import SignupScreen from './screens/onboarding/SignupScreen/SignupScreen';
import NameScreen from './screens/onboarding/NameScreen';
import UsernameScreen from './screens/onboarding/UsernameScreen';
import GeolocationPermissionScreen from './screens/onboarding/GeolocationPermissionScreen';
import PushNotificationPermissionScreen from './screens/onboarding/PushNotificationPermissionScreen';
import ContactsPermissionScreen from './screens/onboarding/ContactsPermissionScreen';

import Api from './constants/Api';
import {DEFAULT_SETTINGS, SettingsContext} from './contexts/SettingsContext';
import {SpeakerContext} from './contexts/SpeakerContext';
import {ThemeContext, themes} from './contexts/ThemeContext';
import {UserContext} from './contexts/UserContext';
import {NotificationsContext} from './contexts/NotificationsContext';
import OnboardingHeader from './components/OnboardingHeader';
import StyledStatusBar from './components/StyledStatusBar';
import MainStackNavigator from './MainStackNavigator';
import UpgradeScreen from './screens/UpgradeScreen';

// import * as Sentry from 'sentry-expo';
//
// Sentry.init({
//   dsn: 'https://d995b8a71e40433aa619adbc440345d1@o103811.ingest.sentry.io/5757724',
//   enableInExpoDevelopment: true,
//   debug: true, // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
// });

// Access any @sentry/react-native exports via:
// Sentry.Native.*

// Access any @sentry/browser exports via:
// Sentry.Browser.*

// Test Sentry Error
// throw new Error("My first Sentry error!");

export const PURCHASES_STUB = {
  LOG_LEVEL: {},
  setLogLevel: () => {},
  configure: () => {},
  getOfferings: () => {},
  purchasePackage: () => {},
  getCustomerInfo: () => {},
  addCustomerInfoUpdateListener: () => {},
};

let Purchases = PURCHASES_STUB;

console.log(Constants.appOwnership, Platform.OS);

if (Constants.appOwnership !== 'expo' && Platform.OS !== 'web') {
  Purchases = require('react-native-purchases').default;
}
console.log('Purchases', Purchases);

const Stack = createStackNavigator();

// Prepare the notification channel
Notifications.setNotificationChannelAsync('new-match', {
  name: 'Match notifications',
  importance: Notifications.AndroidImportance.HIGH,
  sound: 'email-sound.wav', // <- for Android 8.0+, see channelId property below
});

Notifications.setNotificationChannelAsync('new-message', {
  name: 'Message notifications',
  importance: Notifications.AndroidImportance.HIGH,
  sound: 'email-sound.wav', // <- for Android 8.0+, see channelId property below
});

// When app is in foreground
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: false,
  }),
});

SplashScreen.preventAutoHideAsync();

const Splash = () => {
  const theme = useContext(ThemeContext);

  return (
    <View style={{
      flex: 1,
      backgroundColor: theme.backgroundPrimary,
    }}/>
  );
};

const App = () => {
  const containerRef = useRef();

  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [deviceToken, setDeviceToken] = useState(null);
  const [user, setUser] = useState(null);

  const [pushNotification, setPushNotification] = useState(true);
  const [notificationsCount, setNotificationsCount] = useState(null);

  const [settings, setSettings] = useState(DEFAULT_SETTINGS);
  const [speaking, setSpeaking] = useState(false);

  const [subscribed, setSubscribed] = useState(null);

  const {t, i18n} = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(settings.language);
  }, [i18n, settings]);

  const [drawerOpen, setDrawerOpen] = useState(Dimensions.get('window').width >= 1300);

  const toggleDrawer = useCallback(() => setDrawerOpen(!drawerOpen), [setDrawerOpen, drawerOpen]);
  const closeDrawer = useCallback( () => {
    if (Dimensions.get('window').width >= 1300) {
      // NOTE: We ignore close() call on web/tablets since the drawer can stay open on those platforms
      return ;
    }

    setDrawerOpen(false);
  }, [setDrawerOpen]);

  // Deep linking
  const linking = {
    prefixes: [Linking.createURL('/'), 'secretaire://', 'https://www.secretaire.ai'],
    config: {
      screens: {
        Root: {
          screens: {
            Debug: 'debug',
          },
        },
      },
    },
  };

  const checkSubscriptionStatus = useCallback(async () => {
    try {
      const customerInfo = await Purchases.getCustomerInfo();

      if (typeof customerInfo.entitlements.active['premium'] !== 'undefined') {
        setSubscribed(true);
      } else {
        setSubscribed(false);
      }
    } catch (error) {
      // TODO: Handle error
    }
  }, [setSubscribed]);

  useEffect(() => {
    if (user) {
      if (__DEV__) {
        Purchases.setLogLevel(Purchases.LOG_LEVEL.VERBOSE);
      }

      if (Platform.OS === 'ios') {
        Purchases.configure({apiKey: 'appl_KusxmSTLIKMeunVcvUvMOraiSxw', appUserID: userToken});
      } else if (Platform.OS === 'android') {
        Purchases.configure({apiKey: 'goog_aHyALiJxFOavJngXoCadVptudaW', appUserID: userToken});
      }

      checkSubscriptionStatus();

      Purchases.addCustomerInfoUpdateListener(customerInfo => {
        if (typeof customerInfo.entitlements.active['premium'] !== 'undefined') {
          setSubscribed(true);
        } else {
          setSubscribed(false);
        }
      });
    }
  }, [checkSubscriptionStatus, setSubscribed, user, userToken]);

  useEffect(() => {
    if (Platform.OS === 'android') {
      NavigationBar.setBackgroundColorAsync(themes[settings.theme].footerBackground);
      NavigationBar.setButtonStyleAsync(settings.theme);
    }
  }, [settings.theme]);

  useEffect(() => {
    const subscription = Notifications.addNotificationReceivedListener(notification => {
      console.log('@@@ notification @@@');
      console.log(notification);
    });

    return () => subscription.remove();
  }, []);

  const theme = useMemo(() => themes[settings.theme], [settings.theme]);

  const retrieveDeviceToken = useCallback(async () => {
    try {
      let deviceTokenFromStorage;

      if (Platform.OS === 'web') {
        deviceTokenFromStorage = await AsyncStorage.getItem('deviceToken');
      } else {
        deviceTokenFromStorage = await SecureStore.getItemAsync('deviceToken');
      }

      console.log('deviceTokenFromStorage', deviceTokenFromStorage);

      if (deviceTokenFromStorage) {
        setDeviceToken(deviceTokenFromStorage);
      } else {
        const deviceToken = uuid.v4();

        if (Platform.OS === 'web') {
          await AsyncStorage.setItem('deviceToken', deviceToken);
        } else {
          await SecureStore.setItemAsync('deviceToken', deviceToken);
        }

        setDeviceToken(deviceToken);
      }
    } catch (error) {
      // TODO: Handle error
      if (Platform.OS === 'web') {
        Sentry.Browser.captureException(error);
      } else {
        Sentry.captureException(error);
      }
    }
  }, [setDeviceToken]);

  const signOut = useCallback(async () => {
    await AsyncStorage.removeItem('userId');
    await AsyncStorage.removeItem('userToken');

    setUser(null);
    setUserId(null);
    setUserToken(null);
    setIsLoggedIn(false);
  }, []);

  const fetchUser = useCallback(async (userId, userToken) => {
    console.log('*** fetchUser ***');

    const response = await fetch(
      `${Api.apiBaseUrl}/users/${userId}.json?user_token=${userToken}`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );

    const responseJson = await response.json();

    console.log('responseJson');
    console.log(responseJson);

    if(responseJson && responseJson.id) {
      console.log('fetchUser OK');

      setUser(responseJson);
    } else {
      signOut();
    }
  }, [signOut]);

  const retrieveToken = useCallback(async () => {
    console.log('*** retrieveToken ***');

    try {
      if (userId && userToken) {
        console.log('userId OK', userId);
        console.log('userToken OK', userToken);

        setIsLoggedIn(true);

        await fetchUser(userId, userToken);
      } else {
        console.log('NO userId');
        console.log('NO userToken');

        const userIdFromStorage = await AsyncStorage.getItem('userId');
        const userTokenFromStorage = await AsyncStorage.getItem('userToken');

        if (userIdFromStorage && userTokenFromStorage) {
          console.log('userIdFromStorage OK', userIdFromStorage);
          console.log('userTokenFromStorage OK', userTokenFromStorage);

          setUserId(userIdFromStorage);
          setUserToken(userTokenFromStorage);
          setIsLoggedIn(true);

          await fetchUser(userIdFromStorage, userTokenFromStorage);
        } else {
          console.log('NO userIdFromStorage');
          console.log('NO userTokenFromStorage');

          setIsLoggedIn(false);
        }
      }
    } catch (error) {
      // Error retrieving data
      console.error('***** Error retrieving data *****');
      console.error(error);

      if (Platform.OS === 'web') {
        Sentry.Browser.captureException(error);
      } else {
        Sentry.captureException(error);
      }

      setIsLoggedIn(false);
    }
  }, [fetchUser, userId, userToken]);

  const refreshUser = useCallback(async () => {
    console.log('refreshUser called');

    if (!userId || !userToken) {
      console.log('Error: No userId or userToken');
      return false;
    }

    const response = await fetch(
      `${Api.apiBaseUrl}/users/${userId}.json?user_token=${userToken}`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );

    const responseJson = await response.json();

    console.log('responseJson');
    console.log(responseJson);

    if(responseJson && responseJson.id) {
      setUser(responseJson);
    } else {
      alert('Error fetching user.');
    }
  }, [userId, userToken]);

  const retrieveSettings = useCallback(async () => {
    try {
      const settingsFromStorage = await AsyncStorage.getItem('settings');

      if (settingsFromStorage) {
        setSettings(JSON.parse(settingsFromStorage));
      }
    } catch (error) {
      // TODO: Handle error, for now settings will be set to DEFAULT_SETTINGS which is probably fine

      if (Platform.OS === 'web') {
        Sentry.Browser.captureException(error);
      } else {
        Sentry.captureException(error);
      }
    }
  }, [setSettings]);

  const updateSettings = useCallback(async value => {
    setSettings(value);

    try {
      await AsyncStorage.setItem('settings', JSON.stringify(value));
    } catch (error) {
      // TODO: Handle error
      if (Platform.OS === 'web') {
        Sentry.Browser.captureException(error);
      } else {
        Sentry.captureException(error);
      }
    }
  }, [setSettings]);

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    const loadResourcesAndDataAsync = async () => {
      try {
        await Promise.all([
          retrieveDeviceToken(),
          retrieveToken(),
          retrieveSettings(),
          // Load fonts
          Font.loadAsync({
            ...Ionicons.font,
            'space-mono': require('./assets/fonts/SpaceMono-Regular.ttf'),
          }),
        ]);
      } catch (error) {
        console.warn(error);

        if (Platform.OS === 'web') {
          Sentry.Browser.captureException(error);
        } else {
          Sentry.captureException(error);
        }
      } finally {
        // TODO: We probably need an error screen and not use finally to set loading complete

        setLoadingComplete(true);
        await SplashScreen.hideAsync();
      }
    };

    loadResourcesAndDataAsync();
  }, []);

  let screens = null;

  if (!isLoadingComplete) {
    screens = <Stack.Screen name="Splash" component={Splash} options={{headerShown: false}}/>;
  } else if (!userToken || !user) {
    screens = <Stack.Screen name="Signup" component={SignupScreen} options={{headerShown: false}}/>;
  } else if ((user.status === 'guest' && !user.has_completed_guest_onboarding) || (user.status !== 'guest' && !user.has_completed_onboarding)) {
    screens =  (
      <Stack.Group>
        {user && user.status !== 'guest' && (
          <Stack.Group>
            <Stack.Screen
              name="Name"
              component={NameScreen}
              options={{
                header: ({navigation, back}) => (
                  <OnboardingHeader
                    back={back}
                    navigation={navigation}
                    step={1}
                    title={t('NameScreen.title', 'Your identity')}
                    subtitle={t('NameScreen.subtitle', 'Tell us about yourself')}
                  />
                ),
              }}
            />
            <Stack.Screen
              name="Username"
              component={UsernameScreen}
              options={{
                header: ({navigation, back}) => (
                  <OnboardingHeader
                    back={back}
                    navigation={navigation}
                    step={2}
                    title={t('UsernameScreen.title', 'Your username')}
                    subtitle={t('UsernameScreen.subtitle', 'Choose a nice one')}
                  />
                ),
              }}
            />
          </Stack.Group>
        )}
        <Stack.Screen
          name="GeolocationPermission"
          component={GeolocationPermissionScreen}
          options={{
            header: ({navigation, back}) => (
              <OnboardingHeader
                back={back}
                navigation={navigation}
                step={3}
                title={t('GeolocationPermissionScreen.title', 'Allow Geolocation')}
                subtitle={t('GeolocationPermissionScreen.subtitle', 'Geolocation allows Secrétaire to provide information about nearby places.')}
              />
            ),
          }}
        />
        <Stack.Screen
          name="PushNotificationPermission"
          component={PushNotificationPermissionScreen}
          options={{
            header: ({navigation, back}) => (
              <OnboardingHeader
                back={back}
                navigation={navigation}
                step={4}
                title={t('PushNotificationPermissionScreen.title', 'Allow Push Notifications')}
                subtitle={t('PushNotificationPermissionScreen.subtitle', 'Push notifications allows Secrétaire to notify you when not in the app.')}
              />
            ),
          }}
        />
        <Stack.Screen
          name="ContactsPermission"
          component={ContactsPermissionScreen}
          options={{
            header: ({navigation, back}) => (
              <OnboardingHeader
                back={back}
                navigation={navigation}
                step={4}
                title={t('ContactsPermissionScreen.title', 'Allow Access To Contacts')}
                subtitle={t('ContactsPermissionScreen.subtitle', 'Access to contacts allows Secrétaire to personalize your experience.')}
              />
            ),
          }}
        />
        <Stack.Screen
          name="Upgrade"
          options={{
            header: ({navigation, back}) => (
              <OnboardingHeader
                back={back}
                navigation={navigation}
                step={4}
                title={t('UpgradeScreen.onboardingHeaderTitle', 'Choose your plan')}
                subtitle={t('UpgradeScreen.onboardingHeaderSubtitle', 'Get unlimited queries.')}
              />
            ),
          }}
        >
          {() => (
            <UpgradeScreen onboarding/>
          )}
        </Stack.Screen>
      </Stack.Group>
    );
  } else {
    screens = (
      <Stack.Screen
        name="Root"
        options={{headerShown: false}}
      >
        {() => (
          <MainStackNavigator
            drawerOpen={drawerOpen}
            toggleDrawer={toggleDrawer}
            closeDrawer={closeDrawer}
          />
        )}
      </Stack.Screen>
    );
  }

  return (
    <SettingsContext.Provider value={{settings, updateSettings}}>
      <ThemeContext.Provider value={theme}>
        <UserContext.Provider value={{user, userId, userToken, deviceToken, setUser, setUserId, setUserToken, setDeviceToken, isLoggedIn, setIsLoggedIn, refreshUser, signOut, pushNotification, setPushNotification, subscribed, setSubscribed}}>
          <NotificationsContext.Provider value={{notificationsCount, setNotificationsCount}}>
            <SpeakerContext.Provider value={{speaking, setSpeaking}}>
              <View style={styles.container}>
                <StyledStatusBar/>

                <NavigationContainer ref={containerRef} linking={linking}>
                  <Stack.Navigator>
                    {screens}
                  </Stack.Navigator>
                </NavigationContainer>
              </View>
            </SpeakerContext.Provider>
          </NotificationsContext.Provider>
        </UserContext.Provider>
      </ThemeContext.Provider>
    </SettingsContext.Provider>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
});

export default App;
