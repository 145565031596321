import React, {useContext, useMemo} from 'react';
import {Image, SafeAreaView, StyleSheet, Text, View} from 'react-native';
import {HeaderBackButton} from '@react-navigation/elements';

import {ThemeContext} from '../contexts/ThemeContext';

const STEPS_IMAGES = [
	require('../assets/images/steps/step1.png'),
	require('../assets/images/steps/step2.png'),
	require('../assets/images/steps/step3.png'),
	require('../assets/images/steps/step4.png'),
];

const OnboardingHeader = ({back, navigation, step, title, subtitle}) => {
	const theme = useContext(ThemeContext);
	const styles = useMemo(() => getStyles(theme), [theme]);

	return (
		<SafeAreaView style={{backgroundColor: theme.headerBackground}}>
			<View style={styles.header}>
				{back && (
					<View style={styles.backContainer}>
						<HeaderBackButton
							canGoBack={back}
							tintColor={theme.textSecondary}
							onPress={() => navigation.goBack()}
						/>
					</View>
				)}

				<View style={{paddingVertical: 20, paddingHorizontal: 40}}>
					{/*<Image source={STEPS_IMAGES[step - 1]} style={styles.stepImage}/>*/}

					<Text style={[styles.text, styles.title]}>
						{title}
					</Text>

					<Text style={[styles.text, styles.subtitle]}>
						{subtitle}
					</Text>
				</View>
			</View>
		</SafeAreaView>
	);
};

const getStyles = theme => StyleSheet.create({
	header: {
		backgroundColor: theme.headerBackground,
		borderBottomWidth: 1,
		borderBottomColor: theme.headerBorderColor,
		borderStyle: 'solid',
	},
	backContainer: {
		height: '100%',
		position: 'absolute',
		marginTop: 6,
		elevation: 2,
		zIndex: 2,
	},
	stepImage: {
		marginBottom: 10,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	text: {
		color: theme.textPrimary,
		textAlign: 'center',
	},
	title: {
		fontSize: 22,
		fontWeight: 'bold',
		marginBottom: 5,
	},
	subtitle: {
		fontSize: 15,
	},
});

export default OnboardingHeader;
