import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import en from './locales/en.json';
import fr from './locales/fr.json';
import es from './locales/es.json';
import zh from './locales/zh.json';
import pt from './locales/pt.json';
import ru from './locales/ru.json';
import it from './locales/it.json';
import de from './locales/de.json';
import iw from './locales/iw.json';
import ar from './locales/ar.json';

// Note: Duplicated in i18next-parser.config.js
const SUPPORTED_LANGUAGE = ['en', 'fr', 'es', 'zh', 'pt', 'ru', 'it', 'de', 'iw', 'ar'];

i18n
	.use(initReactI18next)
	.init({
		resources: {
			en: {translation: en},
			fr: {translation: fr},
			es: {translation: es},
			zh: {translation: zh},
			pt: {translation: pt},
			ru: {translation: ru},
			it: {translation: it},
			de: {translation: de},
			iw: {translation: iw},
			ar: {translation: ar},
		},

		debug: false,

		lng: 'en',
		fallbackLng: 'en',
		supportedLngs: SUPPORTED_LANGUAGE,

		load: 'languageOnly',

		keySeparator: '.',

		saveMissing: false,

		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
