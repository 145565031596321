import React, {useContext, useRef, useState} from 'react';
import {StyleSheet, Text, TouchableOpacity} from 'react-native';
import {useTranslation} from 'react-i18next';
import {useNavigation} from '@react-navigation/native';

import {ThemeContext} from './contexts/ThemeContext';
import {UserContext} from './contexts/UserContext';
import Menu from './components/Menu';
import StyledButton from './components/StyledButton';

const getQueryNumberColor = (theme, remaining) => {
  switch (remaining) {
    case 0:
      return theme.error;
    case 1:
      return theme.warning;
    default:
      return theme.success;
  }
};

const UpgradeMenu = () => {
  const {t} = useTranslation();
  const {user} = useContext(UserContext);
  const theme = useContext(ThemeContext);
  const {navigate} = useNavigation();
  const queryNumberButtonRef = useRef(null);

  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);

  const remainingQueries = Math.max((user || {}).free_queries - (user || {}).daily_queries, 0);

  return (
    <>
      <TouchableOpacity
        ref={queryNumberButtonRef}
        onPress={() => setUpgradeModalOpen(!upgradeModalOpen)}
        style={{
          marginRight: 5,
          backgroundColor: getQueryNumberColor(theme, remainingQueries),
          height: 28,
          width: 28,
          borderRadius: 14,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text style={{fontSize: 16, color: '#fff'}}>
          {remainingQueries}
        </Text>
      </TouchableOpacity>

      <Menu
        style={{borderRadius: 22, width: 350}}
        anchor={queryNumberButtonRef}
        open={upgradeModalOpen}
        close={() => setUpgradeModalOpen(false)}
        position="bottom"
      >
        <Text
          style={{
            paddingHorizontal: 10,
            fontSize: 16,
            color: theme.textPrimary,
            marginBottom: 10,
            width: 330,
          }}
        >
          {user.status === 'guest'
            ? t(
              'UpgradeMenu.textGuest',
              'You get {{count}} free queries every day as a guest. Create your account to get more.',
              {count: user.free_queries},
            )
            : t(
              'UpgradeMenu.textUser',
              'You get {{count}} free queries every day. Upgrade to a premium account to get unlimited queries and much more.',
              {count: user.free_queries},
            )
          }
        </Text>

        <StyledButton
          style={{flex: 1, backgroundColor: theme.success}}
          text={user.status === 'guest'
            ? t('UpgradeMenu.buttons.createAccount', 'Create my free account')
            : t('UpgradeMenu.buttons.upgrade', 'Upgrade account')
          }
          onPress={() => user.status === 'guest'
            ? navigate('Signup')
            : navigate('Upgrade')
          }
        />
      </Menu>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
});

export default UpgradeMenu;
