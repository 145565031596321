import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {
  Platform,
  StyleSheet,
  View,
  KeyboardAvoidingView,
} from 'react-native';
import {useHeaderHeight} from '@react-navigation/elements';
import {useTranslation} from 'react-i18next';

import {UserContext} from '../../contexts/UserContext';
import {SettingsContext} from '../../contexts/SettingsContext';
import {ThemeContext} from '../../contexts/ThemeContext';
import useSpeaker from '../../hooks/useSpeaker';
import Api from '../../constants/Api';

import Drawer from './Drawer';
import ChatView from './ChatView';
import MicrophoneView from './MicrophoneView';

const MainScreen = ({chatMode, setChatMode, drawerOpen, closeDrawer}) => {
  const {t} = useTranslation();
  const {user, userToken} = useContext(UserContext);
  const {settings: {language}} = useContext(SettingsContext);
  const theme = useContext(ThemeContext);
  const styles = useMemo(() => getStyles(theme), [theme]);
  const height = useHeaderHeight();
  const speaker = useSpeaker();

  const [discussionId, setDiscussionId] = useState((((user || {}).discussions || [])[0] || {}).id);
  const [discussions, setDiscussions] = useState((user || {}).discussions || []);
  const [discussionsLoading, setDiscussionsLoading] = useState(false);

  // Note: We need to keep track of the previous discussionId in order to properly handle the case
  //  when discussions have been cleared or on the first use when discussionId === null. We use the old
  //  value to not unnecessarily fetchQueries in ChatView when discussionId change from null.
  const previousDiscussionId = useRef((((user || {}).discussions || [])[0] || {}).id);

  useEffect(() => {
    speaker.speak(t(
      'MainScreen.greeting',
      'Hello {{firstName}}, how can I help you ?',
      {firstName: user.first_name}
    ), language);
  }, []);

  const updateDiscussionId = useCallback(value => {
    previousDiscussionId.current = discussionId;
    setDiscussionId(value);
  }, [discussionId]);

  const fetchDiscussions = useCallback(async () => {
    setDiscussionsLoading(true);

    const response = await fetch(`${Api.apiBaseUrl}/discussions.json?user_token=${userToken}`, {method: 'GET'});
    const data = await response.json();

    setDiscussionsLoading(false);

    if (data.error) {
      // TODO: handle error
      console.log(data.error);
      return;
    }

    setDiscussions(data);
    return data;
  }, [userToken, setDiscussionsLoading, setDiscussions]);

  return (
    <KeyboardAvoidingView
      keyboardVerticalOffset={height}
      behavior={Platform.OS === 'ios' ? 'padding' : null}
      style={{flex: 1}}
    >
      <Drawer
        closeDrawer={closeDrawer}
        drawerOpen={drawerOpen}
        setChatMode={setChatMode}
        discussions={discussions}
        setDiscussions={setDiscussions}
        discussionsLoading={discussionsLoading}
        discussionId={discussionId}
        updateDiscussionId={updateDiscussionId}
        fetchDiscussions={fetchDiscussions}
        setDiscussionsLoading={setDiscussionsLoading}
      />

      <View style={styles.mainContainer}>
        {chatMode
          ? (
            <ChatView
              previousDiscussionId={previousDiscussionId.current}
              discussionId={discussionId}
              updateDiscussionId={updateDiscussionId}
              fetchDiscussions={fetchDiscussions}
            />
          )
          : (
            <MicrophoneView discussionId={discussionId}/>
          )}
      </View>
    </KeyboardAvoidingView>
  );
};

const getStyles = theme => StyleSheet.create({
  mainContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.backgroundPrimary,
  },
});

export default MainScreen;
